import React from 'react'
import {
  Chart_Periods_Enum,
  Chart_Styles_Enum,
  GetChartSubscription,
  useUpdateChartSettingsMutation,
  useUpdateChartSymbolMutation,
} from '../../generated/types'
import ChartToolbar from './chart-toolbar'

type UserChartToolbarProps = {
  chartId: number
  data: GetChartSubscription | undefined
}

const UserChartToolbar = ({chartId, data}: UserChartToolbarProps) => {
  const [updateChartSettings] = useUpdateChartSettingsMutation()
  const [updateChartSymbol] = useUpdateChartSymbolMutation()

  const symbol = data?.charts_by_pk?.symbol || ''
  const period = data?.charts_by_pk?.settings[0]?.period || Chart_Periods_Enum.Period_1mm
  const type = data?.charts_by_pk?.settings[0]?.type || Chart_Styles_Enum.Candlestick

  const handleUpdateChartSettings = (type: Chart_Styles_Enum, period: Chart_Periods_Enum) =>
    updateChartSettings({
      variables: {type, period, chartId},
      optimisticResponse: {
        insert_user_chart_settings_one: {
          __typename: 'user_chart_settings',
          period,
          type,
        },
      },
    })

  const handleChangeChartType = async (type: Chart_Styles_Enum) =>
    handleUpdateChartSettings(type, period)

  const handleChangeChartPeriod = (period: Chart_Periods_Enum) =>
    handleUpdateChartSettings(type, period)

  const handleChangeChartSymbol = (symbol: string) => {
    updateChartSymbol({
      variables: {chartId, symbol},
      optimisticResponse: {
        update_charts_by_pk: {symbol, __typename: 'charts'},
      },
    })
  }
  return (
    <ChartToolbar
      symbol={symbol}
      period={period}
      type={type}
      onChangeChartType={handleChangeChartType}
      onChangeChartPeriod={handleChangeChartPeriod}
      onChangeChartSymbol={handleChangeChartSymbol}
    />
  )
}

export default UserChartToolbar
