import React, {useState, ChangeEvent, useEffect, useRef} from 'react'
import {Typography, Grid, InputBase, Theme} from '@material-ui/core'
// import {Fragment} from 'react'
// import {Icon} from '@material-ui/core'
import {makeStyles, fade} from '@material-ui/core/styles'

import Autocomplete from '@material-ui/lab/Autocomplete'

import {AlgoliaChartType, userIndex} from '../../api/algolia'
import {useNavState, useNavDispatch} from '../../utils/nav-context'

type TSProps = {
  symbol?: string
  focus?: boolean
  fullWidth?: boolean
  onSelect: (symbol: string) => any
}

const TickerSelect = ({symbol = '', onSelect, focus, fullWidth}: TSProps) => {
  const classes = useStyles({fullWidth})
  const [inputValue, setInputValue] = useState(symbol)
  const inputRef = useRef<HTMLInputElement>(null)
  const [options, setOptions] = useState<AlgoliaChartType[]>([])
  const [selectedOption, setSelectedOption] = useState<AlgoliaChartType | null>(null)
  const {isSearchOpen} = useNavState()
  const navDispatch = useNavDispatch()

  useEffect(() => {
    if (!isSearchOpen) return
    inputRef?.current?.focus()
    navDispatch({setIsSearchOpen: false})
  }, [isSearchOpen, navDispatch])

  useEffect(() => {
    if (!focus) return
    inputRef?.current?.focus()
  }, [focus, inputRef])

  useEffect(() => {
    if (!symbol) {
      setOptions([])
      setSelectedOption(null)
      return
    }
    userIndex
      .search(symbol, {
        filters: 'type:chart',
      })
      .then(({hits}: any) => {
        setOptions(hits as AlgoliaChartType[])
        setSelectedOption({
          symbol,
          name: symbol,
          exchange: '',
          type: 'chart',
        })
      })
  }, [symbol])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleResultSelected = async (event: ChangeEvent<{}>, value: AlgoliaChartType | null) => {
    if (value) {
      setSelectedOption(value)
      setOptions([])
      onSelect(value.symbol)
    }
  }

  useEffect(() => {
    userIndex.search(inputValue).then(({hits}: any) => {
      setOptions(hits.filter((h: AlgoliaChartType) => h.type === 'chart'))
    })
  }, [inputValue])

  return (
    <Autocomplete
      getOptionLabel={(option: AlgoliaChartType) => option.symbol}
      value={selectedOption}
      onChange={handleResultSelected}
      options={options}
      autoComplete
      includeInputInList
      getOptionSelected={(option: AlgoliaChartType, value: AlgoliaChartType) =>
        option.symbol === value.symbol
      }
      openOnFocus
      className={classes.search}
      classes={{
        popper: classes.suggestions,
      }}
      renderInput={params => (
        // <Fragment>
        //   <Icon className={classes.businessIcon}>business</Icon>
          <InputBase
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            inputRef={inputRef}
            placeholder="TCKR"
            onChange={handleChange}
            fullWidth
            value={inputValue}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
              focused: classes.inputFocused,
            }}
          />
        // </Fragment>
      )}
      renderOption={option => (
        <SearchOptionDisplay
          title={option.symbol}
          subtitle={option.name}
          annotation={option.exchange}
        />
      )}
    />
  )
}

type SODProps = {
  title: string
  subtitle: string
  annotation: string
}
const SearchOptionDisplay = ({annotation, subtitle, title}: SODProps) => (
  <Grid container alignItems="center">
    <Grid item xs>
      <Typography variant="body2" color="textPrimary">
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {subtitle}
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="body2" color="textSecondary">
        {annotation}
      </Typography>
    </Grid>
  </Grid>
)

const useStyles = makeStyles<Theme, {fullWidth?: boolean}>(theme => ({
  suggestions: {
    minWidth: 300,
    marginRight: theme.spacing(1),
  },
  search: {
    position: 'relative',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: props => (props.fullWidth ? 'auto' : 60),
  },
  // businessIcon: {
  //   margin: theme.spacing(0, 1),
  //   height: '100%',
  //   position: 'absolute',
  //   pointerEvents: 'none',
  //   display: 'flex',
  //   opacity: 0.8,
  //   color: fade(theme.palette.text.primary, 0.5),
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   zIndex: 1,
  // },
  inputRoot: {
    color: 'inherit',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 300,
    }),
  },
  inputInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #dee4ea',
    borderColor: theme.brand.primary.main,
    color: fade(theme.palette.text.primary, 0.5),
    display: 'block',
    fontSize: 16,
    lineHeight: '16px',
    marginRight: 0,
    padding: theme.spacing(1.25, 1, 1.25, 1),
    width: '100%',
  },
  searchFocused: {
    borderColor: theme.palette.primary.main,
  },
}))

export default TickerSelect
