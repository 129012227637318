import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import AppContainer from './app-container'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'

if (process.env['NODE_ENV'] !== 'development') {
  Sentry.init({dsn: 'https://ef23c0f4cbfe486987137b1072efaccd@o382345.ingest.sentry.io/5211050'})
}

ReactDOM.render(<AppContainer />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
