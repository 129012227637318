import React, {FunctionComponent, useState, Fragment} from 'react'
import {NestedMenu, LayoutIcon} from '..'
import {useHistory} from 'react-router-dom'
import {Typography, Menu, MenuItem, IconButton, Icon} from '@material-ui/core'
import {
  useDeleteWorkspaceMutation,
  useUpdateWorkspaceLayoutMutation,
  GetWorkspacesQuery,
  GetWorkspacesDocument,
  Layouts_Enum,
} from '../../generated/types'
import {LayoutsBySize} from '../layouts'

type WCMProps = {
  workspaceId: number
}

const WorkspaceContextMenu: FunctionComponent<WCMProps> = ({workspaceId}) => {
  const history = useHistory()

  const [contextAnchor, setContextAnchor] = useState<null | HTMLElement>(null)
  const [updateLayout] = useUpdateWorkspaceLayoutMutation()

  const [deleteWorkspace] = useDeleteWorkspaceMutation({
    variables: {workspaceId},
    update: (cache, {data}) => {
      const existingWorkspaces = cache.readQuery<GetWorkspacesQuery>({
        query: GetWorkspacesDocument,
      })
      const removedWorkspace = data?.delete_workspaces?.returning[0]
      if (!removedWorkspace) return
      cache.writeQuery<GetWorkspacesQuery>({
        query: GetWorkspacesDocument,
        data: {
          workspaces:
            existingWorkspaces?.workspaces.filter(w => w.id !== removedWorkspace.id) || [],
        },
      })
    },
  })

  const handleDeleteWorkspace = async () => {
    await deleteWorkspace()
    history.replace('/')
  }

  const handleChangeLayout = async (layout: Layouts_Enum) => {
    updateLayout({variables: {workspaceId, layout}})
  }

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setContextAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setContextAnchor(null)
  }

  const LayoutMenu = LayoutsBySize.map((row, num) => (
    <MenuItem key={num}>
      {num + 1}
      {row.map(layout => (
        <IconButton key={layout} onClick={() => handleChangeLayout(layout)}>
          <LayoutIcon layout={layout} />
        </IconButton>
      ))}
    </MenuItem>
  ))

  return (
    <Fragment>
      <IconButton aria-controls="customized-menu" aria-haspopup="true" onClick={handleOpen}>
        <Icon>dashboard</Icon>
      </IconButton>
      <Menu
        id="context-menu"
        anchorEl={contextAnchor}
        keepMounted
        open={Boolean(contextAnchor)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        <NestedMenu openLeft label="Layout" parentMenuOpen={!!contextAnchor} key="layout">
          {LayoutMenu}
        </NestedMenu>

        <MenuItem onClick={handleDeleteWorkspace} key="delete">
          <Typography color="error">Delete Workspace</Typography>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default WorkspaceContextMenu
