import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {AppBar, Toolbar, IconButton, Icon} from '@material-ui/core'
import {useNavState, useNavDispatch} from '../../utils/nav-context'
import clsx from 'clsx'

const DefaultToolbar: React.FC = ({children}) => {
  const classes = useStyles()
  const {isSidebarOpen} = useNavState()
  const dispatch = useNavDispatch()

  return (
    <AppBar
      position="fixed"
      elevation={2}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isSidebarOpen,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => dispatch({setIsSidebarOpen: true})}
          edge="start"
          size="small"
          className={clsx(classes.menuButton, {
            [classes.hide]: isSidebarOpen,
          })}
        >
          <Icon style={{fontSize: 16}}>chevron_right</Icon>
        </IconButton>
        {children}
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.default,
    marginLeft: theme.shape.drawerClosedWidth,
    width: `calc(100% - ${theme.shape.drawerClosedWidth}px)`,
  },
  appBarShift: {
    marginLeft: theme.shape.drawerWidth,
    width: `calc(100% - ${theme.shape.drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 0.5),
    ...theme.mixins.toolbar,
  },
  menuButton: {
    position: 'absolute',
    top: 20,
    color: theme.palette.text.hint,
    backgroundColor: theme.palette.background.default,
    left: -10,
    boxShadow: theme.shadows[1],
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
    },
  },
  hide: {
    display: 'none',
  },
}))

export default DefaultToolbar
