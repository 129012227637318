import * as React from 'react'

interface LaserpointerProps {
  readonly xAccessor?: any
  readonly xScale?: any
  readonly yScale?: any
  readonly datum?: object
  readonly timeDelta: number
  readonly value: number
}

const Laserpointer = ({xAccessor, xScale, yScale, timeDelta, datum, value}: LaserpointerProps) => {
  const [x, y] = [xScale(xAccessor(datum)), yScale(value)]

  /* We're animating the dots to fade in quickly and then fade out over time.
    One problem I ran into is that when dots get re-rendered (eg. when you pan the chart),
    the animations starts all over. This is why we pass in timeDelta (ms since the dot
    was placed) and set the animation delay to the negative value of that, so that when
    this dot gets rendered the animation has started X ms before already.
    */
  return (
    <>
      <style>
        {`
        #halo {
           animation-name: halo_grow, halo_opacity;
           animation-duration: 5000ms;
           animation-fill-mode: forwards;
           animation-timing-function: linear;
           animation-direction: normal;
           animation-iteration-count: 1;
           animation-delay: -${timeDelta}ms;
           pointer-events: none
        }
        @keyframes halo_grow {
            0% {r: 2px}
            4% {r: 10px}
            100% {r: 25px}
        }
        @keyframes halo_opacity {
            0% {opacity: 0}
            4% {opacity: 0.2}
            100% {opacity: 0}
        }
        #dot {
           animation-name: dot_grow, dot_opacity;
           animation-duration: 5000ms;
           animation-fill-mode: forwards;
           animation-timing-function: linear;
           animation-direction: normal;
           animation-iteration-count: 1;
           animation-delay: -${timeDelta}ms;
           pointer-events: none
        }
        @keyframes dot_grow {
            0 % { r: 8px }
            100% {r: 12px}
        }

        @keyframes dot_opacity {
            0 % { opacity: 0}
            4% {opacity: 1}
            100% {opacity: 0}
        }
        `}
      </style>
      <g>
        <circle id={`halo`} cx={x} cy={y} r="2" opacity="0" fill="#C11B3A" />
        <circle id={`dot`} cx={x} cy={y} r="8" fill="#C11B3A" opacity="0" />
      </g>
    </>
  )
}
export default Laserpointer
