import React, {createContext, useReducer, useContext, FunctionComponent} from 'react'
import {ContentType} from './note-props'

type Coordinates = {
  x: number
  y: number
}
export type OpenNote = {
  noteId: number
  location?: Coordinates
}

type ContentFilter = {[key in ContentType]: boolean}

type Action = {
  workspaceId?: number | null
  activeChart?: number | null
  activeSymbol?: string | null
  activeNote?: number | null
  chartId?: number | null
  openNote?: OpenNote
  closeNote?: number
  setIsSearchOpen?: boolean
  setIsSidebarOpen?: boolean
  setIsRightSidepanelOpen?: boolean
  setIsLandingPaneOpen?: boolean
  toggleContentFilter?: ContentType
}
type Dispatch = (action: Action) => void
type State = {
  workspaceId?: number | null
  activeChart?: number | null
  activeSymbol?: string | null
  chartId?: number | null
  openNotes: {[key: number]: OpenNote}
  activeNote?: number | null
  isSearchOpen: boolean
  isSidebarOpen: boolean
  isRightSidepanelOpen: boolean
  isLandingPaneOpen: boolean
  contentFilter: ContentFilter
}
const NavStateContext = createContext<State | undefined>(undefined)
const NavDispatchContext = createContext<Dispatch | undefined>(undefined)

function navReducer(state: State, action: Action) {
  const result = {
    workspaceId: 'workspaceId' in action ? action.workspaceId : state.workspaceId,
    chartId: 'chartId' in action ? action.chartId : state.chartId,
    activeChart: 'activeChart' in action ? action.activeChart : state.activeChart,
    activeSymbol: 'activeSymbol' in action ? action.activeSymbol : state.activeSymbol,
    activeNote: state.activeNote || null,
    isSearchOpen:
      action.setIsSearchOpen !== undefined ? action.setIsSearchOpen : state.isSearchOpen,
    isSidebarOpen: state.isSidebarOpen,
    isRightSidepanelOpen: false,
    openNotes: state.openNotes || {},
    isLandingPaneOpen: state.isLandingPaneOpen,
    contentFilter: state.contentFilter,
  }
  // Helps to set the right screen size/containers when navigating from one page to the next
  // (e.g. going from the feed to the charts page)
  if (Object.keys(result.openNotes).length > 0) {
    result.isRightSidepanelOpen = true
  }

  if (action.openNote) {
    result.isRightSidepanelOpen = true
    result.openNotes[action.openNote.noteId] = action.openNote
    result.activeNote = action.openNote.noteId
  }
  if (action.closeNote) {
    result.isRightSidepanelOpen = false
    delete result.openNotes[action.closeNote]
  }
  if (action.toggleContentFilter) {
    result.contentFilter = {
      ...state.contentFilter,
      [action.toggleContentFilter]: !state.contentFilter[action.toggleContentFilter],
    }
  }
  if ('workspaceId' in action) {
    result.chartId = undefined
    result.activeChart = undefined
  }
  if ('setIsSidebarOpen' in action) {
    result.isSidebarOpen = !!action.setIsSidebarOpen
    localStorage.setItem('sidebarOpen', JSON.stringify(action.setIsSidebarOpen))
  }
  if ('setIsLandingPaneOpen' in action) {
    result.isLandingPaneOpen = !!action.setIsLandingPaneOpen
    localStorage.setItem('landingPaneOpen', JSON.stringify(action.setIsLandingPaneOpen))
  }
  return result
}

const NavProvider: FunctionComponent = ({children}) => {
  const sidebarState = JSON.parse(localStorage.getItem('sidebarOpen') || 'true') as boolean
  const landingPaneState = JSON.parse(localStorage.getItem('landingPaneOpen') || 'true') as boolean
  const [state, dispatch] = useReducer(navReducer, {
    workspaceId: null,
    chartId: null,
    activeChart: null,
    activeSymbol: null,
    activeNote: null,
    openNotes: {},
    isSearchOpen: false,
    isSidebarOpen: sidebarState,
    isRightSidepanelOpen: false,
    isLandingPaneOpen: landingPaneState,
    contentFilter: {
      [ContentType.Personal]: true,
      [ContentType.Team]: true,
      [ContentType.Public]: true,
    },
  })

  return (
    <NavStateContext.Provider value={state}>
      <NavDispatchContext.Provider value={dispatch}>{children}</NavDispatchContext.Provider>
    </NavStateContext.Provider>
  )
}

const useNavState = () => {
  const context = useContext(NavStateContext)
  if (context === undefined) {
    throw new Error('useNavState must be used within a NavProvider')
  }
  return context
}

const useNavDispatch = () => {
  const context = useContext(NavDispatchContext)
  if (context === undefined) {
    throw new Error('useNavDispatch must be used within a NavProvider')
  }
  return context
}
export {NavProvider, useNavState, useNavDispatch}
