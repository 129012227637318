import React from 'react'
import {HotKeys} from 'react-hotkeys'
import {RedirectLoginResult} from '@auth0/auth0-spa-js'

import keymap from './utils/keymap'
import {NavProvider} from './utils/nav-context'
import history from './utils/history'
import AuthorizedApolloProvider from './utils/apollo'
import {Auth0Provider} from './utils/auth0'
import {UserProvider} from './utils/user-context'
import App from './app'

import './App.css'
import {SendbirdProvider} from './lib/messages-context'

const onAuthRedirectCallback = (redirectResult?: RedirectLoginResult) => {
  // @ts-ignore
  const targetUrl = redirectResult?.targetUrl || window.location.pathname
  history.push(targetUrl)
}

function AppContainer() {
  return (
    <Auth0Provider
      domain="outline-inc.auth0.com"
      client_id="fD53FQ19dVOiB2Y0WNMUNd5q4R0AvIxa"
      redirect_uri={window.location.origin}
      audience="hasura"
      onRedirectCallback={onAuthRedirectCallback}
    >
      <AuthorizedApolloProvider>
        <NavProvider>
          {/* Any <HotKeys> component that is a descendent of this one will inherit
            its keymap, so we don't have to pass it again later */}
          <HotKeys keyMap={keymap}>
            <UserProvider>
              <SendbirdProvider>
                <App />
              </SendbirdProvider>
            </UserProvider>
          </HotKeys>
        </NavProvider>
      </AuthorizedApolloProvider>
    </Auth0Provider>
  )
}

export default AppContainer
