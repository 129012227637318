import React from 'react'
import {Annotate} from 'react-financial-charts/lib/annotation'

import {compareTimes} from './date-comparison'
import {ChartData} from '..'
import Laserpointer from './laserpointer'
import {useChannel} from '../../../lib/channel-context'
import {DataMessageTypes} from '../../../lib/message-types'

const DURATION = 5000

type LAProps = {
  resolution: number
  channelId: string
}
const LaserpointerAnnotation = ({resolution, channelId}: LAProps) => {
  const now = new Date().getTime()
  const {dataMessages} = useChannel(channelId)
  const laserpointerDots = dataMessages
    .filter(m => m.type === DataMessageTypes.laserpointer)
    .map(m => ({
      date: new Date(m.data.time),
      value: m.data.value,
      timeDelta: now - m.createdAt.getTime(),
    }))
    .filter(m => m.timeDelta < DURATION)

  return (
    <>
      {laserpointerDots.map((dot, i) => {
        return (
          <Annotate
            key={i}
            with={Laserpointer}
            when={(d: ChartData) => dot.date && compareTimes(d.date, dot.date, resolution)}
            usingProps={dot}
          />
        )
      })}
    </>
  )
}

export default LaserpointerAnnotation
