const SUBSCRIPTION_KEY = process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY
if (!SUBSCRIPTION_KEY) {
  throw new Error('AZURE_SUBSCRIPTION_KEY is not set.')
}

export const bingNewsSearch = async (query: string) => {
  const options = {
    method: 'GET',
    headers: { 'Ocp-Apim-Subscription-Key': SUBSCRIPTION_KEY }
  }
  const url = "https://api.bing.microsoft.com/v7.0/news/search?category=Business&q=" + encodeURIComponent(query)
  return await fetch(url, options).then(res => res.json()).catch(err => console.log("Bing news API error", err))
}