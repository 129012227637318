import React, {useState, useEffect, createContext, useContext} from 'react'
import {useUserState} from '../utils/user-context'
import SendBird, {BaseChannel, BaseMessageInstance, GroupChannel, UserMessage} from 'sendbird'
import {
  MessageData,
  TypingUsersByChannel,
  SendbirdState,
  Channels,
  TextMessageTypes,
  MessageHash,
} from './message-types'
import transformMessage from './message-transformers'
import {useMessageNotifications} from '../utils/notifications'
import {indexBy, object} from 'underscore'

const DefaultState = {
  messages: {},
  typingUsers: {},
  isLoading: false,
  channels: {},
  unreadCountPerClient: {},
  loadChannelMessages: (channel: GroupChannel) => {},
  createTeamChannel: (): Promise<string> => new Promise(() => {}),
  getChannel: (): Promise<GroupChannel> => new Promise(() => {}),
  sendMessage: console.log,
  sendDataMessage: console.log,
}

const SendbirdContext = createContext<SendbirdState>(DefaultState)

type MPProps = {
  children: React.ReactNode
}

const SendbirdProvider = ({children}: MPProps) => {
  const {user, team, clients} = useUserState()
  const {sendLocalNotification} = useMessageNotifications()
  const [messages, setMessages] = useState<MessageHash>({})
  const [channels, setChannels] = useState<Channels>({})
  const [msgLoaded, setMsgLoaded] = useState<{[key: string]: boolean}>({})
  const [unreadCountPerClient, setUnreadCountPerClient] = useState<{[key: string]: number}>({})
  const [typingUsers, setTypingUsers] = useState<TypingUsersByChannel>({})
  const [isLoading, setIsLoading] = useState(true)

  const sendbird = new SendBird({
    appId: process.env.REACT_APP_SENDBIRD_APP_ID || '',
  })

  /* ************************************************** */
  /* Connecting on mount and cleaning up                */
  /* ************************************************** */

  useEffect(() => {
    if (!user || !isLoading) return

    /* Handlers                                           */
    const onMessageReceived = (channel: BaseChannel, message: BaseMessageInstance) => {
      const msg = transformMessage(message as UserMessage)
      if (msg.type === TextMessageTypes.message && msg.sender.id !== user.id) {
        sendLocalNotification(msg)
      }
      setMessages(prev => ({
        [msg.id]: msg,
        ...prev,
      }))
    }

    const onTypingStatusUpdated = (channel: GroupChannel) => {
      const users = channel.getTypingMembers().map(u => ({
        id: u.userId,
        full_name: u.nickname,
      }))
      setTypingUsers(prev => ({
        ...prev,
        [channel.url]: users,
      }))
    }

    const maybeUpdateNickname = (sendbird_user: SendBird.User) => {
      if (!user?.full_name || user.full_name === sendbird_user?.nickname) return
      sendbird.updateCurrentUserInfo(user.full_name, '', (response: object, error: object) => {
        if (error) throw error
      })
    }

    const getChannels = async () => {
      const channelListQuery = sendbird.GroupChannel.createMyGroupChannelListQuery()
      channelListQuery.includeEmpty = true
      channelListQuery.order = 'latest_last_message'
      channelListQuery.limit = 100
      channelListQuery.customTypesFilter = ['sales-chat', 'published note']
      if (channelListQuery.hasNext) {
        channelListQuery.next(function(channels, error) {
          if (error) throw error
          setChannels(prev => ({...indexBy(channels, 'url'), ...prev}))
        })
      }
    }

    const getUnreadCountPerClient = async () => {
      if (!clients) return
      const clientIds = clients?.map(c => c.id)
      const filteredQuery = sendbird.GroupChannel.createMyGroupChannelListQuery()
      filteredQuery.userIdsIncludeFilter = clientIds
      filteredQuery.userIdsIncludeFilterQueryType = 'OR'
      filteredQuery.limit = 100
      filteredQuery.customTypesFilter = ['sales-chat', 'published note']
      filteredQuery.unreadChannelFilter = 'unread_message'

      if (filteredQuery.hasNext) {
        filteredQuery.next(groupChannels => {
          // for each client, find all channels that contain that client
          /// map them to the unread count, and reduce that to the sum
          // then make an object with the clientId as key and the sum as value
          const unreadByClient = object(
            clientIds,
            clientIds.map(c =>
              groupChannels
                .filter(g => g.members.map(m => m.userId).includes(c))
                .map(g => g.unreadMessageCount)
                .reduce((a, b) => a + b, 0),
            ),
          )
          setUnreadCountPerClient(unreadByClient)
        })
      }
    }

    const ChannelHandler = new sendbird.ChannelHandler()
    const UserHandler = new sendbird.UserEventHandler()
    UserHandler.onTotalUnreadMessageCountUpdated = getUnreadCountPerClient
    ChannelHandler.onMessageReceived = onMessageReceived
    ChannelHandler.onTypingStatusUpdated = onTypingStatusUpdated

    sendbird.connect(user.id, sendbird_user => {
      setIsLoading(false)
      maybeUpdateNickname(sendbird_user)
      sendbird.addChannelHandler('sendbird-handler', ChannelHandler)
      sendbird.addUserEventHandler('sendbird-user-handler', UserHandler)
      getChannels()
      getUnreadCountPerClient()
    })
  }, [user, isLoading, setIsLoading, sendbird, sendLocalNotification, clients])

  useEffect(() => {
    if (!sendbird) return
    return () => {
      sendbird.removeChannelHandler('sendbird-handler')
      sendbird.removeUserEventHandler('sendbird-user-handler')
    }
  }, [sendbird])

  /* ************************************************** */
  /* Loading channels and messages                      */
  /* ************************************************** */

  const loadChannelMessages = (channel: GroupChannel) => {
    if (msgLoaded[channel.url]) return
    setMsgLoaded(prev => ({[channel.url]: true, ...prev}))
    const prevMessagesQuery = channel.createPreviousMessageListQuery()
    prevMessagesQuery.limit = 100
    prevMessagesQuery.load(messages => {
      if (!messages) return
      _addMessages(messages)
    })
    return channel
  }

  const getChannel = async (channelId: string): Promise<GroupChannel> => {
    return new Promise((resolve, reject) => {
      if (channelId in channels) {
        resolve(channels[channelId])
        return
      }
      sendbird.GroupChannel.getChannel(channelId, (channel, error) => {
        if (error) {
          reject(error)
        } else {
          setChannels(prev => ({[channel.url]: channel, ...prev}))
          resolve(channel)
        }
      })
    })
  }

  const createTeamChannel = async (noteId: number): Promise<string> => {
    if (!team) throw new Error("Don't have team data to create channel")
    return new Promise((resolve, reject) => {
      sendbird.GroupChannel.createChannelWithUserIds(
        team.map(user => user.id),
        false,
        `team-${user?.outline_user?.team_id}-note-${noteId}`,
        '',
        '',
        'team-note-chat',
        (channel: GroupChannel, error: object) => {
          if (error) reject(error)
          resolve(channel.url)
        },
      )
    })
  }

  /* ************************************************** */
  /* Sending messages                                   */
  /* ************************************************** */

  const sendMessage = (text: string, channel: GroupChannel) => {
    channel.endTyping()
    const params = new sendbird.UserMessageParams()
    params.message = text
    channel.sendUserMessage(params, message => {
      _addMessages([message])
    })
  }

  const sendDataMessage = (data: MessageData, channel: GroupChannel) => {
    const params = new sendbird.UserMessageParams()
    params.customType = 'annotation'
    params.data = JSON.stringify(data)
    params.message = `annotation ${params.data}`
    channel.sendUserMessage(params, (message, error) => {
      _addMessages([message])
    })
  }

  /* ************************************************** */
  /* Helpers                                            */
  /* ************************************************** */

  const _addMessages = (messages: BaseMessageInstance[]) => {
    const transformedMsgs = messages
      .filter(m => m.messageType === 'user')
      .map(m => transformMessage(m as UserMessage))
    setMessages(prev => ({
      ...indexBy(transformedMsgs, 'id'),
      ...prev,
    }))
  }

  const value = {
    messages,
    isLoading,
    typingUsers,
    channels,
    unreadCountPerClient,
    loadChannelMessages,
    getChannel,
    sendMessage,
    sendDataMessage,
    createTeamChannel,
  }

  return <SendbirdContext.Provider value={value}>{children}</SendbirdContext.Provider>
}

const useSendbirdContext = () => {
  const context = useContext(SendbirdContext)
  if (context === undefined) {
    throw new Error('useSendbirdContext must be used within a SendbirdProvider')
  }
  return context
}

export {SendbirdProvider, useSendbirdContext}
