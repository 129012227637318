import React, {SyntheticEvent} from 'react'
import {Button, Grid, Icon, Box} from '@material-ui/core'
import {useLocation, Redirect} from 'react-router-dom'

import {useAuth0} from '../utils/auth0'
import {ReactComponent as Logo} from '../assets/img/outline.svg'
import {useUserState} from '../utils/user-context'
import routes from '../routes'

const LoginView: React.FC = () => {
  const {loginWithRedirect} = useAuth0()
  const location = useLocation()
  const {user} = useUserState()

  const onLoginClick = async (e: SyntheticEvent) => {
    e.preventDefault()
    // @ts-ignore TODO: Type router state.
    const statePathname = location?.state?.from?.pathname
    const targetUrl =
      statePathname && statePathname !== routes.common.login.path ? statePathname : '/'
    await loginWithRedirect({appState: {targetUrl}})
  }

  if (user) {
    return <Redirect to="/" />
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{height: '100vh'}}
    >
      <Box m={5}>
        <Logo style={{height: 200}} />
      </Box>
      <Button variant="outlined" onClick={onLoginClick} startIcon={<Icon>lock</Icon>}>
        Login
      </Button>
    </Grid>
  )
}

export default LoginView
