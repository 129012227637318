import React from 'react'
import {useParams} from 'react-router-dom'
import {CardHeader, List, ListItem, Divider, makeStyles, useTheme, Typography} from '@material-ui/core'

import {OpenNotes, Loading} from '../../components'
import {useNavDispatch} from '../../utils/nav-context'
import ThreePanes from '../../components/layouts/three-panes'
import TwoPanes from '../../components/layouts/two-panes'

import NoteListItem from '../../components/notes/note-list-item'
import {useGetWorkspaceSubscription} from '../../generated/types'

const WorkspaceFeedWrapper = () => {
  const classes = useStyles()
  const theme = useTheme()
  const {workspaceId: workspaceIdString} = useParams()
  const navDispatch = useNavDispatch()
  const workspaceId = parseInt(workspaceIdString || '', 10)

  const {loading, error, data} = useGetWorkspaceSubscription({variables: {workspaceId}})
  var notes: any[] = []
  data?.workspaces?.charts?.forEach(c => notes.push(c.notes))
  notes = notes.flat().sort((a, b) => {
    return a.time > b.time ? -1 : a.time < b.time ? 1 : a.id - b.id
  })

  const notesToday = notes.filter(n => {
    return new Date().setHours(0, 0, 0, 0) < new Date(n.time).getTime()
  })

  const notesYesterday = notes.filter(n => {
    return (
      notesToday.indexOf(n) === -1 && new Date().setHours(-24, 0, 0, 0) < new Date(n.time).getTime()
    )
  })

  const notesLastWeek = notes.filter(n => {
    return (
      notesToday.indexOf(n) === -1 &&
      notesYesterday.indexOf(n) === -1 &&
      new Date().setDate(new Date().getDate() - 7) < new Date(n.time).getTime()
    )
  })

  const notesOlder = notes.filter(n => {
    return (
      notesToday.indexOf(n) === -1 &&
      notesYesterday.indexOf(n) === -1 &&
      notesLastWeek.indexOf(n) === -1
    )
  })

  const renderNotesListByTime = (notes: any[], sectionLabel: string, type: string) => {
    const filteredNotes = notes.filter(a => {
      return a.type === type
    })
    if (filteredNotes.length > 0) {
      return (
        <List>
          <ListItem>
            <Typography variant="subtitle1">
              {sectionLabel}
            </Typography>
          </ListItem>
          <Divider />
          {filteredNotes.map(note => (
            <NoteListItem
              note={note}
              channelId={note.channel_id}
              key={note.id}
              onClick={noteId => navDispatch({openNote: {noteId}})}
            />
          ))}
        </List>
      )
    }
  }

  const renderNotesList = (type: string) => {
    return (
      <List className={classes.noteList}>
        <CardHeader
          disableTypography
          style={{
            textAlign: 'center',
            borderBottom: `2px solid ${theme.palette.text.secondary}`,
            fontSize: 20,
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: 0.325,
            marginBottom: '0.5em',
          }}
          title={type.charAt(0).toUpperCase() + type.slice(1) + 's'}
        />
        <List>
          {renderNotesListByTime(notesToday, 'Today', type)}
          {renderNotesListByTime(notesYesterday, 'Yesterday', type)}
          {renderNotesListByTime(notesLastWeek, 'Last Week', type)}
          {renderNotesListByTime(notesOlder, 'Older', type)}
        </List>
      </List>
    )
  }
  if (loading) return <Loading label />
  if (error) return <div>ERROR: {error.message}</div>
  return (
    <>
      <TwoPanes
        pane1={
          <ThreePanes
            left={renderNotesList('note')}
            middle={renderNotesList('link')}
            right={renderNotesList('file')}
            leftName={workspaceId + 'noteList'}
            rightName={workspaceId + 'linkList'}
          />
        }
        pane2={<OpenNotes />}
        name={workspaceId + 'feedPanes'}
      />
    </>
  )
}
const useStyles = makeStyles(theme => ({
  noteList: {
    marginRight: '10px',
    backgroundColor: theme.palette.background.paper,
  },
}))
export default WorkspaceFeedWrapper
