import React, {useState} from 'react'
import {Button, Icon, Popover, Badge} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {useChannel} from '../../lib/channel-context'
import {Loading, Chat} from '..'
import {useUserState} from '../../utils/user-context'

const OnlineIcon = () => {
  const classes = useStyles()
  return <Icon className={classes.onlineIcon}>lens</Icon>
}
const OfflineIcon = () => {
  const classes = useStyles()
  return <Icon className={classes.icon}>panorama_fish_eye</Icon>
}

type CBProps = {
  onClick: (event: React.MouseEvent<HTMLElement>) => any
  unread: number
  online: boolean
  label: string
}

const ChatButton = ({onClick, online, unread, label}: CBProps) => {
  const classes = useStyles()

  const renderIcon = () => {
    if (!unread) return <Icon>chat</Icon>
    return (
      <Badge
        badgeContent={unread}
        max={9}
        color="error"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Icon>chat</Icon>
      </Badge>
    )
  }

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={renderIcon()}
      endIcon={online ? <OnlineIcon /> : <OfflineIcon />}
      onClick={onClick}
      disableElevation
      className={classes.button}
    >
      {label}
    </Button>
  )
}

const ToolbarChat = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const {user} = useUserState()
  const globalChatId = user?.client_user?.team_contacts[0]?.channel_id
  const {unread} = useChannel(globalChatId)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ChatButton online={true} unread={unread} label="Sales" onClick={handleClick} />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popover}
        classes={{paper: classes.chat}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!!globalChatId ? <Chat channelId={globalChatId} /> : <Loading />}
      </Popover>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  popover: {
    marginTop: theme.spacing(1),
  },
  chat: {
    height: 300,
    width: 300,
    display: 'flex',
  },
  onlineIcon: {
    color: theme.palette.success.main,
    // It hurts, but otherwise the button will overwrite this
    fontSize: '10px !important',
  },
  icon: {
    fontSize: '10px !important',
  },
}))

export default ToolbarChat
