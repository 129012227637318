import React, {useEffect} from 'react'
import {Paper, makeStyles} from '@material-ui/core'
import {useParams} from 'react-router-dom'
import SplitPane from 'react-split-pane'
import {useGetAllPublicNotesSubscription} from '../../generated/types'
import {ClientNotesList, Loading} from '../../components'
import {NoteViewer} from '.'
import createPersistedState from 'use-persisted-state'
import {useNavDispatch} from '../../utils/nav-context'

const ClientThreadNoteViewer = () => {
  const {noteId: noteIdString} = useParams()

  const {loading, data, error} = useGetAllPublicNotesSubscription()

  const useVerticalPaneSplit = createPersistedState('clientScreenNoteListPaneSplit')
  const [verticalPaneSplit, setVerticalPaneSplit] = useVerticalPaneSplit(0)
  const navDispatch = useNavDispatch()

  useEffect(() => {
    navDispatch({activeSymbol: null})
  }, [navDispatch])

  const classes = useStyles()

  if (error) throw error
  if (loading) return <Loading />

  if (!noteIdString) {
    return (
      <Paper variant="outlined" className={classes.root}>
        <ClientNotesList notes={data?.public_notes || []} />
      </Paper>
    )
  }

  const noteId = parseInt(noteIdString, 10)

  return (
    <SplitPane
      split="vertical"
      minSize={200}
      defaultSize={verticalPaneSplit || '60%'}
      style={{position: 'static', flex: 1}}
      onChange={(size) => setVerticalPaneSplit(size)}
      maxSize={-100}
      resizerStyle={{
        zIndex: 4000,
        width: 11,
        margin: '0 -5px',
        cursor: 'col-resize',
      }}
      pane2Style={{
        width: '100%',
      }}
    >
      <Paper variant="outlined" className={classes.threadPane}>
        <ClientNotesList notes={data?.public_notes || []} selected={noteId} />
      </Paper>
      <NoteViewer noteId={noteId} chartButton />
    </SplitPane>
  )
}

const useStyles = makeStyles(() => ({
  chatPane: {
    height: '100%',
    overflow: 'auto',
  },
  threadPane: {
    height: '100%',
  },
  root: {
    flex: 1,
  },
}))

export default ClientThreadNoteViewer
