import React, {useState, ChangeEvent} from 'react'
import {
  Icon,
  IconButton,
  InputBase,
  Popover,
  Typography,
  Box,
  Button,
  Tooltip,
} from '@material-ui/core'
import FuzzySearch from 'fuzzy-search'
import {Icons, IconType} from './icons'
import {makeStyles} from '@material-ui/core/styles'

const NUM_ICONS = 20 // number of icons to show in the picker

type IconPickerProps = {
  onChange: (icon: string) => void
  value?: string
}
const IconPicker = ({value = '', onChange}: IconPickerProps) => {
  const classes = useStyles()
  const matcher = new FuzzySearch(Icons, ['aliases'], {sort: true})
  const [results, setResults] = useState<IconType[]>(matcher.search(value))
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setResults(matcher.search(event?.target.value))
  }

  const handleSelect = (icon: string) => {
    onChange(icon)
    handleClose()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderIcons = () => (
    <>
      <IconButton size="small" onClick={() => handleSelect('')} className={classes.icon}>
        <Icon>block</Icon>
      </IconButton>
      {results.slice(0, NUM_ICONS - 1).map(r => (
        <IconButton
          size="small"
          key={r.icon}
          onClick={() => handleSelect(r.icon)}
          className={classes.icon}
        >
          <Icon>{r.icon}</Icon>
        </IconButton>
      ))}
    </>
  )

  return (
    <>
      <Tooltip title="Pick an Icon" arrow>
        <Button color="primary" variant="outlined" onClick={handleClick}>
          <Icon color="action">{value || 'block'}</Icon>
        </Button>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={1} className={classes.popover}>
          <InputBase
            onChange={handleChange}
            placeholder="Search..."
            autoFocus
            fullWidth
            className={classes.input}
          />

          {results.length === 0 ? <Typography>No Results</Typography> : renderIcons()}
        </Box>
      </Popover>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  icon: {
    margin: theme.spacing(0.5),
  },
  input: {
    borderBottomColor: theme.palette.background.default,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  popover: {
    width: 210,
  },
}))

export default IconPicker
