import {useEffect} from 'react'

// import {useNavDispatch} from '../utils/nav-context'
import {Message} from '../lib/message-types'

export const useMessageNotifications = () => {
  // const navDispatch = useNavDispatch()
  useEffect(() => {
    Notification.requestPermission(console.log)
  }, [])

  const sendLocalNotification = (message: Message) => {
    const notification = new Notification(`${message.sender.full_name} wrote`, {
      icon: '/favicon.png',
      body: message.text,
      tag: `new-message-${message.id}`,
    })
    notification.onclick = () => {
      // @Todo replace that when we change routing to use channel ids
      // if (message.thread.public_note.note_id) {
      //   navDispatch({
      //     openNote: {
      //       noteId: message.thread.public_note.note_id,
      //       selectedThreadId: message.thread.id,
      //     },
      //   })
      // }
      window.focus()
      notification.close()
    }
  }

  return {sendLocalNotification}
}
