import React from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControlLabel,
  Switch,
  Box,
  Icon,
} from '@material-ui/core'
import createPersistedState from 'use-persisted-state'
import {useAuth0} from '../../utils/auth0'
type NWDProps = {
  isOpen: boolean
  onClose: () => void
}
const AccountSettingDialog = ({isOpen, onClose}: NWDProps) => {
  const useDarkMode = createPersistedState('darkMode')
  const useDarkModeForNotes = createPersistedState('darkModeForNotes')
  const [darkMode, setDarkMode] = useDarkMode(true)
  const [darkModeForNotes, setDarkModeForNotes] = useDarkModeForNotes(true)
  const {logout} = useAuth0()

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle id="form-dialog-title">Account</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Switch checked={darkMode} onChange={() => setDarkMode((prev: boolean) => !prev)} />
          }
          labelPlacement="start"
          label="Dark Theme"
        />

        <br />

        <FormControlLabel
          control={
            <Switch
              checked={darkModeForNotes}
              onChange={() => setDarkModeForNotes((prev: boolean) => !prev)}
            />
          }
          labelPlacement="start"
          label="Dark Theme for Notes"
        />
        <Box m={2}>
          <Button
            onClick={() => logout({returnTo: window.location.origin})}
            color="secondary"
            variant="outlined"
            startIcon={<Icon>exit_to_app</Icon>}
          >
            Log Out
          </Button>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AccountSettingDialog
