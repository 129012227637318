import React from 'react'
import {makeStyles, Theme} from '@material-ui/core'
import {Layouts_Enum} from '../generated/types'
import useContentSize from '../utils/useContentSize'

export const Layouts = Object.keys(Layouts_Enum)

export const SizeByLayout: {[key in Layouts_Enum]: number} = {
  [Layouts_Enum.Layout_1]: 1,
  [Layouts_Enum.Layout_2h]: 2,
  [Layouts_Enum.Layout_2v]: 2,
  [Layouts_Enum.Layout_3b]: 3,
  [Layouts_Enum.Layout_3v]: 3,
  [Layouts_Enum.Layout_3h]: 3,
  [Layouts_Enum.Layout_3l]: 3,
  [Layouts_Enum.Layout_3r]: 3,
  [Layouts_Enum.Layout_3t]: 3,
  [Layouts_Enum.Layout_4]: 5,
  [Layouts_Enum.Layout_4b]: 5,
  [Layouts_Enum.Layout_4h]: 5,
  [Layouts_Enum.Layout_4l]: 5,
  [Layouts_Enum.Layout_4r]: 5,
  [Layouts_Enum.Layout_4t]: 5,
  [Layouts_Enum.Layout_4v]: 5,
  [Layouts_Enum.Layout_5b]: 5,
  [Layouts_Enum.Layout_5l]: 5,
  [Layouts_Enum.Layout_5r]: 5,
  [Layouts_Enum.Layout_5t]: 5,
  [Layouts_Enum.Layout_6v]: 6,
  [Layouts_Enum.Layout_6h]: 6,
}

// This doesn't include all layouts as we're intentionally limiting the choices for now
export const LayoutsBySize = [
  [Layouts_Enum.Layout_1],
  [Layouts_Enum.Layout_2h, Layouts_Enum.Layout_2v],
  [Layouts_Enum.Layout_3h, Layouts_Enum.Layout_3v, Layouts_Enum.Layout_3b, Layouts_Enum.Layout_3t],
  [
    Layouts_Enum.Layout_4,
    Layouts_Enum.Layout_4h,
    Layouts_Enum.Layout_4v,
    Layouts_Enum.Layout_4b,
    Layouts_Enum.Layout_4t,
  ],
  [Layouts_Enum.Layout_5b, Layouts_Enum.Layout_5t],
  [Layouts_Enum.Layout_6h, Layouts_Enum.Layout_6v],
]

type GridProps = {
  charts: any[]
  layout: number[]
}

const ColumnLayout = ({charts, layout}: GridProps) => {
  const {width, height} = useContentSize({padding: 1})
  const classes = useStyles({rows: layout.length, width, height})

  const sumUntil = (arr: number[], until: number) => {
    if (until === 0) return 0
    return arr.slice(0, until).reduce((sum, x) => sum + x)
  }

  const getChart = (row: number, column: number) => {
    return charts[sumUntil(layout, row) + column]
  }

  return (
    <div className={classes.container}>
      {layout.map((columns, row) => (
        <div key={row} className={classes.row}>
          {[...Array(columns)].map((_, i) => (
            <div style={{width: width / columns}} className={classes.column} key={i}>
              {getChart(row, i)}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

const RowLayout = ({charts, layout}: GridProps) => {
  const {width, height} = useContentSize({})
  const classes = useStyles({rows: layout.length, width, height})

  return (
    <div className={classes.container}>
      {charts.slice(0, layout.length).map((chart, i) => (
        <div style={{gridColumnEnd: `span 6`, gridRowEnd: `span ${layout[i]}`}} key={i}>
          {chart}
        </div>
      ))}
    </div>
  )
}

export const LayoutsGridMap: {[key in Layouts_Enum]: (charts: Array<any>) => React.ReactNode} = {
  [Layouts_Enum.Layout_1]: charts => <ColumnLayout charts={charts} layout={[1]} />,
  [Layouts_Enum.Layout_2v]: charts => <ColumnLayout charts={charts} layout={[2]} />,
  [Layouts_Enum.Layout_2h]: charts => <ColumnLayout charts={charts} layout={[1, 1]} />,
  [Layouts_Enum.Layout_3h]: charts => <ColumnLayout charts={charts} layout={[1, 1, 1]} />,
  [Layouts_Enum.Layout_3v]: charts => <ColumnLayout charts={charts} layout={[3]} />,
  [Layouts_Enum.Layout_3b]: charts => <ColumnLayout charts={charts} layout={[2, 1]} />,
  [Layouts_Enum.Layout_3l]: charts => <RowLayout charts={charts} layout={[1, 1, 1]} />,
  [Layouts_Enum.Layout_3r]: charts => <RowLayout charts={charts} layout={[1, 1, 1]} />,
  [Layouts_Enum.Layout_3t]: charts => <ColumnLayout charts={charts} layout={[1, 2]} />,
  [Layouts_Enum.Layout_4]: charts => <ColumnLayout charts={charts} layout={[2, 2]} />,
  [Layouts_Enum.Layout_4b]: charts => <ColumnLayout charts={charts} layout={[3, 1]} />,
  [Layouts_Enum.Layout_4l]: charts => <RowLayout charts={charts} layout={[2, 2]} />,
  [Layouts_Enum.Layout_4r]: charts => <RowLayout charts={charts} layout={[2, 2]} />,
  [Layouts_Enum.Layout_4t]: charts => <ColumnLayout charts={charts} layout={[1, 3]} />,
  [Layouts_Enum.Layout_4h]: charts => <ColumnLayout charts={charts} layout={[1, 1, 1, 1]} />,
  [Layouts_Enum.Layout_4v]: charts => <ColumnLayout charts={charts} layout={[4]} />,
  [Layouts_Enum.Layout_5b]: charts => <ColumnLayout charts={charts} layout={[4, 1]} />,
  [Layouts_Enum.Layout_5l]: charts => <RowLayout charts={charts} layout={[5]} />,
  [Layouts_Enum.Layout_5r]: charts => <RowLayout charts={charts} layout={[5]} />,
  [Layouts_Enum.Layout_5t]: charts => <ColumnLayout charts={charts} layout={[1, 4]} />,
  [Layouts_Enum.Layout_6v]: charts => <ColumnLayout charts={charts} layout={[2, 2, 2]} />,
  [Layouts_Enum.Layout_6h]: charts => <ColumnLayout charts={charts} layout={[3, 3]} />,
}

const useStyles = makeStyles<Theme, {rows: number; width: number; height: number}>(theme => ({
  container: {
    height: props => props.height,
    width: props => props.width,
    margin: -theme.spacing(0.5),
  },
  row: {
    height: props => props.height / props.rows,
    width: props => props.width,
  },
  column: {
    height: props => props.height / props.rows,
    overflow: 'hidden',
    display: 'inline-block',
    padding: theme.spacing(0.5),
  },
}))
