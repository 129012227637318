import React from 'react'
import {Transforms, Range, Editor} from 'slate'
import {ReactEditor} from 'slate-react'
import MentionsMenu from '../mentions-menu'

export const dummyMentions = [
  "Dhanvi Reddy",
  "Kerry Snyder",
  "Ola Kowalewski",
  "Yang Niu",
  "Sales",
  "Analysts",
  "Traders",
  "Quants",
]
/* TODO: enable live mentionable data with something like these user queries
// const {org_id, team_id} = props
// const {data: orgData} = useGetUsersInOrgByFullNameQuery({variables: {org_id: org_id || 1}})
// const {data: teamData} = useGetUsersOnTeamByFullNameQuery({variables: {team_id: team_id || 1}})
// const mentionables: GetUsersInOrgByFullNameQuery = (orgData || teamData).outline_users
//      .map(m => m.user.full_name || "")
//      .filter(c => c.toLowerCase().startsWith(search.toLowerCase()))
//      .slice(0, 10)
*/
const getMentionables = (search: string) => {
  return dummyMentions.filter(c => c.toLowerCase().startsWith(search.toLowerCase())).slice(0, 10)
}

export const withMentions = (editor: ReactEditor) => {
  // pull in the default methods
  const {isInline, isVoid} = editor

  // extends the Editor's isInline method
  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === 'mention' ? true : isVoid(element)
  }

   return editor
}

// monitors text entered for @ signs in the proper context to constitute a mention
export const detectMentions = (selection: Range | null, editor: ReactEditor, setIndex: React.Dispatch<React.SetStateAction<number>>, setSearch: React.Dispatch<React.SetStateAction<string>>, setTarget: React.Dispatch<React.SetStateAction<Range | undefined>>) => {
  if (selection && Range.isCollapsed(selection)) {
    const [start] = Range.edges(selection)

    // looks for a word before the cursor that starts with `@` and may contains letters
    const wordBefore = Editor.before(editor, start, { unit: 'word' })
    const before = wordBefore && Editor.before(editor, wordBefore)
    const beforeRange = before && Editor.range(editor, before, start)
    const beforeText = beforeRange && Editor.string(editor, beforeRange)
    const beforeMatch = beforeText && beforeText.match(/(?:^|[^\w])@([\w]+)/)
    // modified an alternate regex from [Instagram Mentions Regex](https://www.regextester.com/107977)
    // other regex variants tested: /^@(\w+)$/ /^@(\w*)$/ /@(\w+)$/ /(?:^|[^\w])@([\w]*)$/ with buggy results

    // also detects whether the previous character is `@` preceded by a space
    const charBefore = Editor.before(editor, start, { unit: 'character' })
    const beforeChar = charBefore && Editor.before(editor, charBefore)
    const beforeCharRange = beforeChar && Editor.range(editor, beforeChar, start)
    const beforeCharText = beforeCharRange && Editor.string(editor, beforeCharRange)
    const beforeCharMatch = beforeCharText && beforeCharText.match(/([^\w]*@)/)
    const atCharBefore = Editor.before(editor, selection, { unit: "character" });

    // looks for a string after the cursor that contains a space or nothing
    const after = Editor.after(editor, start)
    const afterRange = Editor.range(editor, start, after)
    const afterText = Editor.string(editor, afterRange)
    const afterMatch = afterText.match(/^(\s|$)/)
    
    /* to trigger the mentions menu, the last word typed must:
    //   - start with @ (beforeMatch or beforeCharMatch)
    //   - be the end of line (afterMatch)
    */ 
    if ((beforeMatch || beforeCharMatch) && afterMatch) {
      // target the `@…` for replacement with the mention element
      if (beforeCharMatch && atCharBefore) {
        // target only the `@` to avoid slate's tendency to also grab previous whitespace
        const atCharRange = Editor.range(editor, atCharBefore, selection)
        setTarget(atCharRange)
        setSearch('')
      }
      // search for a matching name in the array
      if (beforeMatch) {
        // if the user has searched for a name, target their partial search with the `@`
        setTarget(beforeRange)
        setSearch(beforeMatch[1])
      }
      // select the first name in the list
      setIndex(0)
      return
    } else {
      // if no mention matches, hide the mentions menu and clear the search
      setTarget(undefined)
      setSearch('')
    }
  }
}

// inserts a new mention element into the editor
const insertMention = (editor: ReactEditor, character: string) => {
  const mention = { type: 'mention', character, children: [{ text: '' }] }
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
}

export const handleMentionKeys = (event: any, editor: ReactEditor, index: number, setIndex: React.Dispatch<React.SetStateAction<number>>, search: string, target: Range, setTarget: React.Dispatch<React.SetStateAction<Range | undefined>>) => {
  const mentionables = getMentionables(search)
  switch (event.key) {
    case 'ArrowDown':
      event.preventDefault()
      const prevIndex = index >= mentionables.length - 1 ? 0 : index + 1
      setIndex(prevIndex)
      break
    case 'ArrowUp':
      event.preventDefault()
      const nextIndex = index <= 0 ? mentionables.length - 1 : index - 1
      setIndex(nextIndex)
      break
    case 'Tab':
    case 'Enter':
      event.preventDefault()
      Transforms.select(editor, target)
      insertMention(editor, mentionables[index])
      setTarget(undefined)
      break
    case 'Escape':
      event.preventDefault()
      setTarget(undefined)
      break
  }
}

export const toggleMentionsMenu = (editor: ReactEditor, ref: React.RefObject<HTMLDivElement>, search: string, target: Range | undefined) => {
  const mentionables = getMentionables(search)
  if (target && mentionables.length > 0) {
    const el = ref.current
    const domRange = ReactEditor.toDOMRange(editor, target)
    const rect = domRange.getBoundingClientRect()
    if (el) {
      el.style.top = `${rect.top + window.pageYOffset + 24}px`
      el.style.left = `${rect.left + window.pageXOffset}px`
    }
  }
}

export const renderMentionsMenu = (index: number, ref: React.RefObject<HTMLDivElement>, search: string, target: Range | undefined) => {
  const mentionables = getMentionables(search)
  if (target && mentionables.length > 0) {
    return (
      <MentionsMenu
        mentionables={mentionables}
        index={index}
        targetRef={ref} />
    )
  }
}