import React from 'react'
import TwoPanes from './two-panes'

type TPProps = {
  left: React.ReactNode
  middle: React.ReactNode
  right: React.ReactNode
  leftName: string
  rightName: string
}

const ThreePanes = ({left, middle, right, leftName, rightName}: TPProps) => {
  return (
    <TwoPanes
      name={leftName}
      pane1={<TwoPanes name={rightName} pane1={left} pane2={middle} />}
      pane2={right}
    />
  )
}

export default ThreePanes
