import React, {useEffect} from 'react'

import {useNavDispatch, useNavState} from '../../utils/nav-context'
import {useGetAllNotesSubscription} from '../../generated/types'
import {Loading, NotesList, OpenNotes} from '../../components'
import TwoPanes from '../../components/layouts/two-panes'

const Feed = () => {
  const navDispatch = useNavDispatch()
  const {openNotes} = useNavState()
  const openNoteIds = Object.values(openNotes).map((o) => o.noteId)
  const {data, loading, error} = useGetAllNotesSubscription()

  if (error) throw error
  useEffect(() => {
    navDispatch({workspaceId: null})
  }, [navDispatch])

  if (loading) return <Loading label="Loading feed" />

  const notesList = () => {
    return (
      <NotesList
        notes={data?.notes || []}
        onNoteClick={(noteId) => navDispatch({openNote: {noteId}})}
      />
    )
  }

  if (openNoteIds?.length > 0) {
    return (
      <TwoPanes
        pane1={notesList()}
        pane2={<OpenNotes />}
        name="userFeedNotePaneSplit"
        pane2Style={{overflow: 'hidden'}}
      />
    )
  } else {
    return <>{notesList()}</>
  }
}

export default Feed
