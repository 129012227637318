import React from 'react'
import {useTheme} from '@material-ui/core'

const Leaf = ({attributes, children, leaf}: any) => {
  const theme = useTheme()
  if (leaf.bold) {
    children = <b>{children}</b>
  }

  if (leaf.italic) {
    children = <i>{children}</i>
  }

  if (leaf.underlined) {
    children = <u>{children}</u>
  }

  if (leaf.struck) {
    children = <s>{children}</s>
  }

  if (leaf.highlight) {
    try {
      const highlight = theme.brand[leaf.highlight].highlight
      if (highlight) {
        return <mark {...attributes} style={{
          color: theme.palette.text.primary,
          backgroundColor: highlight,
          padding: '1px 2px',
          margin: '2px 0',
          borderRadius: '2px',
        }}>{children}</mark>
      }
    } catch(e) {
      // console.error('legacy marks in editor', e)
    }
  } 
    
  if (leaf.textcolor) {
    try {
      const textcolor = theme.brand[leaf.textcolor].textcolor
      if (textcolor) {
        return <span {...attributes} style={{fontWeight: 500, color: textcolor}}>{children}</span>
      }
    } catch(e) {
      // console.error('legacy marks in editor', e)
    }
  }

  return <span {...attributes}>{children}</span>
}

export default Leaf
