import React, {useEffect} from 'react'
import {Paper, makeStyles} from '@material-ui/core'
import {useParams, useHistory} from 'react-router-dom'
import SplitPane from 'react-split-pane'
import {
  useGetClientPublicNotesByChartSubscription,
  useGetClientPublicNoteQuery,
  PublicNotePreviewFragment,
  Chart_Periods_Enum,
  Chart_Styles_Enum,
  useGetClientChartSubscription,
} from '../../generated/types'
import {Loading, Chart} from '../../components'
import {NoteViewer} from '.'
import createPersistedState from 'use-persisted-state'
import routes from '../../routes'
import {OpenNote, useNavDispatch} from '../../utils/nav-context'
import {useUserState} from '../../utils/user-context'
import {} from '../../lib/channel-context'
import {ContentType} from '../../utils/note-props'

type CNProps = {
  clientId: string
  publicNoteId: number
  symbol: string
  period: Chart_Periods_Enum
  type: Chart_Styles_Enum
  onNoteClick: (openNote: OpenNote) => any
  notes: PublicNotePreviewFragment[]
}

const ChartWithChannel = ({
  notes,
  period,
  type,
  clientId,
  publicNoteId,
  symbol,
  onNoteClick,
}: CNProps) => {
  const {data, error} = useGetClientPublicNoteQuery({
    variables: {publicNoteId, clientId},
  })
  if (error) throw error
  const channelId = data?.public_note?.message_threads[0]?.channel_id

  return (
    <Chart
      contentTypes={[ContentType.Public]}
      symbol={symbol}
      period={period}
      openNotes={[publicNoteId]}
      type={type}
      notes={notes}
      onNoteClick={onNoteClick}
      channelId={channelId}
    />
  )
}

const ClientThreadNoteViewer = () => {
  const history = useHistory()
  const classes = useStyles()
  const {user} = useUserState()
  const {symbol, noteId: noteIdString} = useParams()
  const navDispatch = useNavDispatch()

  useEffect(() => {
    if (!symbol) return
    navDispatch({activeSymbol: symbol})
  }, [navDispatch, symbol])

  const {data, error, loading} = useGetClientPublicNotesByChartSubscription({
    variables: {clientId: user?.id || '', symbol},
  })

  const {data: chartData} = useGetClientChartSubscription({
    variables: {symbol, userId: user?.id || ''},
  })
  const period = chartData?.client_charts_by_pk?.period || Chart_Periods_Enum.Period_1mm
  const type = chartData?.client_charts_by_pk?.type || Chart_Styles_Enum.Candlestick

  const notes = data?.message_threads.map(t => t.public_note) || []

  const useVerticalPaneSplit = createPersistedState('clientScreenNoteListPaneSplit')
  const [verticalPaneSplit, setVerticalPaneSplit] = useVerticalPaneSplit(0)

  const handleNoteClick = (openNote: OpenNote) => {
    history.push(routes.client.chartNote.createUrl(symbol, openNote.noteId))
  }

  if (error) throw error
  if (loading) return <Loading />

  if (!noteIdString || !user) {
    return (
      <Paper variant="outlined" className={classes.root}>
        <Chart
          contentTypes={[ContentType.Public]}
          type={type}
          period={period}
          symbol={symbol}
          notes={notes || []}
          onNoteClick={handleNoteClick}
        />
      </Paper>
    )
  }

  const noteId = parseInt(noteIdString, 10)

  return (
    <SplitPane
      split="vertical"
      minSize={200}
      defaultSize={verticalPaneSplit || '60%'}
      style={{position: 'static', flex: 1}}
      onChange={size => setVerticalPaneSplit(size)}
      maxSize={-100}
      resizerStyle={{
        zIndex: 4000,
        width: 11,
        margin: '0 -5px',
        cursor: 'col-resize',
      }}
      pane2Style={{
        width: '100%',
      }}
    >
      <Paper variant="outlined" className={classes.threadPane}>
        <ChartWithChannel
          symbol={symbol}
          notes={notes}
          clientId={user.id}
          type={type}
          period={period}
          publicNoteId={noteId}
          onNoteClick={handleNoteClick}
        />
      </Paper>
      <NoteViewer noteId={noteId} />
    </SplitPane>
  )
}

const useStyles = makeStyles(() => ({
  chatPane: {
    height: '100%',
    overflow: 'auto',
  },
  threadPane: {
    height: '100%',
  },
  root: {
    flex: 1,
  },
}))

export default ClientThreadNoteViewer
