import {Transforms, Node} from 'slate'
import {ReactEditor} from 'slate-react'

const BREAKOUT_NODES = [
  'heading-one',
  'heading-two',
  'heading-three',
  'bulleted-list',
  'blockquote',
  'callout',
]

export const withBreaks = (editor: ReactEditor) => {
  const {insertBreak} = editor

  editor.insertBreak = () => {
    if (!editor.selection) return
    const currNode = Node.get(editor, editor.selection.focus.path)

    // If current node is an empty line of text
    if (currNode.object === 'text' && currNode.text === '') {
      // eslint-disable-next-line no-restricted-syntax
      for (const [n] of Node.ancestors(editor, editor.selection.focus.path, {reverse: true})) {
        if (BREAKOUT_NODES.includes(n.type as string)) {
          if (n.type === 'bulleted-list') {
            Transforms.unwrapNodes(editor, {match: n => n.type === 'list-item', split: true})
          }
          if (n.type === ('blockquote' || 'callout')) {
            Transforms.unwrapNodes(editor, {match: n => n.type === 'paragraph', split: true})
          }
          Transforms.unwrapNodes(editor, {
            match: n => BREAKOUT_NODES.includes(n.type as string),
            split: true,
          })
          return
        }
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const [currNode] of Node.ancestors(editor, editor.selection.focus.path, {reverse: true})) {
      if (currNode.type === 'list-item') {
        const block = {
          type: 'list-item',
          children: [
            {
              object: 'block',
              type: 'paragraph',
              children: [{object: 'text', text: ''}],
              data: {},
            },
          ],
          data: {tight: true},
        }

        Transforms.insertNodes(editor, block, {match: n => n.type === 'list-item'})
        return
      } else if (BREAKOUT_NODES.includes(currNode.type as string)) {
        const newLine = {
          type: 'paragraph',
          children: [
            {
              text: '',
            },
          ],
        }
        Transforms.insertNodes(editor, newLine)
        return
      }
    }
    insertBreak()
  }

  return editor
}
