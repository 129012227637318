import React from 'react'
import {SvgIcon} from '@material-ui/core'
import {Layouts_Enum} from '../../generated/types'

type LayoutIconProps = {
  layout: Layouts_Enum
}

const LayoutIcon = ({layout, ...props}: LayoutIconProps) => (
  <SvgIcon {...props}>
    <path fill="currentColor" fillRule="nonzero" d={LayoutIconsMap[layout]} />
  </SvgIcon>
)

export default LayoutIcon

// Generated from SVG exports of this Figma:
// https://www.figma.com/file/vE8YMVSmq4lJMZV5skQPa8/Outrline-Icons
const LayoutIconsMap: {[key in Layouts_Enum]?: string} = {
  [Layouts_Enum.Layout_1]:
    'M22,0 C23,0 24,1 24,2 L24,22 C24,23 23,24 22,24 L2,24 C1,24 0,23 0,22 L0,2 C0,1 1,0 2,0 L22,0 Z',
  [Layouts_Enum.Layout_2h]:
    'M24,13 L24,22 C24,23 23,24 22,24 L2,24 C1,24 0,23 0,22 L0,13 L24,13 Z M22,0 C23,0 24,1 24,2 L24,11 L0,11 L0,2 C0,1 1,0 2,0 L22,0 Z',
  [Layouts_Enum.Layout_2v]:
    'M11,0 L11,24 L2,24 C1,24 0,23 0,22 L0,2 C0,1 1,0 2,0 L11,0 Z M22,0 C23,0 24,1 24,2 L24,22 C24,23 23,24 22,24 L13,24 L13,0 L22,0 Z',
  [Layouts_Enum.Layout_3b]:
    'M0,13 L24,13 L24,22 C24,23 23,24 22,24 L2,24 C1,24 0,23 0,22 L0,13 Z M11,0 L11,11 L0,11 L0,2 C0,1 1,0 2,0 L11,0 Z M22,0 C23,0 24,1 24,2 L24,11 L13,11 L13,0 L22,0 Z',
  [Layouts_Enum.Layout_3v]:
    'M7,0 L7,24 L2,24 C1,24 -0,23 -0,22 L-0,2 C-0,1 1,0 2,0 L7,0 Z M22,0 C23,0 24,1 24,2 L24,2 L24,22 C24,23 23,24 22,24 L22,24 L17,24 L17,0 Z M15,0 L15,24 L9,24 L9,0 L15,0 Z',
  [Layouts_Enum.Layout_3h]:
    'M24,17 L24,22 C24,23 23,24 22,24 L2,24 C1,24 0,23 0,22 L0,17 L24,17 Z M24,9 L24,15 L0,15 L0,9 L24,9 Z M22,0 C23,0 24,1 24,2 L24,7 L0,7 L0,2 C0,1 1,0 2,0 L22,0 Z',
  [Layouts_Enum.Layout_3l]:
    'M11,0 L11,24 L2,24 C1,24 0,23 0,22 L0,2 C0,1 1,0 2,0 L11,0 Z M24,13 L24,22 C24,23 23,24 22,24 L13,24 L13,13 L24,13 Z M22,0 C23,0 24,1 24,2 L24,11 L13,11 L13,0 L22,0 Z',
  [Layouts_Enum.Layout_3r]:
    'M22,0 C23,0 24,1 24,2 L24,22 C24,23 23,24 22,24 L13,24 L13,0 L22,0 Z M11,13 L11,24 L2,24 C1,24 0,23 0,22 L0,13 L11,13 Z M11,0 L11,11 L0,11 L0,2 C0,1 1,0 2,0 L11,0 Z',
  [Layouts_Enum.Layout_3t]:
    'M24,13 L24,22 C24,23 23,24 22,24 L13,24 L13,13 L24,13 Z M11,13 L11,24 L2,24 C1,24 0,23 0,22 L0,13 L11,13 Z M22,0 C23,0 24,1 24,2 L24,11 L0,11 L0,2 C0,1 1,0 2,0 L22,0 Z',
  [Layouts_Enum.Layout_4]:
    'M24,13 L24,22 C24,23 23,24 22,24 L13,24 L13,13 L24,13 Z M11,13 L11,24 L2,24 C1,24 0,23 0,22 L0,13 L11,13 Z M11,0 L11,11 L0,11 L0,2 C0,1 1,0 2,0 L11,0 Z M22,0 C23,0 24,1 24,2 L24,11 L13,11 L13,0 L22,0 Z',
  [Layouts_Enum.Layout_4b]:
    'M24 13V22C24 23 23 24 22 24H2C1 24 0 23 0 22V13H24ZM7 0L7 11H0V2C0 1 1 0 2 0L2 0H7ZM22 0L22 0C23 0 24 1 24 2L24 2V11H17V0H22ZM15 0V11H9V0H15Z',
  [Layouts_Enum.Layout_4h]:
    'M-0,19 L24,19.001 L24,22 C24,23 23,24 22,24 L2,24 C1,24 -0,23 -0,22 L-0,19 Z M24,13 L24,17.001 L-0,17 L-0,13 L24,13 Z M24,7 L24,11 L-0,11 L-0,7 L24,7 Z M22,0 C23,0 24,1 24,2 L24,5 L-0,5 L-0,2 C-0,1 1,0 2,0 L22,0 Z',
  [Layouts_Enum.Layout_4l]:
    'M11 0V24H2C1 24 0 23 0 22V2C0 1 1 0 2 0H11ZM24 17V22L24 22C23 23 23 24 22 24L22 24H13V17H24ZM24 9V15H13V9H24ZM22 0C23 0 24 1 24 2L24 2V7L13 7V0H22Z',
  [Layouts_Enum.Layout_4r]:
    'M22 0C23 0 24 1 24 2V22C24 23 23 24 22 24H13V0H22ZM11 17V24H2C1 24 0 23 0 22L0 22V17H11ZM11 9V15H0V9H11ZM11 0V7L0 7V2C0 1 1 0 2 0H11Z',
  [Layouts_Enum.Layout_4t]:
    'M7 13L7 24H2C1 24 0 23 0 22V13H7ZM24 13V22C24 23 23 24 22 24L22 24H17V13H24ZM15 13V24H9V13H15ZM22 0C23 0 24 1 24 2V11H0V2C0 1 1 0 2 0H22Z',
  [Layouts_Enum.Layout_4v]:
    'M11,0 L11,24 L6.999,24 L7,0 L11,0 Z M22,0 C23,0 24,1 24,2 L24,22 C24,23 23,24 22,24 L19,24 L19,0 L22,0 Z M17,0 L17,24 L13,24 L13,0 L17,0 Z M4.999,24 L2,24 C1,24 0,23 0,22 L0,2 C0,1 1,0 2,0 L5,0 L4.999,24 Z',
  [Layouts_Enum.Layout_5b]:
    'M24,13 L24,22 C24,23 23,24 22,24 L2,24 C1,24 0,23 0,22 L0,13 L24,13 Z M11,0 L11,11 L7,11 L7,0 L11,0 Z M22,0 C23,0 24,1 24,2 L24,11 L19,11 L19,0 L22,0 Z M17,0 L17,11 L13,11 L13,0 L17,0 Z M5,0 L5,11 L0,11 L0,2 C0,1 1,0 2,0 L5,0 Z',
  [Layouts_Enum.Layout_5l]:
    'M24,19 L24,22 C24,23 23,24 22,24 L13,24 L13,19 L24,19 Z M11,-0 L11,24 L2,24 C1,24 0,23 0,22 L0,2 C0,1 1,-0 2,-0 L11,-0 Z M24,13 L24,17 L13,17 L13,13 L24,13 Z M24,7 L24,11 L13,11 L13,7 L24,7 Z M22,-0 C23,-0 24,1 24,2 L24,5 L13,5 L13,-0 L22,-0 Z',
  [Layouts_Enum.Layout_5r]:
    'M11,19 L11,24 L2,24 C1,24 -0,23 -0,22 L-0,19 L11,19 Z M22,-0 C23,-0 24,1 24,2 L24,22 C24,23 23,24 22,24 L13,24 L13,-0 L22,-0 Z M-0,13 L11,13 L11,17 L-0,17 L-0,13 Z M11,7 L11,11 L-0,11 L-0,7 L11,7 Z M11,-0 L11,5 L-0,5 L-0,2 C-0,1 1,-0 2,-0 L11,-0 Z',
  [Layouts_Enum.Layout_5t]:
    'M17,13 L17,24 L13,24 L13,13 L17,13 Z M5,13 L5,24 L2,24 C1,24 0,23 0,22 L0,13 L5,13 Z M11,13 L11,24 L7,24 L7,13 L11,13 Z M24,13 L24,22 C24,23 23,24 22,24 L19,24 L19,13 L24,13 Z M22,0 C23,0 24,1 24,2 L24,11 L0,11 L0,2 C0,1 1,0 2,0 L22,0 Z',
  [Layouts_Enum.Layout_6v]:
    'M24,13 L24,22 C24,23 23,24 22,24 L17,24 L17,13 L24,13 Z M7,13 L7,24 L2,24 C1,24 0,23 0,22 L0,13 L7,13 Z M15,13 L15,24 L9,24 L9,13 L15,13 Z M7,0 L7,11 L6.37879537,11 L0,11 L0,2 C0,1 1,0 2,0 L7,0 Z M22,0 C23,0 24,1 24,2 L24,11 L17,11 L17,0 L22,0 Z M15,0 L15,11 L9,11 L9,0 L15,0 Z',
  [Layouts_Enum.Layout_6h]:
    'M11,17 L11,24 L2,24 C1,24 -0,23 -0,22 L-0,17 L11,17 Z M24,17 L24,22 C24,23 23,24 22,24 L13,24 L13,17 L24,17 Z M-0,9 L11,9 L11,15 L-0,15 L-0,9 Z M24,9 L24,15 L13,15 L13,9 L24,9 Z M22,-0 C23,-0 24,1 24,2 L24,7 L13,7 L13,-0 L22,-0 Z M11,-0 L11,7 L-0,7 L-0,2 C-0,1 1,-0 2,-0 L11,-0 Z',
}
