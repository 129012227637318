import React, {useState} from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  CircularProgress,
} from '@material-ui/core'

import {ClientSelectionStepForm, EmailSelectionStepForm, MessageStepForm} from './step-forms'
import {NotePublishingInfo, FrontendClientFragment} from '../../../generated/types'
import routes from '../../../routes'
import {useNoteState} from '../../editor/note-context'

enum Steps {
  ClientSelection,
  ShouldSendEmail,
  Message,
}

type NWDProps = {
  isOpen: boolean
  onClose: () => void
}
const PublishNoteDialog = ({isOpen, onClose}: NWDProps) => {
  const {noteId, teamId, clients, title, publishNote} = useNoteState()
  const [activeStep, setActiveStep] = useState<Steps>(Steps.ClientSelection)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [shouldOpenEmail, setShouldOpenEmail] = useState(false)
  const [publishInfo, setPublishInfo] = useState<NotePublishingInfo>({
    noteId,
    shouldSendEmail: true,
  })
  const steps = ['Select clients', 'Choose how to share', 'Review']

  const handleNext = async () => {
    if (activeStep === Steps.ClientSelection) {
      setActiveStep(Steps.ShouldSendEmail)
    }
    if (activeStep === Steps.ShouldSendEmail) {
      if (publishInfo.shouldSendEmail) {
        setActiveStep(Steps.Message)
      } else {
        handleSubmit()
      }
    }
    if (activeStep === Steps.Message) {
      await handleSubmit()
    }
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    if (publishInfo.clientIds && !publishInfo.clientIds.length) {
      delete publishInfo.clientIds
    }
    const res = await publishNote(publishInfo)
    if (!res?.publishNote?.noteId) {
      throw new Error(`Publishing went wrong ${JSON.stringify(publishInfo)}`)
    }
    if (shouldOpenEmail) {
      const noteUrl = `${window.location.origin}${routes.client.note.createUrl(
        res.publishNote?.noteId,
      )}`
      const clientEmails = clients
        .filter(c => publishInfo.clientIds?.includes(c.user_id))
        .map(c => encodeURIComponent(c.user.email))
        .join(',')
      const mailto = `mailto:?subject=${title}&body=${noteUrl}&bcc=${clientEmails}`
      window.open(mailto)
    }
    setIsSubmitting(false)
    setPublishInfo({noteId, shouldSendEmail: true})
    setActiveStep(Steps.ClientSelection)
    onClose()
  }

  const handleBack = () => {
    if (activeStep === 1 && publishInfo.teamId) {
      setPublishInfo({...publishInfo, teamId: undefined})
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSetTeamId = () => {
    setPublishInfo({...publishInfo, teamId, clientIds: []})
    handleNext()
  }

  const handleClientListChange = (_: object, value: any) => {
    if (!value) return
    setPublishInfo({
      ...publishInfo,
      clientIds: (value as FrontendClientFragment[]).map(c => c.user_id),
    })
  }

  const handleSendEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const shouldSendEmail = event.target.value === 'outline'
    setShouldOpenEmail(event.target.value === 'local')
    setPublishInfo({...publishInfo, shouldSendEmail})
  }

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublishInfo({...publishInfo, message: event.target.value})
  }

  const isNextButtonEnabled = () => {
    switch (activeStep) {
      case 0:
        return publishInfo.teamId || publishInfo.clientIds?.length
      case 1:
        return publishInfo.shouldSendEmail !== undefined
      default:
        return true
    }
  }

  const getStepContent = () => {
    switch (activeStep) {
      case Steps.ClientSelection:
        return (
          <ClientSelectionStepForm
            onWholeTeamClick={handleSetTeamId}
            allClients={clients}
            onSelectedClientsListChange={handleClientListChange}
            clientsValue={clients.filter(c => publishInfo.clientIds?.includes(c.user_id))}
          />
        )
      case Steps.ShouldSendEmail:
        return (
          <EmailSelectionStepForm
            value={
              publishInfo.shouldSendEmail && publishInfo.shouldSendEmail
                ? 'outline'
                : shouldOpenEmail
                ? 'local'
                : 'none'
            }
            onChange={handleSendEmailChange}
          />
        )
      case Steps.Message:
        return <MessageStepForm value={publishInfo.message} onChange={handleMessageChange} />
      default:
        throw new Error('Unknown stepIndex')
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <DialogContent style={{paddingBottom: 30, minHeight: 350, overflow: 'hidden'}}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">Publish "{title}"</Typography>
          </Grid>
          <Grid item>{getStepContent()}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <div style={{flex: 1}} />
        <Button disabled={activeStep === 0 || isSubmitting} onClick={handleBack}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={!isNextButtonEnabled() || isSubmitting}
        >
          {isSubmitting ? (
            <CircularProgress color="secondary" size={20} />
          ) : activeStep === steps.length - 1 ? (
            'Publish'
          ) : (
            'Next'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PublishNoteDialog
