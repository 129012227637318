import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'

import {Loading, ChartGrid, OpenNotes} from '../../components'
import {useGetWorkspaceSubscription, Layouts_Enum} from '../../generated/types'
import {useNavDispatch, useNavState} from '../../utils/nav-context'
import {findWhere} from 'underscore'
import TwoPanes from '../../components/layouts/two-panes'
import ChartSidebar from '../../components/chart-sidebar'
import {makeStyles, Theme} from '@material-ui/core'

const WorkspaceWrapper = () => {
  const {workspaceId: workspaceIdString} = useParams()
  const navDispatch = useNavDispatch()
  const workspaceId = parseInt(workspaceIdString || '', 10)
  const {loading, error, data} = useGetWorkspaceSubscription({variables: {workspaceId}})
  const charts = data?.workspaces?.charts
  const {openNotes, isLandingPaneOpen} = useNavState()
  const openNoteIds = Object.values(openNotes).map((o) => o.noteId)
  const classes = useStyles({isLandingPaneOpen: isLandingPaneOpen})

  useEffect(() => {
    if (!workspaceId) return
    navDispatch({workspaceId})
  }, [navDispatch, workspaceId])

  useEffect(() => {
    if (!charts) return
    const firstChart = findWhere(charts, {position: 0})
    navDispatch({activeChart: 0, chartId: firstChart?.id || null})
  }, [navDispatch, charts])

  if (loading) return <Loading label />
  if (error) return <div>ERROR: {error.message}</div>

  const renderChartGrid = () => {
    return (
      <>
        <ChartGrid charts={charts} layout={data?.workspaces?.layout || Layouts_Enum.Layout_1} />
        <ChartSidebar />
      </>
    )
  }

  if (openNoteIds?.length > 0) {
    return (
      <div id="ChartAndNotesContainer" className={classes.notePaneStyle}>
        <TwoPanes
          pane1={renderChartGrid()}
          pane2={<OpenNotes />}
          name="userChartNotePaneSplit"
          pane1Style={{overflow: 'hidden'}}
          pane2Style={{overflow: 'hidden'}}
        />
      </div>
    )
  } else {
    return <>{renderChartGrid()}</>
  }
}

const useStyles = makeStyles<Theme, {isLandingPaneOpen: boolean}>((theme) => ({
  notePaneStyle: {
    overflow: "hidden",
    marginRight: (props) =>
      props.isLandingPaneOpen ? theme.shape.landingPaneWidth : theme.shape.landingPaneClosedWidth,
  },
}))
export default WorkspaceWrapper
