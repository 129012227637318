/* withUploads

a plugin component for the Slate Editor 
and supporting functions that enable file and 
image uploads to become elements within notes

*/

import {ReactEditor} from 'slate-react'
import {Transforms, Path} from 'slate'
import isUrl from '../../../utils/isUrl'
import {getFileData, uploadFileAsync} from '../../../utils/uploads'
import ApolloClient from 'apollo-client'

const imageExtensions = ['jpg', 'gif', 'png', 'jpeg']
type UploadFn = (file: File) => any

// inserts an image (sourced from a url) as an element in the editor at the specified path node
export const insertImage = (editor: ReactEditor, url: string, at: Path) => {
  const text = {text: ''}
  const image = {type: 'image', url, children: [text]}
  Transforms.insertNodes(editor, image, {at})
}

// inserts a file as a element in the editor
export const insertFile = async (editor: ReactEditor, file: File, uploadFn: UploadFn) => {
  const data = getFileData(file)
  /* data:
  url: null,
  type: image or file
  filename
  mimetype
  extension,
  lastModified
  */
  const node = {
    children: [{text: ''}],
    ...data,
  }
  Transforms.insertNodes(editor, node)
  // then waits for the file to upload
  const result = await uploadFn(file)
  if (result.url) {
    // and stores its new url in the file data in the editor node
    const at = ReactEditor.findPath(editor, node)
    Transforms.setNodes(editor, {url: result.url}, {at})
  }
}

// checks whether a file at a url is an image by comparing its extension to the array above
const isImageUrl = (url: string) => {
  if (!url || !isUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop() || ''
  return imageExtensions.includes(ext)
}

// enables uploading files and images within notes
// by providing a plugin to the slate editor
export const withUploads = (apolloClient: ApolloClient<object>, editor: ReactEditor) => {
  const {insertData, isVoid} = editor
  // uploads a file to datastores using Apollo Client
  // notably, uses a no-cache fetchPolicy
  const uploadFileAsyncWithClient = async (file: File) => uploadFileAsync(apolloClient, file)
  
  /* overwrites the Slate Editor's default isVoid method,
    setting image or file elements as void nodes,
    which prevents text displayed within image or file elements from being treated as editable */
  editor.isVoid = element => {
    return ['image', 'file'].includes(element.type as string) ? true : isVoid(element)
  }

  /* overwrites the Editor's insertData method
    accepting and caches data as plain text
    parsing it for files,
    and noticing if data is an imageUrl
    or else yielding default behavior
  */
  editor.insertData = data => {
    const text = data.getData('text/plain')
    const {files} = data
    if (files && files.length > 0) {
      // console.log({files})
      for (const file of files) {
        insertFile(editor, file, uploadFileAsyncWithClient)
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text, [])
    } else {
      insertData(data)
    }
  }

  return editor
}
