import React, {forwardRef, ReactNode} from 'react'
import {makeStyles} from '@material-ui/core/styles'

import WordIcon from './icons/word.svg'
import SheetIcon from './icons/xls.svg'
import PowerPointIcon from './icons/ppt.svg'
import PDFIcon from './icons/pdf.svg'

import BlockWrapper from './block'

export type FileType = 'doc' | 'sheet' | 'ppt' | 'pdf'

const EXTENSION_TO_FILE_TYPE: {[key: string]: FileType} = {
  pdf: 'pdf',
  doc: 'doc',
  docx: 'doc',
  xls: 'sheet',
  xlsx: 'sheet',
  ppt: 'ppt',
  pptx: 'ppt',
}

const FILE_ICONS: {[key in FileType]: ReactNode} = {
  doc: WordIcon,
  sheet: SheetIcon,
  ppt: PowerPointIcon,
  pdf: PDFIcon,
}

const FileElement = forwardRef((props: any, ref) => {
  const classes = useStyles()
  const {children, element} = props

  if (!element.url) {
    return <BlockWrapper {...props} ref={ref} />
  }

  const fileType: FileType = EXTENSION_TO_FILE_TYPE[element.extension]

  // @ts-ignore Typescript is complaining about SVGs being used for src
  const renderIcon = () => <img src={FILE_ICONS[fileType]} alt={fileType} />
  const renderExtension = () => <div className={classes.extension}>.{element.extension}</div>

  const content = (
    <>
      <div className={classes.background}>
        {Object.keys(FILE_ICONS).includes(fileType) ? renderIcon() : renderExtension()}
      </div>
      <div className={classes.content}>
        <h2 className={classes.filename}>{element.filename}</h2>
        <p className={classes.description}>
          Last Modified: {new Date(element.lastModified).toLocaleDateString()}
        </p>
        {children}
      </div>
    </>
  )

  return (
    <BlockWrapper
      {...props}
      ref={ref}
      content={content}
      onClick={() => window.open(element.url, '_blank')}
    >
      {children}
    </BlockWrapper>
  )
})

const useStyles = makeStyles(theme => ({
  filename: {
    margin: 0,
    color: theme.palette.text.primary,
  },
  description: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  content: {
    flex: 1,
    padding: 10,
    maxHeight: 120,
  },
  background: {
    height: 120,
    width: 120,
    textAlign: 'center',
    display: 'flex',
    alignContent: 'center',
    backgroundColor: '#8894AA',
    overflow: 'hidden',
  },
  extension: {
    backgroundImage: 'linear-gradient(135deg, #667991 0%, #486178 100%)',
    boxShadow: '0 4px 6px -2px #0008',
    borderRadius: 7,
    fontWeight: 700,
    display: 'inline-block',
    padding: '4px 8px',
    margin: 'auto',
    color: 'white',
    fontSize: 28,
  },
}))

export default FileElement
