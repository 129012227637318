import {Message, DataMessage, DataMessageTypes, TextMessageTypes} from './message-types'
import {UserMessage} from 'sendbird'

const transformTextMessage = (message: UserMessage): Message => ({
  createdAt: new Date(message.createdAt),
  type: TextMessageTypes.message,
  id: message.messageId,
  text: message.message,
  channelId: message.channelUrl,
  sender: {
    id: message.sender.userId,
    full_name: message.sender.nickname,
  },
})

const transformDataMessage = (message: UserMessage): DataMessage => ({
  id: message.messageId,
  createdAt: new Date(message.createdAt),
  type: DataMessageTypes.laserpointer,
  data: JSON.parse(message.data),
  channelId: message.channelUrl,
  sender: {
    id: message.sender.userId,
    full_name: message.sender.nickname,
  },
})

const transformMessage = (message: UserMessage): Message | DataMessage =>
  message.customType === 'annotation'
    ? transformDataMessage(message as UserMessage)
    : transformTextMessage(message as UserMessage)

export default transformMessage
