import React from 'react'
import {Chart_Periods_Enum, Chart_Styles_Enum} from '../../generated/types'
import {makeStyles, fade} from '@material-ui/core/styles'
import clsx from 'clsx'
import {Select, MenuItem, Button, ButtonGroup, ListItemIcon} from '@material-ui/core'
import TickerSelect from '../toolbar/ticker-select'
import {ChartLineIcon, ChartAreaIcon, ChartCandlestickIcon} from '../icons'

const chartStyleIcons = {
  [Chart_Styles_Enum.Line]: <ChartLineIcon />,
  [Chart_Styles_Enum.Area]: <ChartAreaIcon />,
  [Chart_Styles_Enum.Candlestick]: <ChartCandlestickIcon />,
}

type ChartToolbarProps = {
  symbol: string
  period: Chart_Periods_Enum
  type: Chart_Styles_Enum
  onChangeChartType: (type: Chart_Styles_Enum) => any
  onChangeChartPeriod: (period: Chart_Periods_Enum) => any
  onChangeChartSymbol: (symbol: string) => any
  canChangeSymbol?: boolean
}

const ChartToolbar = ({
  symbol,
  period,
  type,
  onChangeChartType,
  onChangeChartPeriod,
  onChangeChartSymbol,
  canChangeSymbol = true,
}: ChartToolbarProps) => {
  const classes = useStyles()

  const handleChangeChartType = (event: React.ChangeEvent<{value: unknown}>) =>
    onChangeChartType(event.target.value as Chart_Styles_Enum)

  type SBProps = {
    period: Chart_Periods_Enum
    label: string
  }
  const SelectButton = ({period: p, label, ...props}: SBProps) => (
    <Button
      classes={{root: clsx(classes.selectButton, {[classes.selectButtonFocus]: p === period})}}
      onClick={() => onChangeChartPeriod(p)}
      {...props}
    >
      {label}
    </Button>
  )

  return (
    <>
      {canChangeSymbol && <TickerSelect symbol={symbol} onSelect={onChangeChartSymbol} />}
      <ButtonGroup className={classes.periodSelect}>
        <SelectButton period={Chart_Periods_Enum.Period_5dm} label="5d" />
        <SelectButton period={Chart_Periods_Enum.Period_1mm} label="1m" />
        <SelectButton period={Chart_Periods_Enum.Period_6m} label="6m" />
        <SelectButton period={Chart_Periods_Enum.PeriodYtd} label="YTD" />
        <SelectButton period={Chart_Periods_Enum.Period_1y} label="1y" />
        <SelectButton period={Chart_Periods_Enum.Period_5y} label="5y" />
        <SelectButton period={Chart_Periods_Enum.PeriodMax} label="max" />
      </ButtonGroup>
      <Select
        value={type}
        onChange={handleChangeChartType}
        variant="outlined"
        renderValue={value => chartStyleIcons[value as Chart_Styles_Enum]}
        classes={{
          root: classes.selectType,
        }}
      >
        <MenuItem value="line">
          <ListItemIcon>
            <ChartLineIcon />
          </ListItemIcon>{' '}
          Line
        </MenuItem>
        <MenuItem value="area">
          <ListItemIcon>
            <ChartAreaIcon />
          </ListItemIcon>
          Area
        </MenuItem>
        <MenuItem value="candlestick">
          <ListItemIcon>
            <ChartCandlestickIcon />
          </ListItemIcon>
          Candlestick
        </MenuItem>
      </Select>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  periodSelect: {
    margin: theme.spacing(0, 1),
  },
  selectButton: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #dee4ea',
    borderColor: theme.brand.primary.main,
    color: fade(theme.palette.text.primary, 0.5),
    fontSize: 14,
    fontWeight: 'normal',
    letterSpacing: 0,
    lineHeight: '16px',
    padding: '12px 4px 12px 4px',
    textTransform: 'none',
  },
  selectButtonFocus: {
    borderColor: `${theme.brand.active.main} !important`,
    color: theme.palette.text.primary,
    fontWeight: 600,
    zIndex: 1,
  },
  selectType: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.brand.primary.main,
    padding: '9px 0px 5px 10px',
  },
}))

export default ChartToolbar
