import React from 'react'
import {InMemoryCache} from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import {createHttpLink} from 'apollo-link-http'
import {WebSocketLink} from 'apollo-link-ws'
import {split} from 'apollo-link'
import {ApolloProvider} from '@apollo/react-hooks'
import {setContext} from '@apollo/link-context'
import {getMainDefinition} from 'apollo-utilities'

import {useAuth0} from '../utils/auth0'

const AuthorizedApolloProvider: React.FC<{}> = ({children}) => {
  const {getTokenSilently} = useAuth0()

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_HASURA_HOST}/v1/graphql`,
  })

  const wsLink = new WebSocketLink({
    uri: `${process.env.REACT_APP_HASURA_WS_HOST}/v1/graphql`,
    options: {
      reconnect: true,
      // @todo: Determine correct connection parameters for Apollo client
      // @body: Looks like we've toggled the lazy flag previously, in an effort to get the client connection to work as expected.
      // In my case, I had to disable the lazy flag for the connection to succeed.
      lazy: true,
      connectionParams: async () => {
        const token = await getTokenSilently()
        return {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
    },
  })

  const combinedLink = split(
    ({query}) => {
      const def = getMainDefinition(query)
      return def.kind === 'OperationDefinition' && def.operation === 'subscription'
    },
    wsLink,
    httpLink,
  )

  const authLink = setContext(async () => {
    const token = await getTokenSilently()
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  })

  const apolloClient = new ApolloClient({
    // @ts-ignore (see https://github.com/apollographql/apollo-link/issues/538)
    link: authLink.concat(combinedLink),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  })
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
