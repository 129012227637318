import {GroupChannel} from 'sendbird'

export enum DataMessageTypes {
  laserpointer = 'laserpointer',
}

export enum TextMessageTypes {
  message = 'message',
  note = 'note',
}

export type LaserPointerData = {
  time: Date
  value: number
}

export type Sender = {
  id: string
  full_name: string
}

type BaseMessage = {
  id: number
  createdAt: Date
  channelId: string
  sender: Sender
}

export type Message = BaseMessage & {
  text: string
  type: TextMessageTypes
}

export type DataMessage = BaseMessage & {
  type: DataMessageTypes
  data: LaserPointerData
}

export type MessageData = LaserPointerData

export type MessageHash = {[key: number]: Message | DataMessage}
export type TypingUsersByChannel = {[key: string]: Array<Sender>}
export type Channels = {[key: string]: GroupChannel}

export type SendbirdState = {
  messages: MessageHash
  typingUsers: TypingUsersByChannel
  isLoading: boolean
  channels: Channels
  unreadCountPerClient: {[key: string]: number}
  loadChannelMessages: (channel: GroupChannel) => any
  getChannel: (channelId: string) => Promise<GroupChannel>
  sendMessage: (text: string, channel: GroupChannel) => any
  sendDataMessage: (data: any, channel: GroupChannel) => any
  createTeamChannel: (noteId: number) => Promise<string>
}

export type ChannelState = {
  messages: Message[]
  dataMessages: DataMessage[]
  typingUsers: Sender[]
  channel: GroupChannel | null
  members: Sender[]
  sendMessage: (text: string) => any
  sendDataMessage: (data: any) => any
  startTyping: () => any
  markAsRead: () => any
}
