import React from 'react'
import {Paper, makeStyles, ListSubheader, Divider} from '@material-ui/core'
import {useGetClientWithNotesSubscription} from '../../generated/types'
import {Loading, Chat} from '../../components'
import {useUserState} from '../../utils/user-context'
import {findWhere} from 'underscore'

type CPProps = {
  clientId: string
}

const UserClientGlobalChatPane = ({clientId}: CPProps) => {
  const {user} = useUserState()
  const classes = useStyles()
  if (!clientId) throw Error('Parameter missing in UserClientNoteViewer')

  const {loading, error, data} = useGetClientWithNotesSubscription({
    variables: {clientId},
  })

  const client = data?.client
  const channelId: string = findWhere(client?.team_contacts, {team_id: user?.outline_user?.team_id})
    ?.channel_id

  if (error) throw error
  if (loading || !client) return <Loading />

  return (
    <Paper variant="outlined" className={classes.chatPane}>
      <ListSubheader disableSticky={true}>
        Direct Message with {client.user.full_name}
      </ListSubheader>
      <Divider />
      <div style={{flex: 1}}>
        <Chat channelId={channelId} />
      </div>
    </Paper>
  )
}

const useStyles = makeStyles(() => ({
  chatPane: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default UserClientGlobalChatPane
