import React from 'react'
import {useHotkeys} from 'react-hotkeys-hook'
import Keymap from '../../utils/keymap'
import {Box, Paper} from '@material-ui/core'
import clsx from 'clsx'
import {makeStyles, fade} from '@material-ui/core/styles'

import {useNavState, useNavDispatch, OpenNote} from '../../utils/nav-context'
import Chart from './chart'
import {
  Layouts_Enum,
  Chart_Styles_Enum,
  FrontendChartFragment,
  PublicNotePreviewFragment,
  Chart_Periods_Enum,
  Note_Types_Enum,
} from '../../generated/types'
import {ContentType} from '../../utils/note-props'
import {LayoutsGridMap} from '../layouts'
import Placeholder from './placeholder'

type CGProps = {
  charts?: FrontendChartFragment[]
  layout: Layouts_Enum
  handleSelectChart: (id: number | null, position: number | null) => void
  handleCreateNote: (time: Date, type: Note_Types_Enum, symbol: string, context: ContentType) => void
  handleOpenNote: (openNote: OpenNote) => void
}

const ChartGrid = ({
  charts,
  handleCreateNote,
  layout,
  handleSelectChart,
  handleOpenNote,
}: CGProps) => {
  const {activeChart, openNotes} = useNavState()
  const openNoteIds = Object.values(openNotes).map(o => o.noteId)
  const dispatch = useNavDispatch()
  const classes = useStyles()
  useHotkeys(Keymap.CANCEL_SELECTION, () => handleSelectChart(null, null))

  const voidAction = () => {}

  const handlePlaceholderSelect = (position: number) => {
    dispatch({setIsSearchOpen: true})
    handleSelectChart(null, position)
  }

  let chartArray = [...Array(6)].map((_, i) => (
    <Placeholder
      isSelected={activeChart === i}
      onSelect={() => handlePlaceholderSelect(i)}
      key={i}
    />
  ))

  if (charts !== undefined) {
    charts.forEach((chart: FrontendChartFragment) => {
      chartArray[chart.position || 0] = (
        <Box
          style={{height: '100%'}}
          key={chart.id}
          className={clsx({
            [classes.activeChart]: activeChart === chart.position,
          })}
          onClick={() => handleSelectChart(chart.id, chart.position || 0)}
        >
          <Paper style={{height: '100%'}}>
            <Chart
              id={chart.id}
              position={chart.position || 0}
              notes={chart.notes as PublicNotePreviewFragment[]}
              openNotes={openNoteIds}
              onNoteClick={activeChart === chart.position ? handleOpenNote : voidAction}
              onCreateContent={(time: Date, type: Note_Types_Enum, context: ContentType) =>
                handleCreateNote(time, type, chart.symbol, context)
              }
              symbol={chart.symbol}
              period={chart.settings[0]?.period || Chart_Periods_Enum.Period_1mm}
              type={chart.settings[0]?.type || Chart_Styles_Enum.Candlestick}
            />
          </Paper>
        </Box>
      )
    })
  }
  const LayoutsGrid = LayoutsGridMap[layout]
  return <>{LayoutsGrid(chartArray)}</>
}

const useStyles = makeStyles(theme => ({
  activeChart: {
    borderRadius: 4,
    boxShadow: `0px 0px 0px 3px ${fade(theme.brand.secondary.main, 0.85)}`,
  },
}))

export default ChartGrid
