import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControlLabel,
  Grid,
  Switch,
} from '@material-ui/core'
import {amber, grey} from '@material-ui/core/colors'
import {withStyles} from '@material-ui/core/styles'

import {IconPicker} from '..'
import {
  GetWorkspacesQuery,
  GetWorkspacesDocument,
  useAddWorkspaceMutation,
  Permissions_Enum,
} from '../../generated/types'
import routes from '../../routes'

const PrivacySwitch = withStyles({
  switchBase: {
    color: grey[900],
    '&$checked': {
      color: amber[600],
    },
    '&$checked + $track': {
      backgroundColor: amber[300],
    },
  },
  checked: {},
  track: {},
})(Switch)

type NWDProps = {
  isOpen: boolean
  isPublic?: boolean
  setIsPublic?: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void
}
const NewWorkspaceDialog = ({
  isOpen,
  onClose,
  isPublic: defaultIsPublic,
  setIsPublic: defaultSetIsPublic,
}: NWDProps) => {
  const history = useHistory()
  const [name, setName] = useState('')
  const [icon, setIcon] = useState('')
  const [managedIsPublic, managedSetIsPublic] = useState(false)
  const isPublic = defaultIsPublic !== undefined ? defaultIsPublic : managedIsPublic
  const setIsPublic = defaultSetIsPublic || managedSetIsPublic

  const [addWorkspace] = useAddWorkspaceMutation({
    onCompleted: (data) => {
      setName('')
      setIcon('')
      onClose()
      const newWorkspace = data?.insert_workspaces?.returning[0]
      newWorkspace && history.push(routes.user.workspace.createUrl(newWorkspace.id))
    },
    update: (cache, {data}) => {
      const existingWorkspaces = cache.readQuery<GetWorkspacesQuery>({query: GetWorkspacesDocument})
      const newWorkspace = data?.insert_workspaces?.returning[0]
      if (!newWorkspace) return
      cache.writeQuery<GetWorkspacesQuery>({
        query: GetWorkspacesDocument,
        data: {workspaces: [...(existingWorkspaces?.workspaces || []), newWorkspace]},
      })
    },
  })

  const handleCreateClick = () => {
    addWorkspace({
      variables: {
        name,
        permission: isPublic ? Permissions_Enum.TeamVisible : Permissions_Enum.Private,
        icon,
      },
    })
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle id="form-dialog-title">
        Create {isPublic ? 'shared' : 'personal'} workspace
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <IconPicker value={icon} onChange={setIcon} />
          <TextField
            autoFocus
            id="name"
            required
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{flex: 1, marginLeft: 10}}
          />
        </Grid>
        <FormControlLabel
          control={
            <PrivacySwitch checked={isPublic} onChange={() => setIsPublic((prev) => !prev)} />
          }
          label={isPublic ? 'Everyone in your team can see this' : 'Only you can see this'}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCreateClick} color="primary" variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewWorkspaceDialog
