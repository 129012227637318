import React from 'react'
import {List, makeStyles} from '@material-ui/core'
import {NotePreviewFragment} from '../../generated/types'
import NoteListItem from './note-list-item'

type NLProps = {
  notes: NotePreviewFragment[]
  onNoteClick: (noteId: number) => void
}
const NotesList = ({notes, onNoteClick}: NLProps) => {
  const classes = useStyles()

  return (
    <List className={classes.root}>
      {notes
        .sort((a, b) => {
          return a.time > b.time ? -1 : a.time < b.time ? 1 : a.id - b.id
        })
        .map(note => (
          <NoteListItem
            note={note}
            channelId={note.channel_id}
            key={note.id}
            onClick={() => onNoteClick(note.id)}
          />
        ))}
    </List>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowY: 'auto',
    borderRadius: theme.shape.borderRadius,
  },
  thing: {
    textAlign: 'right',
  },
}))

export default NotesList
