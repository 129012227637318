import React from 'react'
import clsx from 'clsx'
import {Drawer, Icon, Box, IconButton} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {useNavState, useNavDispatch} from '../utils/nav-context'
import LandingPane from './panes/landing-pane'

const ChartSidebar = () => {
  const {isLandingPaneOpen} = useNavState()

  const dispatch = useNavDispatch()

  const classes = useStyles()

  const handleDrawer = () => {
    dispatch({setIsLandingPaneOpen: !isLandingPaneOpen})
  }

  const renderIcon = () => {
    return (
      <IconButton
        aria-label="right-drawer"
        onClick={handleDrawer}
        size="small"
        color="inherit"
        className={clsx(classes.menuButton, {
          [classes.buttonClosed]: !isLandingPaneOpen,
        })}
      >
        <Icon
          className={clsx(classes.icon,
            {[classes.iconOpen]: isLandingPaneOpen}
          )}>chevron_left</Icon>
      </IconButton>
    )
  }

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      className={clsx(classes.landingPaneDrawer, {
        [classes.landingPaneDrawerOpen]: isLandingPaneOpen,
        [classes.landingPaneDrawerClose]: !isLandingPaneOpen,
      })}
      classes={{
        paper: clsx({
          [classes.landingPaneDrawerOpen]: isLandingPaneOpen,
          [classes.landingPaneDrawerClose]: !isLandingPaneOpen,
        }),
      }}
    >
      <div className={classes.toolbar} />
      <Box position="relative">
        {renderIcon()}
        <LandingPane />
      </Box>
    </Drawer>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  landingPaneDrawer: {
    width: theme.shape.landingPaneWidth,
    height: '30%',
    whiteSpace: 'nowrap',
    borderLeft: 'none',
  },
  landingPaneDrawerOpen: {
    width: theme.shape.landingPaneWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderLeft: 'none',
  },
  landingPaneDrawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.shape.landingPaneClosedWidth,
  },
  drawerList: {
    paddingTop: theme.spacing(0.25),
  },
  menuButton: {
    position: 'absolute',
    top: 0,
    height: '2rem',
    margin: '0.5rem 0',
    width: '2rem',
    transition: 'transform 250ms',
    color: theme.palette.text.hint,
    zIndex: theme.zIndex.drawer + 1,
  },
  iconOpen: {
    transform: 'rotateZ(180deg)',
    fontSize: 24,
  },
  icon: {
    transition: 'transform 250ms',
  },
  buttonClosed: {
    transform: 'scale(0.75)',
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}))

export default ChartSidebar
