import React, {forwardRef} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {ReactTinyLink} from 'react-tiny-link'

import BlockWrapper from './block'

const LinkPreview = forwardRef((props: any, ref) => {
  const classes = useStyles()
  // const editor = useEditor()
  const {children, element, attributes} = props

  if (!element.meta) {
    return (
      <div ref={ref} {...attributes} contentEditable={false}>
        <ReactTinyLink
          cardSize="small"
          showGraphic={true}
          maxLine={2}
          minLine={1}
          url={element.url}
        />
        {children}
      </div>
    )
  }

  const coverImage = element.meta.image[0]
  const content = (
    <>
      {coverImage && (
        <div className={classes.image} style={{backgroundImage: `url(${coverImage})`}} />
      )}
      <div className={classes.content}>
        <h3 className={classes.title}>{element.meta.title}</h3>
        <p className={classes.description}>{element.meta.description}</p>
        {children}
      </div>
    </>
  )

  return (
    <BlockWrapper {...props} ref={ref} content={content}>
      {children}
    </BlockWrapper>
  )
})

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    color: theme.palette.text.primary,
  },
  description: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  content: {
    flex: 1,
    padding: 10,
    maxHeight: 120,
  },
  image: {
    height: 120,
    width: 120,
    textAlign: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    alignContent: 'center',
    backgroundColor: '#8894AA',
    overflow: 'hidden',
  },
}))

export default LinkPreview
