import React from 'react'
import {List, Divider, ListSubheader, ListItemSecondaryAction, Button} from '@material-ui/core'
import {makeStyles, fade} from '@material-ui/core/styles'

import {
  MessageThreadFragment,
  useGetClientWithNotesSubscription,
  PublicNotePreviewFragment,
} from '../../generated/types'
import NoteListItem from './note-list-item'

import routes from '../../routes'
import {Loading} from '..'
import {Link} from 'react-router-dom'

type NLProps = {
  clientId: string
  selectedNoteId?: number
}
const ThreadList = ({clientId, selectedNoteId}: NLProps) => {
  const classes = useStyles()
  const {loading, error, data} = useGetClientWithNotesSubscription({
    variables: {clientId},
  })

  const threads: MessageThreadFragment[] = data?.client?.user.message_threads || []

  if (error) throw error
  if (loading) return <Loading />

  const renderSubheader = () => (
    <ListSubheader className={classes.subheader}>
      Notes
      {!!selectedNoteId && (
        <ListItemSecondaryAction>
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to={routes.user.clientsClientNoteChart.createUrl(clientId, selectedNoteId)}
          >
            View Chart
          </Button>
        </ListItemSecondaryAction>
      )}
    </ListSubheader>
  )

  return (
    <List className={classes.root} subheader={renderSubheader()}>
      <Divider />
      {threads.map(thread => {
        if (!thread.public_note) return null
        return (
          <NoteListItem
            key={thread.public_note.id}
            note={thread.public_note as PublicNotePreviewFragment}
            selected={thread.public_note.id === selectedNoteId}
            channelId={thread.channel_id}
            to={routes.user.clientsClientNote.createUrl(thread.client_id, thread.public_note.id)}
          />
        )
      })}
    </List>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  unread: {
    backgroundColor: theme.palette.secondary.main,
  },
  subheader: {
    backgroundColor: fade(theme.palette.background.paper, 0.9),
  },
}))

export default ThreadList
