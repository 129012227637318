import React, {useState} from 'react'
import {makeStyles, Theme} from '@material-ui/core'
import clsx from 'clsx'
import {Typography, Grid, Box, IconButton, Icon} from '@material-ui/core'
import NoteMenu from './note-menu'
import {useNoteState} from './note-context'
import {useTheme} from '@material-ui/core/styles'

type NoteHeaderProps = {
  fullscreen: boolean
  onUndock: () => void
}

const NoteHeader = ({fullscreen, onUndock}: NoteHeaderProps) => {
  const {contentType, previewIcon: PreviewIcon, header, canEdit, onClose} = useNoteState()
  const theme = useTheme()
  const color = {color: theme.brand.context[contentType.toLowerCase()]}
  const classes = useStyles(color)
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchor(null)
  }

  return (
    <Box className={clsx(classes.header)}>
      <NoteMenu anchor={menuAnchor} onClose={handleMenuClose} />
      <Grid container alignItems="center">
        <PreviewIcon className={classes.headerIcon}/>
        <Typography className={classes.headerText}>{header}</Typography>
        <Grid item>
          {canEdit && (
            <IconButton onClick={handleMenuOpen} size="small">
              <Icon>more_horiz</Icon>
            </IconButton>
          )}
          {!fullscreen && (
            <>
              <IconButton size="small" onClick={onUndock}>
                <Icon>fullscreen</Icon>
              </IconButton>
              <IconButton size="small" onClick={onClose}>
                <Icon>close</Icon>
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles<Theme, {color: string}>((theme) => ({
  headerText: {
    flex: 1,
    marginLeft: theme.spacing(1),
  },
  headerIcon: {
    color: theme.palette.background.default,
    backgroundColor: props => props.color,
    borderRadius: '100%',
  },
  header: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
  },
}))

export default NoteHeader
