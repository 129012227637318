import React, {useEffect} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import useInterval from 'use-interval'
import {makeStyles} from '@material-ui/core/styles'

import {ClientToolbar, ClientSidebar} from '../../components'
import {useSetUserLastSeenMutation} from '../../generated/types'
import {ThreadViewer, ChartViewer} from '.'
import {useUserState} from '../../utils/user-context'
import routes, {ClientRoute} from '../../routes'
import {useNavDispatch} from '../../utils/nav-context'

const ClientHome = () => {
  const {user} = useUserState()
  const [updateLastSeen] = useSetUserLastSeenMutation()
  const classes = useStyles()
  const navDispatch = useNavDispatch()

  useEffect(() => {
    navDispatch({activeSymbol: null})
  }, [navDispatch])

  useInterval(() => {
    if (!user) return
    updateLastSeen({variables: {userId: user?.id}})
  }, 10 * 1000)

  return (
    <div style={{display: 'flex'}}>
      <ClientSidebar />
      <ClientToolbar />
      <div className={classes.content}>
        <div className={classes.toolbarSpacer} />
        <div className={classes.main}>
          <Switch>
            <ClientRoute path={routes.client.root.path} exact component={ThreadViewer} />
            <ClientRoute path={routes.client.note.path} component={ThreadViewer} />
            <ClientRoute path={routes.client.chart.path} exact component={ChartViewer} />
            <ClientRoute path={routes.client.chartNote.path} component={ChartViewer} />
            <Route>
              <Redirect to={routes.common.fourhundredandfour.createUrl()} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    height: '100vh',
    maxWidth: '100%',
    flexGrow: 1,
  },
  toolbarSpacer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  main: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100vh - 56px)',
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 'calc(100vh - 48px)',
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 64px)',
    },
  },
}))

export default ClientHome
