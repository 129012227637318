import React, {createContext, useContext, useState} from 'react'
import {useNavState} from '../../utils/nav-context'
import {makeStyles} from '@material-ui/core/styles'
import {Editor, EditorViewer} from '..'
import {Node} from 'slate'
import clsx from 'clsx'

import {
  useUpdateWorkspaceLandingPaneContentsMutation,
  useGetWorkspaceSubscription,
} from '../../generated/types'
import {useDebouncedCallback} from 'use-debounce'
import {NOTE_AUTOSAVE_INTERVAL_MS, SHOW_NOTE_SAVED_INTERVAL} from '../../config'
import {Box, Snackbar, SnackbarContent, Typography} from '@material-ui/core'
import {useUserState} from '../../utils/user-context'

export const BLANK: Node[] = [
  {
    type: 'heading-one',
    children: [{text: ''}],
  },
]
type LPProps = {
  workspaceId: number
  children: React.ReactNode
}

type LandingPaneState = {
  error?: any
  loading: boolean
  landingPaneContent: Node[]
  justSaved: boolean
  canEdit: boolean
  onChange: (content: Node[]) => void
}

const DefaultState: LandingPaneState = {
  landingPaneContent: [],
  loading: true,
  justSaved: false,
  canEdit: false,
  onChange: (content: Node[]) => null,
}

const LandingPaneStateContext = createContext<LandingPaneState>(DefaultState)

const LandingPaneProvider = ({workspaceId, children}: LPProps) => {
  const {loading, error, data} = useGetWorkspaceSubscription({variables: {workspaceId}})
  const landingPaneContentFromDb = data?.workspaces?.landing_pane_content
  const [updateLandingPaneContents] = useUpdateWorkspaceLandingPaneContentsMutation()
  const [justSaved, setJustSaved] = useState(false)
  // a debounced callback will only execute the callback after
  // it HASN'T been called for a given amount of time
  const [onChange] = useDebouncedCallback((content: Node[]) => {
    if (JSON.stringify(content) === JSON.stringify(landingPaneContentFromDb)) return
    if (!workspaceId) return
    setJustSaved(true)
    updateLandingPaneContents({variables: {id: workspaceId, landing_pane_content: content}})
    setTimeout(() => setJustSaved(false), SHOW_NOTE_SAVED_INTERVAL)
  }, NOTE_AUTOSAVE_INTERVAL_MS)

  const {user} = useUserState()
  const canEdit = (user && user.id === data?.workspaces?.owner) || false

  const values = {
    justSaved,
    canEdit,
  }

  const handlers = {
    onChange,
  }

  return (
    <LandingPaneStateContext.Provider
      value={{
        landingPaneContent: landingPaneContentFromDb,
        error,
        loading,
        ...values,
        ...handlers,
      }}
    >
      {children}
    </LandingPaneStateContext.Provider>
  )
}

const LandingPaneContainer = () => {
  const {workspaceId} = useNavState()

  if (!workspaceId) return <></>
  return (
    <LandingPaneProvider workspaceId={workspaceId}>
      <LandingPane />
    </LandingPaneProvider>
  )
}

const LandingPane = () => {
  const classes = useStyles()
  const {isLandingPaneOpen} = useNavState()

  const {landingPaneContent, onChange, justSaved, canEdit, loading} = useContext(
    LandingPaneStateContext,
  )

  if (!isLandingPaneOpen || loading) return <></>

  const renderEditor = () => {
    if (!canEdit) return <EditorViewer value={landingPaneContent} />
    return (
      <>
        <Editor value={landingPaneContent} onChange={onChange} />
        <Snackbar open={!!justSaved} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
          <SnackbarContent message="Saved" className={classes.justSaved} variant="outlined" />
        </Snackbar>
      </>
    )
  }

  return (
    <>
      <Box className={clsx(classes.header)}>
        <Typography className={clsx({[classes.indent]: isLandingPaneOpen})}>Updates</Typography>
      </Box>
      <Box className={clsx(classes.mainContent)}>{renderEditor()}</Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.action.selected,
    padding: theme.spacing(1.5),
  },
  mainContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  justSaved: {
    minWidth: 0,
    color: theme.palette.primary.main,
    background: theme.palette.background.default,
    padding: '0px 8px',
  },
  indent: {
    marginLeft: '1rem',
  },
}))

export default LandingPaneContainer
