import React, {forwardRef} from 'react'
import clsx from 'clsx'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {
  useSelected,
  useFocused,
} from 'slate-react'

const MentionElement = forwardRef((props: any, ref) => {
  const {attributes, children, element} = props
  const classes = useStyles()
  const selected = useSelected()
  const focused = useFocused()
  // TODO: update this hack logic when we use live mentionable person data
  const person = (/\s/.test(element.character))
  return (
    <span
      ref={ref}
      {...attributes}
      contentEditable={false}
      className={clsx(classes.mention, {
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.person]: person
      })}
    >
      @{element.character}
      {children}
    </span>
  )
})

const useStyles = makeStyles<Theme>(theme => ({
  selected: {
    boxShadow: `0 0 0 2px ${theme.brand.info.highlight}`,
  },
  focused: {
    boxShadow: 'none',
  },
  mention: {
    padding: '3px 3px 2px',
    margin: '0 1px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    borderRadius: '4px',
    backgroundColor: theme.palette.action.selected,
    color: theme.brand.success.textcolor,
    fontSize: '0.9em',
    userSelect: 'none',
  },
  person: {
    color: theme.brand.info.textcolor,
  }
}))

export default MentionElement