import React from 'react'
import {Paper, makeStyles, Box} from '@material-ui/core'
import {useGetClientPublicNoteQuery} from '../../generated/types'
import EditorViewer from '../editor/editor-viewer'
import {Loading, Chat} from '..'
import TwoPanes from '../layouts/two-panes'

type CPProps = {
  publicNoteId: number
  clientId: string
}

const UserClientGlobalChatPane = ({publicNoteId, clientId}: CPProps) => {
  const classes = useStyles()

  const {loading, error, data} = useGetClientPublicNoteQuery({
    variables: {publicNoteId, clientId},
  })

  const note = data?.public_note
  const channelId = note?.message_threads[0]?.channel_id

  if (error) throw error
  if (loading || !note || !channelId) return <Loading />

  return (
    <Paper variant="outlined" className={classes.chatPane}>
      <TwoPanes
        pane1={
          <Box p={2} className={classes.topPane}>
            <EditorViewer key={publicNoteId} value={note.content} />
          </Box>
        }
        pane2={
          <div className={classes.bottomPane}>
            <Chat channelId={channelId} />
          </div>
        }
        name="notePaneChatSplit"
        direction="horizontal"
      />
    </Paper>
  )
}

const useStyles = makeStyles(theme => ({
  chatPane: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  bottomPane: {
    borderTop: `1px solid ${theme.palette.divider}`,
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  topPane: {
    width: '100%',
    overflow: 'auto',
    height: '100%',
  },
}))

export default UserClientGlobalChatPane
