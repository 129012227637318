import {ReactEditor} from 'slate-react'
import {Editor} from 'slate'
import {isKeyHotkey} from 'is-hotkey'

// mod is option on mac, alt on windows
const HOTKEYS: {[key: string]: string} = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underlined',
}

export const handleHotkeys = (editor: ReactEditor, event: any) => {
  for (const hotkey in HOTKEYS) {
    if (isKeyHotkey(hotkey, event)) {
      event.preventDefault()
      const mark = HOTKEYS[hotkey]
      toggleFormat(editor, mark)
    }
  }
}

export const toggleFormat = (editor: ReactEditor, format: string, mark?: string) => {
  const isActive = checkFormatActive(editor, format)
  if (format === "highlight" || format === "textcolor") {
    const isSameMark = checkSameMark(editor, format, mark)
    clearMarks(editor)
    if (!isSameMark) {
      Editor.addMark(editor, format, mark)
    }
  } else if (format === "clear") {
    clearMarks(editor)
  } else if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

function clearMarks(editor: ReactEditor) {
  Editor.removeMark(editor, "highlight")
  Editor.removeMark(editor, "textcolor")
}

export const checkFormatActive = (editor: ReactEditor, format: string) => {
  if (!format) return
  const [match] = Editor.nodes(editor, {
    match: n => n[format] === true,
  })
  return !!match
}

export const checkSameMark = (editor: ReactEditor, format: string, mark?: string) => {
  if (!mark) return
  const [match] = Editor.nodes(editor, {
    match: n => n[format] === mark,
  })
  return !!match
}
