import React from 'react'
import {
  Chart_Periods_Enum,
  Chart_Styles_Enum,
  useGetClientChartSubscription,
  useUpdateClientChartMutation,
} from '../../generated/types'
import ChartToolbar from './chart-toolbar'
import {useUserState} from '../../utils/user-context'

type ClientChartToolbarProps = {
  symbol: string
}

const ClientChartToolbar = ({symbol}: ClientChartToolbarProps) => {
  const {user} = useUserState()
  const userId = user?.id || ''
  const [updateChartSettings] = useUpdateClientChartMutation()

  const {data} = useGetClientChartSubscription({variables: {symbol, userId}})
  const period = data?.client_charts_by_pk?.period || Chart_Periods_Enum.Period_1mm
  const type = data?.client_charts_by_pk?.type || Chart_Styles_Enum.Candlestick

  const handleUpdateChartSettings = (type: Chart_Styles_Enum, period: Chart_Periods_Enum) =>
    updateChartSettings({
      variables: {type, period, symbol},
      optimisticResponse: {
        insert_client_charts_one: {
          __typename: 'client_charts',
          period,
          symbol,
          type,
        },
      },
    })

  const handleChangeChartType = async (type: Chart_Styles_Enum) =>
    handleUpdateChartSettings(type, period)

  const handleChangeChartPeriod = (period: Chart_Periods_Enum) =>
    handleUpdateChartSettings(type, period)

  const handleChangeChartSymbol = (symbol: string) => {}
  return (
    <ChartToolbar
      symbol={symbol}
      period={period}
      type={type}
      canChangeSymbol={false}
      onChangeChartType={handleChangeChartType}
      onChangeChartPeriod={handleChangeChartPeriod}
      onChangeChartSymbol={handleChangeChartSymbol}
    />
  )
}

export default ClientChartToolbar
