import React from 'react'
import {useAddChartMutation} from '../../generated/types'
import TickerSelect from '../toolbar/ticker-select'
import {useNavDispatch} from '../../utils/nav-context'

type ECTProps = {
  workspaceId: number
  position: number
}

const EmptyChartToolbar = ({workspaceId, position}: ECTProps) => {
  const [addChart] = useAddChartMutation()
  const dispatch = useNavDispatch()

  const handleChangeChart = async (symbol: string) => {
    const result = await addChart({
      variables: {workspaceId, position, symbol},
    })
    const chartId = result.data?.insert_charts_one?.id
    dispatch({chartId})
  }

  return <TickerSelect symbol={''} onSelect={handleChangeChart} />
}

export default EmptyChartToolbar
