import React from 'react'
import {Redirect} from 'react-router-dom'

import {useUserState} from '../utils/user-context'
import routes from '../routes'
import {FullHeightLoading} from '../components'
import {useAuth0} from '../utils/auth0'

const Home = () => {
  const {isInitializing} = useAuth0()
  const {user, isFetchingUser} = useUserState()
  if (user?.outline_user) {
    const lastPath = localStorage.getItem('lastPath')
    if (lastPath) return <Redirect to={lastPath} />
    return <Redirect to={routes.user.root.createUrl()} />
  }
  if (user?.client_user) {
    return <Redirect to={routes.client.root.createUrl()} />
  }
  if (isFetchingUser || isInitializing) {
    return <FullHeightLoading />
  }
  return <Redirect to={routes.common.login.createUrl()} />
}

export default Home
