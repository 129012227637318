import React, {useEffect, useState} from 'react'
import {ReactEditor, useSlate} from 'slate-react'
import {Range, Editor} from 'slate'
import {toggleFormat} from './format'
import {Icon, IconButton, ButtonGroup, Popover} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'

const HoveringToolbar = () => {
  const [position, setPosition] = useState<{left: number; top: number}>({left: 0, top: 0})
  const [open, setOpen] = useState(false)
  const editor = useSlate()

  useEffect(() => {
    const {selection} = editor

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      setOpen(false)
      return
    }

    const domSelection = window.getSelection()
    if (!domSelection) return
    const domRange = domSelection.getRangeAt(0)
    const rect = domRange.getBoundingClientRect()
    // TODO: avoid showing the hovering toolbar until a user is done selecting text
    setTimeout(() => {
      setPosition({
        left: rect.left + window.pageXOffset + rect.width / 2,
        top: rect.top + window.pageYOffset,
      })
      setOpen(true)
    }, 1250)
  }, [editor.selection, editor])

  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={position}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      hideBackdrop={true}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <ButtonGroup color="primary" variant="outlined" size="small" style={{verticalAlign: 'middle'}}>
        <FormatButton format="bold" icon="format_bold" />
        <FormatButton format="italic" icon="format_italic" />
        <FormatButton format="underlined" icon="format_underlined" />
        <FormatButton format="struck" icon="format_strikethrough" />
        {/* <FormatButton format="coded" icon="code" /> */}
      </ButtonGroup>
      <ButtonGroup color="secondary" variant="outlined" size="small" style={{verticalAlign: 'middle'}}>
        <FormatButton format="highlight" icon="highlight" mark="info" />
        <FormatButton format="highlight" icon="highlight" mark="error" />
        <FormatButton format="highlight" icon="highlight" mark="success" />
        <FormatButton format="highlight" icon="highlight" mark="warning" />
      </ButtonGroup>
      <ButtonGroup color="secondary" variant="outlined" size="small" style={{verticalAlign: 'middle'}}>
        <FormatButton format="textcolor" icon="format_color_text" mark="info" />
        <FormatButton format="textcolor" icon="format_color_text" mark="error" />
        <FormatButton format="textcolor" icon="format_color_text" mark="success" />
        <FormatButton format="textcolor" icon="format_color_text" mark="warning" />
      </ButtonGroup>
    </Popover>
  )
}

type FormatButtonProps = {
  format: string
  icon: string
  className?: string
  mark?: string
}

const FormatButton = ({format, icon, className, mark}: FormatButtonProps) => {
  const editor = useSlate()
  const theme = useTheme()
  let style = {}
  if (mark) {
    const color = theme.brand[mark][format]
    style = (format === "highlight") ? {backgroundColor: color} : {color: color}
  }
  return (
    <IconButton
      size="small"
      className={className}
      style={style}
      onClick={() => {
        toggleFormat(editor, format, mark)
      }}
    >
      <Icon>{icon}</Icon>
    </IconButton>
  )
}

export default HoveringToolbar
