import React from 'react'

import {useNavDispatch, useNavState, OpenNote} from '../../utils/nav-context'
import {
  Layouts_Enum,
  FrontendChartFragment,
  useAddNoteMutation,
  Permissions_Enum,
  Note_Types_Enum,
  useUpdateNotePermissionMutation,
} from '../../generated/types'
import {ContentType} from '../../utils/note-props'
import ChartGrid from './chart-grid'
import {useSendbirdContext} from '../../lib/messages-context'

type CGProps = {
  charts?: FrontendChartFragment[]
  layout: Layouts_Enum
  readOnly?: boolean
}

const ChartGridContainer = ({charts, readOnly, layout = Layouts_Enum.Layout_1}: CGProps) => {
  const navDispatch = useNavDispatch()
  const {chartId, activeChart} = useNavState()
  const {createTeamChannel} = useSendbirdContext()
  const [updatePermissions] = useUpdateNotePermissionMutation()

  const handleOpenNote = (openNote: OpenNote) => {
    if (readOnly) return
    navDispatch({openNote})
  }

  const handleSelectChart = (id: number | null, position: number | null) => {
    if (readOnly || id === chartId || position === activeChart) return
    navDispatch({activeChart: position, chartId: id})
  }

  const setNotePermissions = async (noteId: number, permission: Permissions_Enum) => {
    const channelId = await createTeamChannel(noteId)
    updatePermissions({
      variables: {
        id: noteId,
        permission: permission,
        channelId: channelId,
      },
    })
  }

  const [addNote] = useAddNoteMutation({
    onCompleted: (data) => {
      const newNote = data?.insert_notes_one
      newNote && handleOpenNote({noteId: newNote.id})
      newNote && setNotePermissions(newNote.id, newNote.permission || Permissions_Enum.Private)
    },
  })

  const handleCreateNote = (
    time: Date,
    type: Note_Types_Enum,
    symbol: string,
    context: ContentType,
  ) => {
    if (readOnly) return
    else if (context === ContentType.Personal) {
      addNote({
        variables: {
          symbol,
          type,
          time: time.toISOString(),
          permission: Permissions_Enum.Private,
        },
      })
    } else if (context === ContentType.Team) {
      addNote({
        variables: {
          symbol,
          type,
          time: time.toISOString(),
          permission: Permissions_Enum.TeamVisible,
        },
      })
    }
  }

  return (
    <ChartGrid
      charts={charts}
      layout={layout}
      handleCreateNote={handleCreateNote}
      handleSelectChart={handleSelectChart}
      handleOpenNote={handleOpenNote}
    />
  )
}

export default ChartGridContainer
