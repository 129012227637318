import React from 'react'
import {Chip, makeStyles} from '@material-ui/core'

type UnreadProps = {
  count?: number | null
}

const UnreadBadge = ({count}: UnreadProps) => {
  const classes = useStyles()
  if (!count) return null
  return <Chip label={count} size="small" className={classes.unread} />
}

const useStyles = makeStyles(theme => ({
  unread: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: "bolder"
  },
}))

export default UnreadBadge
