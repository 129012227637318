import {useState} from 'react'
import {useApolloClient} from '@apollo/react-hooks'
import {GetSignedUrlDocument} from '../generated/types'
import ApolloClient from 'apollo-client'

const imageExtensions = ['jpg', 'gif', 'png', 'jpeg']

export type FileData = {
  url: string | null
  extension: string
  filename: string
  mimetype: string
  lastModified: Date
  type: string
}

export const getFileData = (file: File): FileData => {
  const extension = file.name.split('.').pop() || file.name
  const result = {
    url: null,
    type: imageExtensions.includes(extension) ? 'image' : 'file',
    filename: file.name,
    mimetype: file.type,
    extension,
    lastModified: new Date(file.lastModified),
  }
  return result
}

export const uploadFileAsync = async (apolloClient: ApolloClient<object>, file: File) => {
  apolloClient.defaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache"
    },
    query: {
      fetchPolicy: "no-cache"
    }
  }

  const hasuraResponse = await apolloClient.query({
    query: GetSignedUrlDocument,
    variables: {fileName: file.name, fileType: file.type},
  })
  const {signedUrl, url} = hasuraResponse.data.getSignedUrl
  const response = await fetch(signedUrl, {
    method: 'PUT',
    body: file,
  })
  if (response.status !== 200) {
    return false
  }

  const result = getFileData(file)
  result.url = url.startsWith('http') || url.startsWith('//') ? url : '//' + url
  return result
}

export const useUpload = () => {
  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState<FileData>()

  const uploadFile = async (file: File) => {
    setError(false)
    setLoading(true)

    const data = await uploadFileAsync(apolloClient, file)
    if (!data) {
      setError(true)
      return
    }
    setLoading(false)
    setData(data)
    return data
  }

  return {uploadFile, loading, error, data}
}
