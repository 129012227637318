import {AlogliaNoteType} from '../api/algolia'
import {Permissions_Enum, FrontendUserFragment} from '../generated/types'

export const isNoteVisibleForUser = (
  note: AlogliaNoteType,
  user: FrontendUserFragment | null,
): boolean => {
  if (!user) return false
  if (note.permission === Permissions_Enum.Public) return true
  if (
    note.permission === Permissions_Enum.OrgVisible &&
    note.organizationId === user.outline_user?.organization_id
  ) {
    return true
  }
  if (
    note.permission === Permissions_Enum.TeamVisible &&
    note.viewableByTeam === user.outline_user?.team_id
  ) {
    return true
  }
  if (note.permission === Permissions_Enum.Private && note.ownerId === user.id) return true
  return false
}
