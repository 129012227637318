import React from 'react'
import {Grid, ListItemText, ListItem, Chip, Typography} from '@material-ui/core'
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles'

import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {format} from 'date-fns'

import {
  PublicNotePreviewWithThreadsFragment,
  PublicNotePreviewFragment,
  NotePreviewFragment,
  Notes,
} from '../../generated/types'

import {UnreadBadge} from '..'
import {useChannel} from '../../lib/channel-context'
import useNoteProps from '../../utils/note-props'

type NotePreview =
  | PublicNotePreviewWithThreadsFragment
  | PublicNotePreviewFragment
  | NotePreviewFragment
  | Notes

type NLIProps = {
  note: NotePreview
  read?: boolean
  channelId?: string | null
  to?: string
  onClick?: (noteId: number) => any
  selected?: boolean
  hideIcon?: boolean
}

const NoteListItem = ({note, channelId, to, read, onClick, selected, hideIcon}: NLIProps) => {
  const {unread} = useChannel(channelId)
  const {previewIcon: Icon, contentType, unread: isNoteUnread} = useNoteProps(note)
  const theme = useTheme()
  const color = {color: theme.brand.context[contentType.toLowerCase()]}
  const classes = useStyles(color)
  const props = to ? {component: Link, to} : {onClick: () => onClick && onClick(note.id)}
  const time = note.time || note.last_modified

  return (
    <ListItem button {...props} selected={selected}>
      <Grid container justify="space-between" alignItems="center">
        <ListItemText
          className={clsx({
            [classes.read]: read,
          })}
          primary={
            <>
              <Typography
                variant="body1"
                style={{display: 'inline', fontWeight: isNoteUnread ? 'bold' : 'inherit'}}
              >
                {!hideIcon && <Icon className={classes.icon} />}
                {note.title}
              </Typography>
              {note.chart_symbol && (
                <Chip
                  size="small"
                  label={note.chart_symbol}
                  style={{marginLeft: 8, display: 'inline'}}
                />
              )}
            </>
          }
          secondary={
            <Typography variant="body2" style={{
              color: theme.palette.text.secondary,
              fontWeight: 300
            }}>
              {format(new Date(time), 'MMM d, yyyy')} · {note.user.full_name}
            </Typography>
          }
        />
        <UnreadBadge count={unread} />
      </Grid>
    </ListItem>
  )
}

export default NoteListItem

const useStyles = makeStyles<Theme, {color: string}>(theme => ({
  root: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },

  read: {
    opacity: 0.7,
  },
  icon: {
    width: '1.2rem',
    height: '1.2rem',
    padding: '0.1rem',
    borderRadius: '0.625rem',
    marginRight: '0.3125rem',
    color: theme.palette.background.default,
    backgroundColor: props => props.color,
    verticalAlign: '-4px',
  },
}))
