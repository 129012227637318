import {ReactEditor} from 'slate-react'
import {Transforms, Node} from 'slate'

export const withLayout = (editor: ReactEditor) => {
  const {normalizeNode} = editor
  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      // For children of top-level nodes
      for (const [childNode, childPath] of Node.children(editor, path)) {
        // Force the first block to be a header
        if (childPath[0] === 0) {
          Transforms.setNodes(editor, {type: 'heading-one'}, {at: childPath})
        }
        // if the last block is an image or link preview, append an empty paragraph
        if (
          childPath[0] === editor.children.length - 1 &&
          ['image', 'link-preview', 'file'].includes(childNode.type as string)
        ) {
          Transforms.insertNodes(editor, {type: 'paragraph', children: [{text: ''}]})
        }
      }
    }

    return normalizeNode([node, path])
  }

  return editor
}
