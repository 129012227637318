import React from 'react'
import {Grid, Typography, Paper, Box, Icon} from '@material-ui/core'
import {makeStyles, fade} from '@material-ui/core/styles'
import clsx from 'clsx'

type PlaceholderProps = {
  onSelect: () => void
  isSelected: boolean
}

const ChartPlaceholder = ({onSelect, isSelected}: PlaceholderProps) => {
  const classes = useStyles()
  return (
    <Box
      style={{height: '100%'}}
      onClick={onSelect}
      className={clsx({
        [classes.activeChart]: isSelected,
      })}
    >
      <Paper style={{height: '100%', opacity: 0.3}}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{height: '100%'}}
        >
          <Icon style={{fontSize: 80, marginBottom: 10, opacity: 0.5}}>add_circle</Icon>
          <Typography>Select to add a new chart</Typography>
        </Grid>
      </Paper>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  activeChart: {
    borderRadius: 4,
    boxShadow: `0px 0px 0px 3px ${fade(theme.brand.secondary.main, 0.85)}`,
  },
}))

export default ChartPlaceholder
