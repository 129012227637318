import React from 'react'
import {Grid, Typography} from '@material-ui/core'
import OutlineLoading from './outline-loading'

type LoadingProps = {
  label?: string | boolean
}

const Loading = ({label}: LoadingProps) => (
  <Grid item container direction="column" style={{flex: 1}} justify="center">
    <OutlineLoading size={100} style={{marginLeft: 'calc(50% - 50px)'}} />
    {label && (
      <Typography
        variant="button"
        align="center"
        display="block"
        color="textSecondary"
        style={{
          marginTop: 10,
          letterSpacing: 1,
        }}
      >
        &nbsp;&nbsp;&nbsp;{typeof label === 'string' ? label : 'loading'}...
      </Typography>
    )}
  </Grid>
)

export const FullHeightLoading = () => (
  <Grid container style={{height: '100vh'}}>
    <Loading />
  </Grid>
)

export default Loading
