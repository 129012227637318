import {useState, useEffect, useCallback} from 'react'
import SendBird, {GroupChannel} from 'sendbird'
import {useDebouncedCallback} from 'use-debounce'
import {useSendbirdContext} from './messages-context'
import {Message, MessageData, DataMessage, Sender, TextMessageTypes} from './message-types'
import {useUserState} from '../utils/user-context'

type useChannelOptions = {
  loadMessages: boolean
  markAsRead: boolean
}

export const useChannel = (channelId: string | null | undefined, options?: useChannelOptions) => {
  const {
    getChannel,
    isLoading: sendbirdIsLoading,
    messages: allMessages,
    loadChannelMessages,
    sendMessage: sendChannelMessage,
    sendDataMessage: sendChannelDataMessage,
  } = useSendbirdContext()

  const [messages, setMessages] = useState<Message[]>([])
  const [dataMessages, setDataMessages] = useState<DataMessage[]>([])
  const [channel, setChannel] = useState<SendBird.GroupChannel | null>(null)
  const {user} = useUserState()

  useEffect(() => {
    const messages = Object.values(allMessages).filter(m => m.channelId === channelId)
    setMessages(messages.filter(m => m.type === TextMessageTypes.message).map(m => m as Message))
    setDataMessages(
      messages.filter(m => m.type !== TextMessageTypes.message).map(m => m as DataMessage),
    )
  }, [allMessages, channelId])

  const loadChannel = useCallback(() => {
    if (!channelId) return
    getChannel(channelId).then((channel: GroupChannel) => {
      options?.loadMessages && loadChannelMessages(channel)
      options?.markAsRead && channel.markAsRead()
      setChannel(channel)
    })
  }, [channelId, options, loadChannelMessages, setChannel, getChannel])

  useEffect(() => {
    if (sendbirdIsLoading || !channelId || (channel && channel.url === channelId)) return
    loadChannel()
  }, [sendbirdIsLoading, channelId, loadChannel, channel])

  const members =
    channel?.members
      .filter(m => m.userId !== user?.id)
      .map(m => ({id: m.userId, full_name: m.nickname} as Sender)) || []

  const typingUsers =
    channel?.getTypingMembers().map(u => ({
      id: u.userId,
      full_name: u.nickname,
    })) || []

  const sendMessage = (text: string) => {
    if (!channel) throw new Error('Channel not initialized')
    sendChannelMessage(text, channel)
  }

  const sendDataMessage = (data: MessageData) => {
    if (!channel) throw new Error('Channel not initialized')
    sendChannelDataMessage(data, channel)
  }

  const [stopTypingAfterInterval] = useDebouncedCallback(() => {
    channel?.endTyping()
  }, 10000)

  const startTyping = () => {
    channel?.startTyping()
    stopTypingAfterInterval()
  }

  const markAsRead = () => {
    channel?.markAsRead()
  }

  return {
    messages,
    dataMessages,
    typingUsers,
    channel,
    members,
    unread: channel?.unreadMessageCount || 0,
    sendMessage,
    sendDataMessage,
    startTyping,
    markAsRead,
  }
}
