import React from 'react'
import {useParams} from 'react-router-dom'

import {Loading} from '../../components'
import {useGetWorkspaceSubscription} from '../../generated/types'
import {useTheme, Card, Divider, Typography} from '@material-ui/core'

const WorkspaceModelsWrapper = () => {
  const {workspaceId: workspaceIdString} = useParams()
  const workspaceId = parseInt(workspaceIdString || '', 10)
  const {loading, error, data} = useGetWorkspaceSubscription({variables: {workspaceId}})
  const workspaces = data?.workspaces
  const theme = useTheme()

  const renderFile = (filename: string, lastModified: string) => {
    return (
      <Card style={{display: 'flex', flexDirection: 'row', margin: '10px'}}>
        <img src="/static/media/xls.4818c87e.svg" alt="sheet" />
        <div className="jss8208" style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          paddingLeft: '1em'
        }}>
          <Typography variant="body1" className="jss8206">{filename}</Typography>
          <Typography variant="body2" style={{
            color: theme.palette.text.secondary,
            fontWeight: 300
          }} className="jss8207">{lastModified}</Typography>
          <div
            data-slate-spacer="true"
            style={{height: '0px', color: 'transparent', outline: 'none', position: 'absolute'}}
          >
            <span data-slate-node="text">
              <span data-slate-leaf="true">
                <span data-slate-zero-width="z" data-slate-length="0">
                  ﻿
                </span>
              </span>
            </span>
          </div>
        </div>

        <div
          data-slate-spacer="true"
          style={{height: '0px', color: 'transparent', outline: 'none', position: 'absolute'}}
        >
          <span data-slate-node="text">
            <span data-slate-leaf="true">
              <span data-slate-zero-width="z" data-slate-length="0"></span>
            </span>
          </span>
        </div>
      </Card>
    )
  }

  if (loading) return <Loading label />
  if (error) return <div>ERROR: {error.message}</div>
  return (
    <>
      <Typography variant='h6' style={{marginLeft: '12px'}}>Models for {workspaces?.name}</Typography>
      <Divider />
      {renderFile(workspaces?.name + ' Swap Pricer v2.xlsx', 'Last Modified: 09/01/2020')}
      {renderFile(workspaces?.name + ' Options Pricer v4.xlsx', 'Last Modified: 10/7/2020')}
      {renderFile(workspaces?.name + ' Exotic Options Pricer v8.xlsx', 'Last Modified: 09/24/2020')}
    </>
  )
}

export default WorkspaceModelsWrapper
