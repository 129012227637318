import React from 'react'
import {Note} from '../../components'
import {useParams} from 'react-router-dom'
import {useNavDispatch, useNavState} from '../../utils/nav-context'

const NoteWindow = () => {
  const {activeNote} = useNavState()
  const navDispatch = useNavDispatch()

  const {noteId: noteIdString} = useParams()
  const noteId = parseInt(noteIdString || '', 10)

  return (
    <Note
      noteId={noteId}
      active={activeNote === noteId}
      onClose={() => navDispatch({closeNote: noteId})}
      onFocus={() => navDispatch({activeNote: noteId})}
      fullscreen
    />
  )
}

export default NoteWindow
