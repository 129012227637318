import React, {useCallback, useMemo} from 'react'

import {createEditor, Node} from 'slate'
import {Slate, Editable, withReact} from 'slate-react'
import {Element, Leaf} from './elements'
import {BLANK} from './editor'

type NEProps = {
  value: Node[]
}
const NoteViewer = ({value}: NEProps) => {
  const renderElement = useCallback(props => <Element {...props} />, [])
  const editor = useMemo(() => withReact(createEditor()), [])
  return (
    <Slate editor={editor} value={value || BLANK} onChange={() => {}}>
      <Editable renderElement={renderElement} readOnly renderLeaf={props => <Leaf {...props} />} />
    </Slate>
  )
}

export default NoteViewer
