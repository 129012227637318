import React, {useEffect} from 'react'

import {useNavDispatch, useNavState} from '../../utils/nav-context'
import {Note} from '..'

const OpenNotes = () => {
  const {openNotes, activeNote} = useNavState()
  const navDispatch = useNavDispatch()

  useEffect(() => {
    const openNoteIds = Object.values(openNotes).map((o) => o.noteId)
    const closePreviousNotes = (idsToClose: Array<number>) => {
      idsToClose.forEach((i) => {
        navDispatch({closeNote: i})
      })
    }

    closePreviousNotes(openNoteIds.filter((item) => item !== activeNote))
  })

  if (!openNotes) return null

  return (
    <>
      {Object.values(openNotes).map(({noteId}) => (
        <Note
          key={noteId}
          onClose={() => navDispatch({closeNote: noteId})}
          noteId={noteId}
          active={activeNote === noteId}
          onFocus={() => navDispatch({activeNote: noteId})}
        />
      ))}
    </>
  )
}

export default OpenNotes
