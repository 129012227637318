import React from 'react'
import {List, ListItemText, ListItem, ListSubheader, Divider} from '@material-ui/core'
import {makeStyles, Theme, fade} from '@material-ui/core/styles'
import {useGetTeamClientsLiveSubscription} from '../../generated/types'
import {Link} from 'react-router-dom'

import {OnlineIcon, Loading, UnreadBadge} from '../../components'
import routes from '../../routes'
import {useUserState} from '../../utils/user-context'
import {useSendbirdContext} from '../../lib/messages-context'

type UCLProps = {
  selectedClientId?: string
}

const UserClientsList = ({selectedClientId}: UCLProps) => {
  const {user} = useUserState()
  const classes = useStyles()
  const userTeamId = user?.outline_user?.team_id

  const {loading, error, data} = useGetTeamClientsLiveSubscription({
    variables: {teamId: userTeamId || 1},
    skip: !userTeamId,
  })

  const clients = data?.team_contacts || []

  if (loading) return <Loading label="Loading clients" />
  if (error) throw error

  return (
    <List
      component="nav"
      className={classes.root}
      dense
      disablePadding
      subheader={<ListSubheader className={classes.subheader}>Clients</ListSubheader>}
      style={{height: '100%', width: '100%', maxWidth: '100%', backgroundColor: 'transparent'}}
    >
      <Divider />
      {clients.map(c => (
        <ClientListItem
          key={c.client.user_id}
          userId={c.client.user_id}
          isOnline={!!c.client.user.is_online}
          channelId={c.channel_id}
          name={c.client.user.full_name || ''}
          company={c.client.company.name || ''}
          selected={selectedClientId === c.client.user_id}
        />
      ))}
    </List>
  )
}

type CLIProps = {
  userId: string
  isOnline: boolean
  channelId?: string | null
  name: string
  company: string
  selected: boolean
}

const ClientListItem = ({userId, isOnline, channelId, name, company, selected}: CLIProps) => {
  const {unreadCountPerClient} = useSendbirdContext()
  const unread = unreadCountPerClient[userId]

  return (
    <ListItem
      button
      key={userId}
      component={Link}
      to={routes.user.clientsClient.createUrl(userId)}
      selected={selected}
    >
      <OnlineIcon online={isOnline} />
      <ListItemText primary={name} secondary={company} />
      <UnreadBadge count={unread} />
    </ListItem>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    maxWidth: 360,
  },
  subheader: {
    backgroundColor: fade(theme.palette.background.paper, 0.9),
  },
}))

export default UserClientsList
