export type IconType = {
  icon: string
  aliases: string[]
  category: string
}

export const Icons: IconType[] = [
  {
    icon: '3d_rotation',
    aliases: ['3d rotation', 'arrows', 'vr'],
    category: 'action',
  },
  {
    icon: 'accessibility',
    aliases: ['accessibility', 'user', 'person'],
    category: 'action',
  },
  {
    icon: 'accessible',
    aliases: ['accessible', 'wheelchair', 'user'],
    category: 'action',
  },
  {
    icon: 'account_balance',
    aliases: ['account balance', 'building', 'temple'],
    category: 'action',
  },
  {
    icon: 'account_balance_wallet',
    aliases: ['account balance wallet'],
    category: 'action',
  },
  {
    icon: 'account_box',
    aliases: ['account box', 'user'],
    category: 'action',
  },
  {
    icon: 'account_circle',
    aliases: ['account circle', 'user'],
    category: 'action',
  },
  {
    icon: 'add_shopping_cart',
    aliases: ['add shopping cart'],
    category: 'action',
  },
  {
    icon: 'alarm',
    aliases: ['alarm', 'time', 'clock'],
    category: 'action',
  },
  {
    icon: 'alarm_add',
    aliases: ['alarm add', 'time', 'clock'],
    category: 'action',
  },
  {
    icon: 'alarm_off',
    aliases: ['alarm off', 'time', 'clock'],
    category: 'action',
  },
  {
    icon: 'alarm_on',
    aliases: ['alarm on', 'time', 'clock', 'checkmark'],
    category: 'action',
  },
  {
    icon: 'all_out',
    aliases: ['all out', 'arrows', 'circle', 'directional', 'expand'],
    category: 'action',
  },
  {
    icon: 'android',
    aliases: ['android', 'operating system'],
    category: 'action',
  },
  {
    icon: 'announcement',
    aliases: ['announcement', 'comment', 'chat', 'message', 'balloon', 'exclamation'],
    category: 'action',
  },
  {
    icon: 'aspect_ratio',
    aliases: ['aspect ratio', 'screen', 'resolution', 'monitor'],
    category: 'action',
  },
  {
    icon: 'assessment',
    aliases: ['assessment', 'graph', 'chart'],
    category: 'action',
  },
  {
    icon: 'assignment',
    aliases: ['assignment', 'clipboard'],
    category: 'action',
  },
  {
    icon: 'assignment_ind',
    aliases: ['assignment ind', 'user', 'clipboard'],
    category: 'action',
  },
  {
    icon: 'assignment_late',
    aliases: ['assignment late', 'exclamation', 'clipboard'],
    category: 'action',
  },
  {
    icon: 'assignment_return',
    aliases: ['assignment return', 'clipboard', 'arrow'],
    category: 'action',
  },
  {
    icon: 'assignment_returned',
    aliases: ['assignment returned', 'clipboard', 'arrow'],
    category: 'action',
  },
  {
    icon: 'assignment_turned_in',
    aliases: ['assignment turned in', 'clipboard', 'checkmark'],
    category: 'action',
  },
  {
    icon: 'autorenew',
    aliases: ['autorenew', 'arrows', 'loader', 'loading', 'refresh'],
    category: 'action',
  },
  {
    icon: 'backup',
    aliases: ['backup', 'cloud', 'arrow', 'upload'],
    category: 'action',
  },
  {
    icon: 'book',
    aliases: ['book', 'bookmark'],
    category: 'action',
  },
  {
    icon: 'bookmark',
    aliases: ['bookmark', 'favorite'],
    category: 'action',
  },
  {
    icon: 'bookmark_border',
    aliases: ['bookmark border', 'favorite'],
    category: 'action',
  },
  {
    icon: 'bug_report',
    aliases: ['bug report', 'ticket', 'issue'],
    category: 'action',
  },
  {
    icon: 'build',
    aliases: ['build', 'wrench', 'spanner'],
    category: 'action',
  },
  {
    icon: 'cached',
    aliases: ['cached', 'arrows', 'refresh', 'loading', 'loader'],
    category: 'action',
  },
  {
    icon: 'camera_enhance',
    aliases: ['camera enhance', 'photo'],
    category: 'action',
  },
  {
    icon: 'card_giftcard',
    aliases: ['card giftcard', 'creditcard'],
    category: 'action',
  },
  {
    icon: 'card_membership',
    aliases: ['card membership', 'creditcard'],
    category: 'action',
  },
  {
    icon: 'card_travel',
    aliases: ['card travel', 'creditcard'],
    category: 'action',
  },
  {
    icon: 'change_history',
    aliases: ['change history', 'triangle'],
    category: 'action',
  },
  {
    icon: 'check_circle',
    aliases: ['check circle'],
    category: 'action',
  },
  {
    icon: 'chrome_reader_mode',
    aliases: ['chrome reader mode'],
    category: 'action',
  },
  {
    icon: 'class',
    aliases: ['class'],
    category: 'action',
  },
  {
    icon: 'code',
    aliases: ['code', 'parenthesis', 'brackets'],
    category: 'action',
  },
  {
    icon: 'compare_arrows',
    aliases: ['compare arrows', 'directional'],
    category: 'action',
  },
  {
    icon: 'copyright',
    aliases: ['copyright'],
    category: 'action',
  },
  {
    icon: 'credit_card',
    aliases: ['credit card', 'creditcard'],
    category: 'action',
  },
  {
    icon: 'dashboard',
    aliases: ['dashboard'],
    category: 'action',
  },
  {
    icon: 'date_range',
    aliases: ['date range', 'calendar', 'agenda'],
    category: 'action',
  },
  {
    icon: 'delete',
    aliases: ['delete', 'trashcan', 'bin', 'recycle', 'junk'],
    category: 'action',
  },
  {
    icon: 'delete_forever',
    aliases: ['delete forever', 'trashcan', 'bin', 'recycle', 'junk'],
    category: 'action',
  },
  {
    icon: 'description',
    aliases: ['description', 'file', 'document'],
    category: 'action',
  },
  {
    icon: 'dns',
    aliases: ['dns', 'server', 'network'],
    category: 'action',
  },
  {
    icon: 'done',
    aliases: ['done', 'checkmark'],
    category: 'action',
  },
  {
    icon: 'done_all',
    aliases: ['done all', 'checkmark'],
    category: 'action',
  },
  {
    icon: 'donut_large',
    aliases: ['donut large', 'circle'],
    category: 'action',
  },
  {
    icon: 'donut_small',
    aliases: ['donut small', 'circle'],
    category: 'action',
  },
  {
    icon: 'eject',
    aliases: ['eject'],
    category: 'action',
  },
  {
    icon: 'euro_symbol',
    aliases: ['euro symbol', 'financial', 'money'],
    category: 'action',
  },
  {
    icon: 'event',
    aliases: ['event', 'calendar', 'agenda'],
    category: 'action',
  },
  {
    icon: 'event_seat',
    aliases: ['event seat', 'chair', 'bench', 'sit'],
    category: 'action',
  },
  {
    icon: 'exit_to_app',
    aliases: ['exit to app', 'quit'],
    category: 'action',
  },
  {
    icon: 'explore',
    aliases: ['explore', 'compass', 'map'],
    category: 'action',
  },
  {
    icon: 'extension',
    aliases: ['extension', 'puzzle'],
    category: 'action',
  },
  {
    icon: 'face',
    aliases: ['face', 'person', 'user'],
    category: 'action',
  },
  {
    icon: 'favorite',
    aliases: ['favorite', 'heart', 'love'],
    category: 'action',
  },
  {
    icon: 'favorite_border',
    aliases: ['favorite border', 'heart', 'love'],
    category: 'action',
  },
  {
    icon: 'feedback',
    aliases: ['feedback', 'comment', 'message', 'chat'],
    category: 'action',
  },
  {
    icon: 'find_in_page',
    aliases: ['find in page', 'search'],
    category: 'action',
  },
  {
    icon: 'find_replace',
    aliases: ['find replace', 'search'],
    category: 'action',
  },
  {
    icon: 'fingerprint',
    aliases: ['fingerprint', 'biometrics', 'touchid'],
    category: 'action',
  },
  {
    icon: 'flight_land',
    aliases: ['flight land', 'airplane', 'flying'],
    category: 'action',
  },
  {
    icon: 'flight_takeoff',
    aliases: ['flight takeoff', 'airplane', 'flying'],
    category: 'action',
  },
  {
    icon: 'flip_to_back',
    aliases: ['flip to back'],
    category: 'action',
  },
  {
    icon: 'flip_to_front',
    aliases: ['flip to front'],
    category: 'action',
  },
  {
    icon: 'g_translate',
    aliases: ['g translate', 'google'],
    category: 'action',
  },
  {
    icon: 'gavel',
    aliases: ['gavel', 'hammer'],
    category: 'action',
  },
  {
    icon: 'get_app',
    aliases: ['get app', 'download'],
    category: 'action',
  },
  {
    icon: 'gif',
    aliases: ['gif', 'animated'],
    category: 'action',
  },
  {
    icon: 'grade',
    aliases: ['grade', 'star', 'rating', 'rate'],
    category: 'action',
  },
  {
    icon: 'group_work',
    aliases: ['group work', 'circle', 'film', 'reel'],
    category: 'action',
  },
  {
    icon: 'help',
    aliases: ['help', 'question mark'],
    category: 'action',
  },
  {
    icon: 'help_outline',
    aliases: ['help outline', 'question mark'],
    category: 'action',
  },
  {
    icon: 'highlight_off',
    aliases: ['highlight off', 'delete', 'disable', 'clear', 'remove', 'circle', 'times'],
    category: 'action',
  },
  {
    icon: 'history',
    aliases: ['history', 'time', 'revert', 'undo', 'clock'],
    category: 'action',
  },
  {
    icon: 'home',
    aliases: ['home', 'building', 'house'],
    category: 'action',
  },
  {
    icon: 'hourglass_empty',
    aliases: ['hourglass empty', 'time'],
    category: 'action',
  },
  {
    icon: 'hourglass_full',
    aliases: ['hourglass full', 'time'],
    category: 'action',
  },
  {
    icon: 'http',
    aliases: ['http', 'network', 'internet', 'web'],
    category: 'action',
  },
  {
    icon: 'https',
    aliases: ['https', 'network', 'internet', 'web', 'security', 'lock', 'ssl', 'encrypt'],
    category: 'action',
  },
  {
    icon: 'important_devices',
    aliases: ['important devices', 'computer', 'phone', 'monitor'],
    category: 'action',
  },
  {
    icon: 'info',
    aliases: ['info', 'circle', 'information'],
    category: 'action',
  },
  {
    icon: 'info_outline',
    aliases: ['info outline', 'circle', 'information'],
    category: 'action',
  },
  {
    icon: 'input',
    aliases: ['input', 'arrow'],
    category: 'action',
  },
  {
    icon: 'invert_colors',
    aliases: ['invert colors', 'drop', 'water', 'liquid'],
    category: 'action',
  },
  {
    icon: 'label',
    aliases: ['label', 'tag', 'badge'],
    category: 'action',
  },
  {
    icon: 'label_outline',
    aliases: ['label outline', 'tag', 'badge'],
    category: 'action',
  },
  {
    icon: 'language',
    aliases: ['language', 'earth', 'world', 'web'],
    category: 'action',
  },
  {
    icon: 'launch',
    aliases: ['launch', 'arrow'],
    category: 'action',
  },
  {
    icon: 'lightbulb_outline',
    aliases: ['lightbulb outline'],
    category: 'action',
  },
  {
    icon: 'line_style',
    aliases: ['line style', 'editor'],
    category: 'action',
  },
  {
    icon: 'line_weight',
    aliases: ['line weight', 'editor'],
    category: 'action',
  },
  {
    icon: 'list',
    aliases: ['list', 'editor'],
    category: 'action',
  },
  {
    icon: 'lock',
    aliases: ['lock', 'security'],
    category: 'action',
  },
  {
    icon: 'lock_open',
    aliases: ['lock open', 'security'],
    category: 'action',
  },
  {
    icon: 'lock_outline',
    aliases: ['lock outline', 'security'],
    category: 'action',
  },
  {
    icon: 'loyalty',
    aliases: ['loyalty', 'tag', 'badge', 'heart', 'love'],
    category: 'action',
  },
  {
    icon: 'markunread_mailbox',
    aliases: ['markunread mailbox'],
    category: 'action',
  },
  {
    icon: 'motorcycle',
    aliases: ['motorcycle', 'motorbike'],
    category: 'action',
  },
  {
    icon: 'note_add',
    aliases: ['note add', 'document', 'create'],
    category: 'action',
  },
  {
    icon: 'offline_pin',
    aliases: ['offline pin', 'checkmark', 'circle'],
    category: 'action',
  },
  {
    icon: 'opacity',
    aliases: ['opacity', 'drop', 'water', 'liquid'],
    category: 'action',
  },
  {
    icon: 'open_in_browser',
    aliases: ['open in browser'],
    category: 'action',
  },
  {
    icon: 'open_in_new',
    aliases: ['open in new'],
    category: 'action',
  },
  {
    icon: 'open_with',
    aliases: ['open with', 'arrows', 'directional', 'expand'],
    category: 'action',
  },
  {
    icon: 'pageview',
    aliases: ['pageview', 'search', 'find', 'looking glass'],
    category: 'action',
  },
  {
    icon: 'pan_tool',
    aliases: ['pan tool', 'hand', 'touch', 'drag'],
    category: 'action',
  },
  {
    icon: 'payment',
    aliases: ['payment', 'creditcard', 'financial', 'money'],
    category: 'action',
  },
  {
    icon: 'perm_camera_mic',
    aliases: ['perm camera mic'],
    category: 'action',
  },
  {
    icon: 'perm_contact_calendar',
    aliases: ['perm contact calendar', 'user', 'agenda'],
    category: 'action',
  },
  {
    icon: 'perm_data_setting',
    aliases: ['perm data setting', 'wifi', 'wireless', 'network', 'cellular', 'configure'],
    category: 'action',
  },
  {
    icon: 'perm_device_information',
    aliases: ['perm device information', 'phone', 'mobile'],
    category: 'action',
  },
  {
    icon: 'perm_identity',
    aliases: ['perm identity', 'user'],
    category: 'action',
  },
  {
    icon: 'perm_media',
    aliases: ['perm media', 'folders', 'directories'],
    category: 'action',
  },
  {
    icon: 'perm_phone_msg',
    aliases: ['perm phone msg', 'mobile', 'message'],
    category: 'action',
  },
  {
    icon: 'perm_scan_wifi',
    aliases: ['perm scan wifi', 'network', 'wireless'],
    category: 'action',
  },
  {
    icon: 'pets',
    aliases: ['pets', 'animal', 'claw', 'paw', 'dog', 'cat'],
    category: 'action',
  },
  {
    icon: 'picture_in_picture',
    aliases: ['picture in picture'],
    category: 'action',
  },
  {
    icon: 'picture_in_picture_alt',
    aliases: ['picture in picture alt'],
    category: 'action',
  },
  {
    icon: 'play_for_work',
    aliases: ['play for work', 'arrow'],
    category: 'action',
  },
  {
    icon: 'polymer',
    aliases: ['polymer'],
    category: 'action',
  },
  {
    icon: 'power_settings_new',
    aliases: ['power settings new', 'off'],
    category: 'action',
  },
  {
    icon: 'pregnant_woman',
    aliases: ['pregnant woman', 'user', 'person'],
    category: 'action',
  },
  {
    icon: 'print',
    aliases: ['print', 'printer', 'paper'],
    category: 'action',
  },
  {
    icon: 'query_builder',
    aliases: ['query builder', 'clock', 'time'],
    category: 'action',
  },
  {
    icon: 'question_answer',
    aliases: ['question answer', 'comment', 'message', 'chat'],
    category: 'action',
  },
  {
    icon: 'receipt',
    aliases: ['receipt', 'transaction'],
    category: 'action',
  },
  {
    icon: 'record_voice_over',
    aliases: ['record voice over', 'user', 'sound'],
    category: 'action',
  },
  {
    icon: 'redeem',
    aliases: ['redeem', 'present', 'giftcard'],
    category: 'action',
  },
  {
    icon: 'remove_shopping_cart',
    aliases: ['remove shopping cart'],
    category: 'action',
  },
  {
    icon: 'reorder',
    aliases: ['reorder', 'lines'],
    category: 'action',
  },
  {
    icon: 'report_problem',
    aliases: ['report problem', 'triangle', 'warning', 'danger', 'exclamation'],
    category: 'action',
  },
  {
    icon: 'restore',
    aliases: ['restore', 'clock', 'undo', 'time', 'history'],
    category: 'action',
  },
  {
    icon: 'restore_page',
    aliases: ['restore page', 'history', 'web', 'undo'],
    category: 'action',
  },
  {
    icon: 'room',
    aliases: ['room', 'location', 'gps'],
    category: 'action',
  },
  {
    icon: 'rounded_corner',
    aliases: ['rounded corner'],
    category: 'action',
  },
  {
    icon: 'rowing',
    aliases: ['rowing', 'sports'],
    category: 'action',
  },
  {
    icon: 'schedule',
    aliases: ['schedule', 'time', 'clock'],
    category: 'action',
  },
  {
    icon: 'search',
    aliases: ['search', 'find', 'looking glass'],
    category: 'action',
  },
  {
    icon: 'settings',
    aliases: ['settings', 'gear'],
    category: 'action',
  },
  {
    icon: 'settings_applications',
    aliases: ['settings applications', 'gear'],
    category: 'action',
  },
  {
    icon: 'settings_backup_restore',
    aliases: ['settings backup restore', 'time', 'history', 'undo'],
    category: 'action',
  },
  {
    icon: 'settings_bluetooth',
    aliases: ['settings bluetooth', 'network'],
    category: 'action',
  },
  {
    icon: 'settings_brightness',
    aliases: ['settings brightness', 'sun'],
    category: 'action',
  },
  {
    icon: 'settings_cell',
    aliases: ['settings cell', 'mobile', 'phone'],
    category: 'action',
  },
  {
    icon: 'settings_ethernet',
    aliases: ['settings ethernet', 'brackets', 'parenthesis', 'network'],
    category: 'action',
  },
  {
    icon: 'settings_input_antenna',
    aliases: ['settings input antenna', 'wifi', 'network'],
    category: 'action',
  },
  {
    icon: 'settings_input_component',
    aliases: ['settings input component', 'plugs', 'cable'],
    category: 'action',
  },
  {
    icon: 'settings_input_composite',
    aliases: ['settings input composite', 'plugs', 'cable'],
    category: 'action',
  },
  {
    icon: 'settings_input_hdmi',
    aliases: ['settings input hdmi', 'cable'],
    category: 'action',
  },
  {
    icon: 'settings_input_svideo',
    aliases: ['settings input svideo', 'plugs'],
    category: 'action',
  },
  {
    icon: 'settings_overscan',
    aliases: ['settings overscan', 'expand'],
    category: 'action',
  },
  {
    icon: 'settings_phone',
    aliases: ['settings phone'],
    category: 'action',
  },
  {
    icon: 'settings_power',
    aliases: ['settings power'],
    category: 'action',
  },
  {
    icon: 'settings_remote',
    aliases: ['settings remote', 'control'],
    category: 'action',
  },
  {
    icon: 'settings_voice',
    aliases: ['settings voice', 'microphone'],
    category: 'action',
  },
  {
    icon: 'shop',
    aliases: ['shop', 'briefcase'],
    category: 'action',
  },
  {
    icon: 'shop_two',
    aliases: ['shop two', 'briefcase'],
    category: 'action',
  },
  {
    icon: 'shopping_basket',
    aliases: ['shopping basket'],
    category: 'action',
  },
  {
    icon: 'shopping_cart',
    aliases: ['shopping cart'],
    category: 'action',
  },
  {
    icon: 'speaker_notes',
    aliases: ['speaker notes', 'message', 'chat', 'comment'],
    category: 'action',
  },
  {
    icon: 'speaker_notes_off',
    aliases: ['speaker notes off', 'message', 'chat', 'comment'],
    category: 'action',
  },
  {
    icon: 'spellcheck',
    aliases: ['spellcheck', 'letter', 'alphabet', 'checkmark'],
    category: 'action',
  },
  {
    icon: 'stars',
    aliases: ['stars', 'rating'],
    category: 'action',
  },
  {
    icon: 'store',
    aliases: ['store', 'building'],
    category: 'action',
  },
  {
    icon: 'subject',
    aliases: ['subject', 'lines', 'text'],
    category: 'action',
  },
  {
    icon: 'supervisor_account',
    aliases: ['supervisor account', 'user', 'administrator', 'person'],
    category: 'action',
  },
  {
    icon: 'swap_horiz',
    aliases: ['swap horiz', 'arrows'],
    category: 'action',
  },
  {
    icon: 'swap_vert',
    aliases: ['swap vert', 'arrows'],
    category: 'action',
  },
  {
    icon: 'swap_vertical_circle',
    aliases: ['swap vertical circle', 'arrows'],
    category: 'action',
  },
  {
    icon: 'system_update_alt',
    aliases: ['system update alt', 'arrow'],
    category: 'action',
  },
  {
    icon: 'tab',
    aliases: ['tab'],
    category: 'action',
  },
  {
    icon: 'tab_unselected',
    aliases: ['tab unselected'],
    category: 'action',
  },
  {
    icon: 'theaters',
    aliases: ['theaters', 'video', 'media', 'photography'],
    category: 'action',
  },
  {
    icon: 'thumb_down',
    aliases: ['thumb down', 'hand'],
    category: 'action',
  },
  {
    icon: 'thumb_up',
    aliases: ['thumb up', 'hand'],
    category: 'action',
  },
  {
    icon: 'thumbs_up_down',
    aliases: ['thumbs up down', 'hand'],
    category: 'action',
  },
  {
    icon: 'timeline',
    aliases: ['timeline', 'chart', 'graph', 'line'],
    category: 'action',
  },
  {
    icon: 'toc',
    aliases: ['toc', 'lines', 'text'],
    category: 'action',
  },
  {
    icon: 'today',
    aliases: ['today', 'agenda', 'calendar'],
    category: 'action',
  },
  {
    icon: 'toll',
    aliases: ['toll', 'circles'],
    category: 'action',
  },
  {
    icon: 'touch_app',
    aliases: ['touch app', 'hand'],
    category: 'action',
  },
  {
    icon: 'track_changes',
    aliases: ['track changes', 'radar', 'circle'],
    category: 'action',
  },
  {
    icon: 'translate',
    aliases: ['translate', 'alphabet', 'text', 'letter'],
    category: 'action',
  },
  {
    icon: 'trending_down',
    aliases: ['trending down', 'chart', 'graph', 'arrow'],
    category: 'action',
  },
  {
    icon: 'trending_flat',
    aliases: ['trending flat', 'arrow', 'chart', 'graph'],
    category: 'action',
  },
  {
    icon: 'trending_up',
    aliases: ['trending up', 'arrow', 'chart', 'graph'],
    category: 'action',
  },
  {
    icon: 'turned_in',
    aliases: ['turned in'],
    category: 'action',
  },
  {
    icon: 'turned_in_not',
    aliases: ['turned in not'],
    category: 'action',
  },
  {
    icon: 'update',
    aliases: ['update', 'clock', 'time', 'arrow', 'future'],
    category: 'action',
  },
  {
    icon: 'verified_user',
    aliases: ['verified user', 'shield', 'checkmark'],
    category: 'action',
  },
  {
    icon: 'view_agenda',
    aliases: ['view agenda', 'blocks'],
    category: 'action',
  },
  {
    icon: 'view_array',
    aliases: ['view array', 'blocks'],
    category: 'action',
  },
  {
    icon: 'view_carousel',
    aliases: ['view carousel', 'blocks'],
    category: 'action',
  },
  {
    icon: 'view_column',
    aliases: ['view column', 'blocks'],
    category: 'action',
  },
  {
    icon: 'view_day',
    aliases: ['view day', 'blocks'],
    category: 'action',
  },
  {
    icon: 'view_headline',
    aliases: ['view headline', 'blocks'],
    category: 'action',
  },
  {
    icon: 'view_list',
    aliases: ['view list', 'blocks'],
    category: 'action',
  },
  {
    icon: 'view_module',
    aliases: ['view module', 'blocks'],
    category: 'action',
  },
  {
    icon: 'view_quilt',
    aliases: ['view quilt', 'blocks'],
    category: 'action',
  },
  {
    icon: 'view_stream',
    aliases: ['view stream', 'blocks'],
    category: 'action',
  },
  {
    icon: 'view_week',
    aliases: ['view week', 'blocks'],
    category: 'action',
  },
  {
    icon: 'visibility',
    aliases: ['visibility', 'eye'],
    category: 'action',
  },
  {
    icon: 'visibility_off',
    aliases: ['visibility off', 'eye', 'invisible'],
    category: 'action',
  },
  {
    icon: 'watch_later',
    aliases: ['watch later', 'clock', 'time'],
    category: 'action',
  },
  {
    icon: 'work',
    aliases: ['work', 'briefcase', 'suitcase'],
    category: 'action',
  },
  {
    icon: 'youtube_searched_for',
    aliases: ['youtube searched for', 'arrow', 'looking glass'],
    category: 'action',
  },
  {
    icon: 'zoom_in',
    aliases: ['zoom in', 'looking glass'],
    category: 'action',
  },
  {
    icon: 'zoom_out',
    aliases: ['zoom out', 'looking glass'],
    category: 'action',
  },
  {
    icon: 'add_alert',
    aliases: ['add alert', 'bell', 'notification', 'reminder'],
    category: 'alert',
  },
  {
    icon: 'error',
    aliases: ['error', 'warning', 'danger', 'exclamation', 'circle'],
    category: 'alert',
  },
  {
    icon: 'error_outline',
    aliases: ['error outline', 'warning', 'danger', 'exclamation', 'circle'],
    category: 'alert',
  },
  {
    icon: 'warning',
    aliases: ['warning', 'danger', 'exclamation', 'triangle'],
    category: 'alert',
  },
  {
    icon: 'add_to_queue',
    aliases: ['add to queue', 'television', 'monitor', 'plus'],
    category: 'av',
  },
  {
    icon: 'airplay',
    aliases: ['airplay', 'apple', 'television', 'monitor'],
    category: 'av',
  },
  {
    icon: 'album',
    aliases: ['album', 'vinyl', 'cd', 'record'],
    category: 'av',
  },
  {
    icon: 'art_track',
    aliases: ['art track'],
    category: 'av',
  },
  {
    icon: 'av_timer',
    aliases: ['av timer', 'stopwatch'],
    category: 'av',
  },
  {
    icon: 'branding_watermark',
    aliases: ['branding watermark'],
    category: 'av',
  },
  {
    icon: 'call_to_action',
    aliases: ['call to action'],
    category: 'av',
  },
  {
    icon: 'closed_caption',
    aliases: ['closed caption', 'subtitles'],
    category: 'av',
  },
  {
    icon: 'equalizer',
    aliases: ['equalizer', 'graph', 'chart'],
    category: 'av',
  },
  {
    icon: 'explicit',
    aliases: ['explicit'],
    category: 'av',
  },
  {
    icon: 'fast_forward',
    aliases: ['fast forward'],
    category: 'av',
  },
  {
    icon: 'fast_rewind',
    aliases: ['fast rewind'],
    category: 'av',
  },
  {
    icon: 'featured_play_list',
    aliases: ['featured play list'],
    category: 'av',
  },
  {
    icon: 'featured_video',
    aliases: ['featured video'],
    category: 'av',
  },
  {
    icon: 'fiber_dvr',
    aliases: ['fiber dvr'],
    category: 'av',
  },
  {
    icon: 'fiber_manual_record',
    aliases: ['fiber manual record', 'dot', 'circle'],
    category: 'av',
  },
  {
    icon: 'fiber_new',
    aliases: ['fiber new'],
    category: 'av',
  },
  {
    icon: 'fiber_pin',
    aliases: ['fiber pin'],
    category: 'av',
  },
  {
    icon: 'fiber_smart_record',
    aliases: ['fiber smart record'],
    category: 'av',
  },
  {
    icon: 'forward_10',
    aliases: ['forward 10', 'arrow', 'circle'],
    category: 'av',
  },
  {
    icon: 'forward_30',
    aliases: ['forward 30', 'arrow', 'circle'],
    category: 'av',
  },
  {
    icon: 'forward_5',
    aliases: ['forward 5', 'arrow', 'circle'],
    category: 'av',
  },
  {
    icon: 'games',
    aliases: ['games', 'playstation', 'nintendo', 'xbox', 'dpad', 'controller'],
    category: 'av',
  },
  {
    icon: 'hd',
    aliases: ['hd'],
    category: 'av',
  },
  {
    icon: 'hearing',
    aliases: ['hearing'],
    category: 'av',
  },
  {
    icon: 'high_quality',
    aliases: ['high quality', 'hq'],
    category: 'av',
  },
  {
    icon: 'library_add',
    aliases: ['library add', 'collection'],
    category: 'av',
  },
  {
    icon: 'library_books',
    aliases: ['library books', 'collection'],
    category: 'av',
  },
  {
    icon: 'library_music',
    aliases: ['library music', 'collection'],
    category: 'av',
  },
  {
    icon: 'loop',
    aliases: ['loop', 'refresh', 'loader', 'loading'],
    category: 'av',
  },
  {
    icon: 'mic',
    aliases: ['mic', 'microphone'],
    category: 'av',
  },
  {
    icon: 'mic_none',
    aliases: ['mic none', 'microphone'],
    category: 'av',
  },
  {
    icon: 'mic_off',
    aliases: ['mic off', 'microphone'],
    category: 'av',
  },
  {
    icon: 'movie',
    aliases: ['movie', 'video', 'film'],
    category: 'av',
  },
  {
    icon: 'music_video',
    aliases: ['music video'],
    category: 'av',
  },
  {
    icon: 'new_releases',
    aliases: ['new releases', 'exclamation'],
    category: 'av',
  },
  {
    icon: 'not_interested',
    aliases: ['not interested', 'disabled', 'not allowed', 'banned', 'prohibited'],
    category: 'av',
  },
  {
    icon: 'note',
    aliases: ['note'],
    category: 'av',
  },
  {
    icon: 'pause',
    aliases: ['pause'],
    category: 'av',
  },
  {
    icon: 'pause_circle_filled',
    aliases: ['pause circle filled'],
    category: 'av',
  },
  {
    icon: 'pause_circle_outline',
    aliases: ['pause circle outline'],
    category: 'av',
  },
  {
    icon: 'play_arrow',
    aliases: ['play arrow'],
    category: 'av',
  },
  {
    icon: 'play_circle_filled',
    aliases: ['play circle filled'],
    category: 'av',
  },
  {
    icon: 'play_circle_outline',
    aliases: ['play circle outline'],
    category: 'av',
  },
  {
    icon: 'playlist_add',
    aliases: ['playlist add'],
    category: 'av',
  },
  {
    icon: 'playlist_add_check',
    aliases: ['playlist add check', 'checkmark'],
    category: 'av',
  },
  {
    icon: 'playlist_play',
    aliases: ['playlist play'],
    category: 'av',
  },
  {
    icon: 'queue',
    aliases: ['queue'],
    category: 'av',
  },
  {
    icon: 'queue_music',
    aliases: ['queue music'],
    category: 'av',
  },
  {
    icon: 'queue_play_next',
    aliases: ['queue play next'],
    category: 'av',
  },
  {
    icon: 'radio',
    aliases: ['radio'],
    category: 'av',
  },
  {
    icon: 'recent_actors',
    aliases: ['recent actors', 'contacts'],
    category: 'av',
  },
  {
    icon: 'remove_from_queue',
    aliases: ['remove from queue', 'television', 'monitor'],
    category: 'av',
  },
  {
    icon: 'repeat',
    aliases: ['repeat', 'arrows'],
    category: 'av',
  },
  {
    icon: 'repeat_one',
    aliases: ['repeat one', 'arrows'],
    category: 'av',
  },
  {
    icon: 'replay',
    aliases: ['replay', 'undo', 'rewind', 'arrow'],
    category: 'av',
  },
  {
    icon: 'replay_10',
    aliases: ['replay 10', 'rewind', 'arrow'],
    category: 'av',
  },
  {
    icon: 'replay_30',
    aliases: ['replay 30', 'rewind', 'arrow'],
    category: 'av',
  },
  {
    icon: 'replay_5',
    aliases: ['replay 5', 'rewind', 'arrow'],
    category: 'av',
  },
  {
    icon: 'shuffle',
    aliases: ['shuffle', 'arrows'],
    category: 'av',
  },
  {
    icon: 'skip_next',
    aliases: ['skip next'],
    category: 'av',
  },
  {
    icon: 'skip_previous',
    aliases: ['skip previous'],
    category: 'av',
  },
  {
    icon: 'slow_motion_video',
    aliases: ['slow motion video', 'circle', 'time'],
    category: 'av',
  },
  {
    icon: 'snooze',
    aliases: ['snooze', 'alarm'],
    category: 'av',
  },
  {
    icon: 'sort_by_alpha',
    aliases: ['sort by alpha', 'alphabet', 'letters'],
    category: 'av',
  },
  {
    icon: 'stop',
    aliases: ['stop', 'square'],
    category: 'av',
  },
  {
    icon: 'subscriptions',
    aliases: ['subscriptions', 'youtube', 'playlist', 'queue'],
    category: 'av',
  },
  {
    icon: 'subtitles',
    aliases: ['subtitles', 'captions'],
    category: 'av',
  },
  {
    icon: 'surround_sound',
    aliases: ['surround sound', 'audio'],
    category: 'av',
  },
  {
    icon: 'video_call',
    aliases: ['video call', 'camera'],
    category: 'av',
  },
  {
    icon: 'video_label',
    aliases: ['video label'],
    category: 'av',
  },
  {
    icon: 'video_library',
    aliases: ['video library', 'collection'],
    category: 'av',
  },
  {
    icon: 'videocam',
    aliases: ['videocam', 'camera'],
    category: 'av',
  },
  {
    icon: 'videocam_off',
    aliases: ['videocam off', 'camera'],
    category: 'av',
  },
  {
    icon: 'volume_down',
    aliases: ['volume down', 'sound'],
    category: 'av',
  },
  {
    icon: 'volume_mute',
    aliases: ['volume mute', 'sound'],
    category: 'av',
  },
  {
    icon: 'volume_off',
    aliases: ['volume off', 'sound'],
    category: 'av',
  },
  {
    icon: 'volume_up',
    aliases: ['volume up', 'sound'],
    category: 'av',
  },
  {
    icon: 'web',
    aliases: ['web', 'blocks'],
    category: 'av',
  },
  {
    icon: 'web_asset',
    aliases: ['web asset'],
    category: 'av',
  },
  {
    icon: 'business',
    aliases: ['business', 'building', 'flat', 'apartment'],
    category: 'communication',
  },
  {
    icon: 'call',
    aliases: ['call', 'phone'],
    category: 'communication',
  },
  {
    icon: 'call_end',
    aliases: ['call end', 'phone'],
    category: 'communication',
  },
  {
    icon: 'call_made',
    aliases: ['call made', 'arrow'],
    category: 'communication',
  },
  {
    icon: 'call_merge',
    aliases: ['call merge', 'arrow'],
    category: 'communication',
  },
  {
    icon: 'call_missed',
    aliases: ['call missed', 'arrow'],
    category: 'communication',
  },
  {
    icon: 'call_missed_outgoing',
    aliases: ['call missed outgoing', 'arrow'],
    category: 'communication',
  },
  {
    icon: 'call_received',
    aliases: ['call received', 'arrow'],
    category: 'communication',
  },
  {
    icon: 'call_split',
    aliases: ['call split', 'arrow'],
    category: 'communication',
  },
  {
    icon: 'chat',
    aliases: ['chat', 'message', 'comment'],
    category: 'communication',
  },
  {
    icon: 'chat_bubble',
    aliases: ['chat bubble', 'message', 'comment'],
    category: 'communication',
  },
  {
    icon: 'chat_bubble_outline',
    aliases: ['chat bubble outline', 'message', 'comment'],
    category: 'communication',
  },
  {
    icon: 'clear_all',
    aliases: ['clear all', 'lines', 'notifications'],
    category: 'communication',
  },
  {
    icon: 'comment',
    aliases: ['comment', 'chat', 'message'],
    category: 'communication',
  },
  {
    icon: 'contact_mail',
    aliases: ['contact mail', 'user', 'person'],
    category: 'communication',
  },
  {
    icon: 'contact_phone',
    aliases: ['contact phone', 'user', 'person'],
    category: 'communication',
  },
  {
    icon: 'contacts',
    aliases: ['contacts', 'user', 'person'],
    category: 'communication',
  },
  {
    icon: 'dialer_sip',
    aliases: ['dialer sip', 'phone'],
    category: 'communication',
  },
  {
    icon: 'dialpad',
    aliases: ['dialpad', 'dots', 'phone'],
    category: 'communication',
  },
  {
    icon: 'email',
    aliases: ['email', 'letter', 'envelope'],
    category: 'communication',
  },
  {
    icon: 'forum',
    aliases: ['forum', 'chat', 'messages', 'conversation'],
    category: 'communication',
  },
  {
    icon: 'import_contacts',
    aliases: ['import contacts', 'book', 'magazine'],
    category: 'communication',
  },
  {
    icon: 'import_export',
    aliases: ['import export', 'arrows'],
    category: 'communication',
  },
  {
    icon: 'invert_colors_off',
    aliases: ['invert colors off', 'drop', 'liquid', 'water'],
    category: 'communication',
  },
  {
    icon: 'live_help',
    aliases: ['live help', 'comment', 'question', 'faq'],
    category: 'communication',
  },
  {
    icon: 'location_off',
    aliases: ['location off', 'gps'],
    category: 'communication',
  },
  {
    icon: 'location_on',
    aliases: ['location on', 'gps'],
    category: 'communication',
  },
  {
    icon: 'mail_outline',
    aliases: ['mail outline', 'letter', 'envelope'],
    category: 'communication',
  },
  {
    icon: 'message',
    aliases: ['message', 'chat', 'comment'],
    category: 'communication',
  },
  {
    icon: 'no_sim',
    aliases: ['no sim'],
    category: 'communication',
  },
  {
    icon: 'phone',
    aliases: ['phone', 'mobile'],
    category: 'communication',
  },
  {
    icon: 'phonelink_erase',
    aliases: ['phonelink erase', 'mobile'],
    category: 'communication',
  },
  {
    icon: 'phonelink_lock',
    aliases: ['phonelink lock', 'mobile', 'security'],
    category: 'communication',
  },
  {
    icon: 'phonelink_ring',
    aliases: ['phonelink ring', 'mobile'],
    category: 'communication',
  },
  {
    icon: 'phonelink_setup',
    aliases: ['phonelink setup', 'mobile', 'settings'],
    category: 'communication',
  },
  {
    icon: 'portable_wifi_off',
    aliases: ['portable wifi off', 'network'],
    category: 'communication',
  },
  {
    icon: 'present_to_all',
    aliases: ['present to all', 'arrow'],
    category: 'communication',
  },
  {
    icon: 'ring_volume',
    aliases: ['ring volume', 'phone', 'mobile'],
    category: 'communication',
  },
  {
    icon: 'rss_feed',
    aliases: ['rss feed', 'wifi', 'network'],
    category: 'communication',
  },
  {
    icon: 'screen_share',
    aliases: ['screen share', 'monitor', 'laptop'],
    category: 'communication',
  },
  {
    icon: 'speaker_phone',
    aliases: ['speaker phone', 'mobile'],
    category: 'communication',
  },
  {
    icon: 'stay_current_landscape',
    aliases: ['stay current landscape', 'mobile', 'phone'],
    category: 'communication',
  },
  {
    icon: 'stay_current_portrait',
    aliases: ['stay current portrait', 'mobile', 'phone'],
    category: 'communication',
  },
  {
    icon: 'stay_primary_landscape',
    aliases: ['stay primary landscape', 'mobile', 'phone'],
    category: 'communication',
  },
  {
    icon: 'stay_primary_portrait',
    aliases: ['stay primary portrait', 'mobile', 'phone'],
    category: 'communication',
  },
  {
    icon: 'stop_screen_share',
    aliases: ['stop screen share', 'monitor', 'laptop'],
    category: 'communication',
  },
  {
    icon: 'swap_calls',
    aliases: ['swap calls', 'arrow'],
    category: 'communication',
  },
  {
    icon: 'textsms',
    aliases: ['textsms', 'message', 'chat', 'comment'],
    category: 'communication',
  },
  {
    icon: 'voicemail',
    aliases: ['voicemail'],
    category: 'communication',
  },
  {
    icon: 'vpn_key',
    aliases: ['vpn key', 'security'],
    category: 'communication',
  },
  {
    icon: 'add',
    aliases: ['add', 'plus', 'new', 'create'],
    category: 'content',
  },
  {
    icon: 'add_box',
    aliases: ['add box', 'plus', 'new', 'create', 'square'],
    category: 'content',
  },
  {
    icon: 'add_circle',
    aliases: ['add circle', 'plus', 'new', 'create'],
    category: 'content',
  },
  {
    icon: 'add_circle_outline',
    aliases: ['add circle outline', 'plus', 'new', 'create'],
    category: 'content',
  },
  {
    icon: 'archive',
    aliases: ['archive'],
    category: 'content',
  },
  {
    icon: 'backspace',
    aliases: ['backspace', 'delete', 'clear', 'remove'],
    category: 'content',
  },
  {
    icon: 'block',
    aliases: ['block', 'disable', 'not allowed', 'cancel', 'banned'],
    category: 'content',
  },
  {
    icon: 'clear',
    aliases: ['clear', 'disable', 'cancel', 'cross', 'times', 'x'],
    category: 'content',
  },
  {
    icon: 'content_copy',
    aliases: ['content copy', 'files'],
    category: 'content',
  },
  {
    icon: 'content_cut',
    aliases: ['content cut', 'scissor'],
    category: 'content',
  },
  {
    icon: 'content_paste',
    aliases: ['content paste', 'clipboard'],
    category: 'content',
  },
  {
    icon: 'create',
    aliases: ['create', 'pencil', 'new'],
    category: 'content',
  },
  {
    icon: 'delete_sweep',
    aliases: ['delete sweep', 'trashcan', 'bin'],
    category: 'content',
  },
  {
    icon: 'drafts',
    aliases: ['drafts', 'email', 'letter', 'envelope'],
    category: 'content',
  },
  {
    icon: 'filter_list',
    aliases: ['filter list', 'lines'],
    category: 'content',
  },
  {
    icon: 'flag',
    aliases: ['flag'],
    category: 'content',
  },
  {
    icon: 'font_download',
    aliases: ['font download', 'letter', 'square'],
    category: 'content',
  },
  {
    icon: 'forward',
    aliases: ['forward', 'arrow'],
    category: 'content',
  },
  {
    icon: 'gesture',
    aliases: ['gesture', 'line', 'drawing'],
    category: 'content',
  },
  {
    icon: 'inbox',
    aliases: ['inbox'],
    category: 'content',
  },
  {
    icon: 'link',
    aliases: ['link'],
    category: 'content',
  },
  {
    icon: 'low_priority',
    aliases: ['low priority', 'arrow', 'list'],
    category: 'content',
  },
  {
    icon: 'mail',
    aliases: ['mail', 'letter', 'envelope'],
    category: 'content',
  },
  {
    icon: 'markunread',
    aliases: ['markunread', 'letter', 'envelope'],
    category: 'content',
  },
  {
    icon: 'move_to_inbox',
    aliases: ['move to inbox'],
    category: 'content',
  },
  {
    icon: 'next_week',
    aliases: ['next week', 'suitcase', 'briefcase'],
    category: 'content',
  },
  {
    icon: 'redo',
    aliases: ['redo', 'arrow'],
    category: 'content',
  },
  {
    icon: 'remove',
    aliases: ['remove', 'minus', 'line'],
    category: 'content',
  },
  {
    icon: 'remove_circle',
    aliases: ['remove circle', 'not allowed', 'disable', 'banned'],
    category: 'content',
  },
  {
    icon: 'remove_circle_outline',
    aliases: ['remove circle outline', 'not allowed', 'disable', 'banned'],
    category: 'content',
  },
  {
    icon: 'reply',
    aliases: ['reply', 'arrow'],
    category: 'content',
  },
  {
    icon: 'reply_all',
    aliases: ['reply all', 'arrows'],
    category: 'content',
  },
  {
    icon: 'report',
    aliases: ['report', 'exclamation'],
    category: 'content',
  },
  {
    icon: 'save',
    aliases: ['save', 'floppy', 'diskette'],
    category: 'content',
  },
  {
    icon: 'select_all',
    aliases: ['select all'],
    category: 'content',
  },
  {
    icon: 'send',
    aliases: ['send', 'chat', 'message', 'telegram'],
    category: 'content',
  },
  {
    icon: 'sort',
    aliases: ['sort', 'lines'],
    category: 'content',
  },
  {
    icon: 'text_format',
    aliases: ['text format', 'letter'],
    category: 'content',
  },
  {
    icon: 'unarchive',
    aliases: ['unarchive', 'arrow'],
    category: 'content',
  },
  {
    icon: 'undo',
    aliases: ['undo', 'arrow'],
    category: 'content',
  },
  {
    icon: 'weekend',
    aliases: ['weekend', 'couch', 'seat', 'chair'],
    category: 'content',
  },
  {
    icon: 'access_alarm',
    aliases: ['access alarm', 'time', 'clock'],
    category: 'device',
  },
  {
    icon: 'access_alarms',
    aliases: ['access alarms', 'time', 'clock'],
    category: 'device',
  },
  {
    icon: 'access_time',
    aliases: ['access time', 'time', 'clock'],
    category: 'device',
  },
  {
    icon: 'add_alarm',
    aliases: ['add alarm', 'time', 'clock', 'plus'],
    category: 'device',
  },
  {
    icon: 'airplanemode_active',
    aliases: ['airplanemode active', 'flying'],
    category: 'device',
  },
  {
    icon: 'airplanemode_inactive',
    aliases: ['airplanemode inactive', 'flying'],
    category: 'device',
  },
  {
    icon: 'battery_20',
    aliases: ['battery 20'],
    category: 'device',
  },
  {
    icon: 'battery_30',
    aliases: ['battery 30'],
    category: 'device',
  },
  {
    icon: 'battery_50',
    aliases: ['battery 50'],
    category: 'device',
  },
  {
    icon: 'battery_60',
    aliases: ['battery 60'],
    category: 'device',
  },
  {
    icon: 'battery_80',
    aliases: ['battery 80'],
    category: 'device',
  },
  {
    icon: 'battery_90',
    aliases: ['battery 90'],
    category: 'device',
  },
  {
    icon: 'battery_alert',
    aliases: ['battery alert', 'exclamation'],
    category: 'device',
  },
  {
    icon: 'battery_charging_20',
    aliases: ['battery charging 20'],
    category: 'device',
  },
  {
    icon: 'battery_charging_30',
    aliases: ['battery charging 30'],
    category: 'device',
  },
  {
    icon: 'battery_charging_50',
    aliases: ['battery charging 50'],
    category: 'device',
  },
  {
    icon: 'battery_charging_60',
    aliases: ['battery charging 60'],
    category: 'device',
  },
  {
    icon: 'battery_charging_80',
    aliases: ['battery charging 80'],
    category: 'device',
  },
  {
    icon: 'battery_charging_90',
    aliases: ['battery charging 90'],
    category: 'device',
  },
  {
    icon: 'battery_charging_full',
    aliases: ['battery charging full', 'lightning'],
    category: 'device',
  },
  {
    icon: 'battery_full',
    aliases: ['battery full'],
    category: 'device',
  },
  {
    icon: 'battery_std',
    aliases: ['battery std'],
    category: 'device',
  },
  {
    icon: 'battery_unknown',
    aliases: ['battery unknown', 'question'],
    category: 'device',
  },
  {
    icon: 'bluetooth',
    aliases: ['bluetooth', 'wireless', 'network'],
    category: 'device',
  },
  {
    icon: 'bluetooth_connected',
    aliases: ['bluetooth connected', 'wireless', 'network'],
    category: 'device',
  },
  {
    icon: 'bluetooth_disabled',
    aliases: ['bluetooth disabled', 'wireless', 'network'],
    category: 'device',
  },
  {
    icon: 'bluetooth_searching',
    aliases: ['bluetooth searching', 'wireless', 'network'],
    category: 'device',
  },
  {
    icon: 'brightness_auto',
    aliases: ['brightness auto'],
    category: 'device',
  },
  {
    icon: 'brightness_high',
    aliases: ['brightness high'],
    category: 'device',
  },
  {
    icon: 'brightness_low',
    aliases: ['brightness low'],
    category: 'device',
  },
  {
    icon: 'brightness_medium',
    aliases: ['brightness medium'],
    category: 'device',
  },
  {
    icon: 'data_usage',
    aliases: ['data usage', 'circle'],
    category: 'device',
  },
  {
    icon: 'developer_mode',
    aliases: ['developer mode', 'code'],
    category: 'device',
  },
  {
    icon: 'devices',
    aliases: ['devices', 'laptop', 'phone', 'mobile'],
    category: 'device',
  },
  {
    icon: 'dvr',
    aliases: ['dvr', 'monitor', 'laptop'],
    category: 'device',
  },
  {
    icon: 'gps_fixed',
    aliases: ['gps fixed', 'location'],
    category: 'device',
  },
  {
    icon: 'gps_not_fixed',
    aliases: ['gps not fixed'],
    category: 'device',
  },
  {
    icon: 'gps_off',
    aliases: ['gps off'],
    category: 'device',
  },
  {
    icon: 'graphic_eq',
    aliases: ['graphic eq', 'equalizer', 'audio'],
    category: 'device',
  },
  {
    icon: 'location_disabled',
    aliases: ['location disabled'],
    category: 'device',
  },
  {
    icon: 'location_searching',
    aliases: ['location searching'],
    category: 'device',
  },
  {
    icon: 'network_cell',
    aliases: ['network cell'],
    category: 'device',
  },
  {
    icon: 'network_wifi',
    aliases: ['network wifi'],
    category: 'device',
  },
  {
    icon: 'nfc',
    aliases: ['nfc'],
    category: 'device',
  },
  {
    icon: 'screen_lock_landscape',
    aliases: ['screen lock landscape', 'mobile', 'phone', 'security'],
    category: 'device',
  },
  {
    icon: 'screen_lock_portrait',
    aliases: ['screen lock portrait', 'mobile', 'phone', 'security'],
    category: 'device',
  },
  {
    icon: 'screen_lock_rotation',
    aliases: ['screen lock rotation', 'mobile', 'phone'],
    category: 'device',
  },
  {
    icon: 'screen_rotation',
    aliases: ['screen rotation', 'mobile', 'phone'],
    category: 'device',
  },
  {
    icon: 'sd_storage',
    aliases: ['sd storage', 'microsd'],
    category: 'device',
  },
  {
    icon: 'settings_system_daydream',
    aliases: ['settings system daydream', 'cloud'],
    category: 'device',
  },
  {
    icon: 'storage',
    aliases: ['storage', 'network', 'server', 'database'],
    category: 'device',
  },
  {
    icon: 'usb',
    aliases: ['usb'],
    category: 'device',
  },
  {
    icon: 'wallpaper',
    aliases: ['wallpaper', 'image', 'picture'],
    category: 'device',
  },
  {
    icon: 'widgets',
    aliases: ['widgets', 'blocks'],
    category: 'device',
  },
  {
    icon: 'wifi_lock',
    aliases: ['wifi lock', 'network'],
    category: 'device',
  },
  {
    icon: 'wifi_tethering',
    aliases: ['wifi tethering', 'network'],
    category: 'device',
  },
  {
    icon: 'attach_file',
    aliases: ['attach file', 'paperclip'],
    category: 'editor',
  },
  {
    icon: 'attach_money',
    aliases: ['attach money', 'financial', 'dollar'],
    category: 'editor',
  },
  {
    icon: 'border_all',
    aliases: ['border all'],
    category: 'editor',
  },
  {
    icon: 'border_bottom',
    aliases: ['border bottom'],
    category: 'editor',
  },
  {
    icon: 'border_clear',
    aliases: ['border clear'],
    category: 'editor',
  },
  {
    icon: 'border_color',
    aliases: ['border color', 'pencil', 'marker', 'create'],
    category: 'editor',
  },
  {
    icon: 'border_horizontal',
    aliases: ['border horizontal'],
    category: 'editor',
  },
  {
    icon: 'border_inner',
    aliases: ['border inner'],
    category: 'editor',
  },
  {
    icon: 'border_left',
    aliases: ['border left'],
    category: 'editor',
  },
  {
    icon: 'border_outer',
    aliases: ['border outer'],
    category: 'editor',
  },
  {
    icon: 'border_right',
    aliases: ['border right'],
    category: 'editor',
  },
  {
    icon: 'border_style',
    aliases: ['border style'],
    category: 'editor',
  },
  {
    icon: 'border_top',
    aliases: ['border top'],
    category: 'editor',
  },
  {
    icon: 'border_vertical',
    aliases: ['border vertical'],
    category: 'editor',
  },
  {
    icon: 'bubble_chart',
    aliases: ['bubble chart', 'graph'],
    category: 'editor',
  },
  {
    icon: 'drag_handle',
    aliases: ['drag handle', 'lines'],
    category: 'editor',
  },
  {
    icon: 'format_align_center',
    aliases: ['format align center', 'lines'],
    category: 'editor',
  },
  {
    icon: 'format_align_justify',
    aliases: ['format align justify', 'lines'],
    category: 'editor',
  },
  {
    icon: 'format_align_left',
    aliases: ['format align left', 'lines'],
    category: 'editor',
  },
  {
    icon: 'format_align_right',
    aliases: ['format align right', 'lines'],
    category: 'editor',
  },
  {
    icon: 'format_bold',
    aliases: ['format bold'],
    category: 'editor',
  },
  {
    icon: 'format_clear',
    aliases: ['format clear'],
    category: 'editor',
  },
  {
    icon: 'format_color_fill',
    aliases: ['format color fill', 'bucket'],
    category: 'editor',
  },
  {
    icon: 'format_color_reset',
    aliases: ['format color reset', 'drop', 'water', 'liquid'],
    category: 'editor',
  },
  {
    icon: 'format_color_text',
    aliases: ['format color text'],
    category: 'editor',
  },
  {
    icon: 'format_indent_decrease',
    aliases: ['format indent decrease'],
    category: 'editor',
  },
  {
    icon: 'format_indent_increase',
    aliases: ['format indent increase'],
    category: 'editor',
  },
  {
    icon: 'format_italic',
    aliases: ['format italic'],
    category: 'editor',
  },
  {
    icon: 'format_line_spacing',
    aliases: ['format line spacing'],
    category: 'editor',
  },
  {
    icon: 'format_list_bulleted',
    aliases: ['format list bulleted'],
    category: 'editor',
  },
  {
    icon: 'format_list_numbered',
    aliases: ['format list numbered'],
    category: 'editor',
  },
  {
    icon: 'format_paint',
    aliases: ['format paint', 'paintroller'],
    category: 'editor',
  },
  {
    icon: 'format_quote',
    aliases: ['format quote', 'quotation'],
    category: 'editor',
  },
  {
    icon: 'format_shapes',
    aliases: ['format shapes'],
    category: 'editor',
  },
  {
    icon: 'format_size',
    aliases: ['format size'],
    category: 'editor',
  },
  {
    icon: 'format_strikethrough',
    aliases: ['format strikethrough'],
    category: 'editor',
  },
  {
    icon: 'format_textdirection_l_to_r',
    aliases: ['format textdirection l to r', 'paragraph'],
    category: 'editor',
  },
  {
    icon: 'format_textdirection_r_to_l',
    aliases: ['format textdirection r to l', 'paragraph'],
    category: 'editor',
  },
  {
    icon: 'format_underlined',
    aliases: ['format underlined'],
    category: 'editor',
  },
  {
    icon: 'functions',
    aliases: ['functions', 'sigma'],
    category: 'editor',
  },
  {
    icon: 'highlight',
    aliases: ['highlight', 'marker', 'flashlight'],
    category: 'editor',
  },
  {
    icon: 'insert_chart',
    aliases: ['insert chart', 'graph'],
    category: 'editor',
  },
  {
    icon: 'insert_comment',
    aliases: ['insert comment', 'chat', 'message'],
    category: 'editor',
  },
  {
    icon: 'insert_drive_file',
    aliases: ['insert drive file'],
    category: 'editor',
  },
  {
    icon: 'insert_emoticon',
    aliases: ['insert emoticon'],
    category: 'editor',
  },
  {
    icon: 'insert_invitation',
    aliases: ['insert invitation', 'agenda', 'calendar'],
    category: 'editor',
  },
  {
    icon: 'insert_link',
    aliases: ['insert link'],
    category: 'editor',
  },
  {
    icon: 'insert_photo',
    aliases: ['insert photo', 'image', 'wallpaper'],
    category: 'editor',
  },
  {
    icon: 'linear_scale',
    aliases: ['linear scale'],
    category: 'editor',
  },
  {
    icon: 'merge_type',
    aliases: ['merge type', 'arrow'],
    category: 'editor',
  },
  {
    icon: 'mode_comment',
    aliases: ['mode comment', 'message', 'chat'],
    category: 'editor',
  },
  {
    icon: 'mode_edit',
    aliases: ['mode edit', 'pencil', 'create', 'new'],
    category: 'editor',
  },
  {
    icon: 'monetization_on',
    aliases: ['monetization on', 'money', 'financial', 'dollar'],
    category: 'editor',
  },
  {
    icon: 'money_off',
    aliases: ['money off', 'money', 'financial', 'dollar'],
    category: 'editor',
  },
  {
    icon: 'multiline_chart',
    aliases: ['multiline chart', 'graph'],
    category: 'editor',
  },
  {
    icon: 'pie_chart',
    aliases: ['pie chart', 'graph'],
    category: 'editor',
  },
  {
    icon: 'pie_chart_outlined',
    aliases: ['pie chart outlined', 'graph'],
    category: 'editor',
  },
  {
    icon: 'publish',
    aliases: ['publish', 'upload', 'arrow'],
    category: 'editor',
  },
  {
    icon: 'short_text',
    aliases: ['short text', 'lines'],
    category: 'editor',
  },
  {
    icon: 'show_chart',
    aliases: ['show chart', 'graph'],
    category: 'editor',
  },
  {
    icon: 'space_bar',
    aliases: ['space bar'],
    category: 'editor',
  },
  {
    icon: 'strikethrough_s',
    aliases: ['strikethrough s'],
    category: 'editor',
  },
  {
    icon: 'text_fields',
    aliases: ['text fields'],
    category: 'editor',
  },
  {
    icon: 'title',
    aliases: ['title'],
    category: 'editor',
  },
  {
    icon: 'vertical_align_bottom',
    aliases: ['vertical align bottom', 'arrow'],
    category: 'editor',
  },
  {
    icon: 'vertical_align_center',
    aliases: ['vertical align center', 'arrow'],
    category: 'editor',
  },
  {
    icon: 'vertical_align_top',
    aliases: ['vertical align top', 'arrow'],
    category: 'editor',
  },
  {
    icon: 'wrap_text',
    aliases: ['wrap text', 'arrow'],
    category: 'editor',
  },
  {
    icon: 'attachment',
    aliases: ['attachment', 'paperclip'],
    category: 'file',
  },
  {
    icon: 'cloud',
    aliases: ['cloud'],
    category: 'file',
  },
  {
    icon: 'cloud_circle',
    aliases: ['cloud circle'],
    category: 'file',
  },
  {
    icon: 'cloud_done',
    aliases: ['cloud done', 'checkmark'],
    category: 'file',
  },
  {
    icon: 'cloud_download',
    aliases: ['cloud download'],
    category: 'file',
  },
  {
    icon: 'cloud_off',
    aliases: ['cloud off'],
    category: 'file',
  },
  {
    icon: 'cloud_queue',
    aliases: ['cloud queue'],
    category: 'file',
  },
  {
    icon: 'cloud_upload',
    aliases: ['cloud upload'],
    category: 'file',
  },
  {
    icon: 'create_new_folder',
    aliases: ['create new folder', 'directory'],
    category: 'file',
  },
  {
    icon: 'file_download',
    aliases: ['file download'],
    category: 'file',
  },
  {
    icon: 'file_upload',
    aliases: ['file upload'],
    category: 'file',
  },
  {
    icon: 'folder',
    aliases: ['folder', 'directory'],
    category: 'file',
  },
  {
    icon: 'folder_open',
    aliases: ['folder open', 'directory'],
    category: 'file',
  },
  {
    icon: 'folder_shared',
    aliases: ['folder shared', 'directory'],
    category: 'file',
  },
  {
    icon: 'cast',
    aliases: ['cast', 'chromecast'],
    category: 'hardware',
  },
  {
    icon: 'cast_connected',
    aliases: ['cast connected', 'chromecast'],
    category: 'hardware',
  },
  {
    icon: 'computer',
    aliases: ['computer', 'laptop', 'pc', 'monitor'],
    category: 'hardware',
  },
  {
    icon: 'desktop_mac',
    aliases: ['desktop mac', 'pc', 'apple', 'monitor'],
    category: 'hardware',
  },
  {
    icon: 'desktop_windows',
    aliases: ['desktop windows', 'pc', 'monitor'],
    category: 'hardware',
  },
  {
    icon: 'developer_board',
    aliases: ['developer board', 'devkit'],
    category: 'hardware',
  },
  {
    icon: 'device_hub',
    aliases: ['device hub'],
    category: 'hardware',
  },
  {
    icon: 'devices_other',
    aliases: ['devices other', 'smartwatch'],
    category: 'hardware',
  },
  {
    icon: 'dock',
    aliases: ['dock', 'charger'],
    category: 'hardware',
  },
  {
    icon: 'gamepad',
    aliases: ['gamepad'],
    category: 'hardware',
  },
  {
    icon: 'headset',
    aliases: ['headset', 'earmuffs', 'headphones'],
    category: 'hardware',
  },
  {
    icon: 'headset_mic',
    aliases: ['headset mic', 'headphones'],
    category: 'hardware',
  },
  {
    icon: 'keyboard',
    aliases: ['keyboard'],
    category: 'hardware',
  },
  {
    icon: 'keyboard_arrow_down',
    aliases: ['keyboard arrow down', 'chevron'],
    category: 'hardware',
  },
  {
    icon: 'keyboard_arrow_left',
    aliases: ['keyboard arrow left', 'chevron'],
    category: 'hardware',
  },
  {
    icon: 'keyboard_arrow_right',
    aliases: ['keyboard arrow right', 'chevron'],
    category: 'hardware',
  },
  {
    icon: 'keyboard_arrow_up',
    aliases: ['keyboard arrow up', 'chevron'],
    category: 'hardware',
  },
  {
    icon: 'keyboard_backspace',
    aliases: ['keyboard backspace'],
    category: 'hardware',
  },
  {
    icon: 'keyboard_capslock',
    aliases: ['keyboard capslock', 'arrow'],
    category: 'hardware',
  },
  {
    icon: 'keyboard_hide',
    aliases: ['keyboard hide'],
    category: 'hardware',
  },
  {
    icon: 'keyboard_return',
    aliases: ['keyboard return', 'arrow'],
    category: 'hardware',
  },
  {
    icon: 'keyboard_tab',
    aliases: ['keyboard tab', 'arrow'],
    category: 'hardware',
  },
  {
    icon: 'keyboard_voice',
    aliases: ['keyboard voice'],
    category: 'hardware',
  },
  {
    icon: 'laptop',
    aliases: ['laptop'],
    category: 'hardware',
  },
  {
    icon: 'laptop_chromebook',
    aliases: ['laptop chromebook'],
    category: 'hardware',
  },
  {
    icon: 'laptop_mac',
    aliases: ['laptop mac', 'apple'],
    category: 'hardware',
  },
  {
    icon: 'laptop_windows',
    aliases: ['laptop windows'],
    category: 'hardware',
  },
  {
    icon: 'memory',
    aliases: ['memory', 'chip'],
    category: 'hardware',
  },
  {
    icon: 'mouse',
    aliases: ['mouse'],
    category: 'hardware',
  },
  {
    icon: 'phone_android',
    aliases: ['phone android', 'mobile'],
    category: 'hardware',
  },
  {
    icon: 'phone_iphone',
    aliases: ['phone iphone', 'mobile', 'apple'],
    category: 'hardware',
  },
  {
    icon: 'phonelink',
    aliases: ['phonelink'],
    category: 'hardware',
  },
  {
    icon: 'phonelink_off',
    aliases: ['phonelink off'],
    category: 'hardware',
  },
  {
    icon: 'power_input',
    aliases: ['power input', 'dc'],
    category: 'hardware',
  },
  {
    icon: 'router',
    aliases: ['router', 'device', 'network'],
    category: 'hardware',
  },
  {
    icon: 'scanner',
    aliases: ['scanner', 'device'],
    category: 'hardware',
  },
  {
    icon: 'security',
    aliases: ['security', 'shield'],
    category: 'hardware',
  },
  {
    icon: 'sim_card',
    aliases: ['sim card'],
    category: 'hardware',
  },
  {
    icon: 'smartphone',
    aliases: ['smartphone', 'mobile'],
    category: 'hardware',
  },
  {
    icon: 'speaker',
    aliases: ['speaker', 'audio'],
    category: 'hardware',
  },
  {
    icon: 'speaker_group',
    aliases: ['speaker group', 'audio'],
    category: 'hardware',
  },
  {
    icon: 'tablet',
    aliases: ['tablet'],
    category: 'hardware',
  },
  {
    icon: 'tablet_android',
    aliases: ['tablet android'],
    category: 'hardware',
  },
  {
    icon: 'tablet_mac',
    aliases: ['tablet mac', 'apple'],
    category: 'hardware',
  },
  {
    icon: 'toys',
    aliases: ['toys', 'fan'],
    category: 'hardware',
  },
  {
    icon: 'tv',
    aliases: ['tv', 'television'],
    category: 'hardware',
  },
  {
    icon: 'videogame_asset',
    aliases: ['videogame asset', 'gamepad', 'controller', 'playstation', 'xbox', 'nintendo'],
    category: 'hardware',
  },
  {
    icon: 'watch',
    aliases: ['watch', 'smartwatch'],
    category: 'hardware',
  },
  {
    icon: 'add_a_photo',
    aliases: ['add a photo', 'camera'],
    category: 'image',
  },
  {
    icon: 'add_to_photos',
    aliases: ['add to photos', 'plus'],
    category: 'image',
  },
  {
    icon: 'adjust',
    aliases: ['adjust', 'circle'],
    category: 'image',
  },
  {
    icon: 'assistant',
    aliases: ['assistant', 'star', 'comment', 'chat'],
    category: 'image',
  },
  {
    icon: 'assistant_photo',
    aliases: ['assistant photo'],
    category: 'image',
  },
  {
    icon: 'audiotrack',
    aliases: ['audiotrack', 'note'],
    category: 'image',
  },
  {
    icon: 'blur_circular',
    aliases: ['blur circular', 'circle'],
    category: 'image',
  },
  {
    icon: 'blur_linear',
    aliases: ['blur linear'],
    category: 'image',
  },
  {
    icon: 'blur_off',
    aliases: ['blur off'],
    category: 'image',
  },
  {
    icon: 'blur_on',
    aliases: ['blur on'],
    category: 'image',
  },
  {
    icon: 'brightness_1',
    aliases: ['brightness 1'],
    category: 'image',
  },
  {
    icon: 'brightness_2',
    aliases: ['brightness 2', 'moon'],
    category: 'image',
  },
  {
    icon: 'brightness_3',
    aliases: ['brightness 3', 'moon'],
    category: 'image',
  },
  {
    icon: 'brightness_4',
    aliases: ['brightness 4', 'moon', 'sun'],
    category: 'image',
  },
  {
    icon: 'brightness_5',
    aliases: ['brightness 5', 'sun'],
    category: 'image',
  },
  {
    icon: 'brightness_6',
    aliases: ['brightness 6', 'sun'],
    category: 'image',
  },
  {
    icon: 'brightness_7',
    aliases: ['brightness 7', 'sun'],
    category: 'image',
  },
  {
    icon: 'broken_image',
    aliases: ['broken image', 'picture'],
    category: 'image',
  },
  {
    icon: 'brush',
    aliases: ['brush', 'paint'],
    category: 'image',
  },
  {
    icon: 'burst_mode',
    aliases: ['burst mode', 'image', 'picture'],
    category: 'image',
  },
  {
    icon: 'camera',
    aliases: ['camera'],
    category: 'image',
  },
  {
    icon: 'camera_alt',
    aliases: ['camera alt'],
    category: 'image',
  },
  {
    icon: 'camera_front',
    aliases: ['camera front'],
    category: 'image',
  },
  {
    icon: 'camera_rear',
    aliases: ['camera rear'],
    category: 'image',
  },
  {
    icon: 'camera_roll',
    aliases: ['camera roll'],
    category: 'image',
  },
  {
    icon: 'center_focus_strong',
    aliases: ['center focus strong'],
    category: 'image',
  },
  {
    icon: 'center_focus_weak',
    aliases: ['center focus weak'],
    category: 'image',
  },
  {
    icon: 'collections',
    aliases: ['collections'],
    category: 'image',
  },
  {
    icon: 'collections_bookmark',
    aliases: ['collections bookmark'],
    category: 'image',
  },
  {
    icon: 'color_lens',
    aliases: ['color lens'],
    category: 'image',
  },
  {
    icon: 'colorize',
    aliases: ['colorize', 'color picker', 'pipette'],
    category: 'image',
  },
  {
    icon: 'compare',
    aliases: ['compare'],
    category: 'image',
  },
  {
    icon: 'control_point',
    aliases: ['control point', 'circle', 'plus'],
    category: 'image',
  },
  {
    icon: 'control_point_duplicate',
    aliases: ['control point duplicate', 'circle', 'plus'],
    category: 'image',
  },
  {
    icon: 'crop',
    aliases: ['crop'],
    category: 'image',
  },
  {
    icon: 'crop_16_9',
    aliases: ['crop 16 9', 'square'],
    category: 'image',
  },
  {
    icon: 'crop_3_2',
    aliases: ['crop 3 2', 'square'],
    category: 'image',
  },
  {
    icon: 'crop_5_4',
    aliases: ['crop 5 4', 'square'],
    category: 'image',
  },
  {
    icon: 'crop_7_5',
    aliases: ['crop 7 5', 'square'],
    category: 'image',
  },
  {
    icon: 'crop_din',
    aliases: ['crop din', 'square'],
    category: 'image',
  },
  {
    icon: 'crop_free',
    aliases: ['crop free', 'square'],
    category: 'image',
  },
  {
    icon: 'crop_landscape',
    aliases: ['crop landscape', 'square'],
    category: 'image',
  },
  {
    icon: 'crop_original',
    aliases: ['crop original', 'square'],
    category: 'image',
  },
  {
    icon: 'crop_portrait',
    aliases: ['crop portrait', 'square'],
    category: 'image',
  },
  {
    icon: 'crop_rotate',
    aliases: ['crop rotate'],
    category: 'image',
  },
  {
    icon: 'crop_square',
    aliases: ['crop square'],
    category: 'image',
  },
  {
    icon: 'dehaze',
    aliases: ['dehaze'],
    category: 'image',
  },
  {
    icon: 'details',
    aliases: ['details', 'triangle'],
    category: 'image',
  },
  {
    icon: 'edit',
    aliases: ['edit'],
    category: 'image',
  },
  {
    icon: 'exposure',
    aliases: ['exposure', 'plus', 'minus'],
    category: 'image',
  },
  {
    icon: 'exposure_neg_1',
    aliases: ['exposure neg 1', 'minus'],
    category: 'image',
  },
  {
    icon: 'exposure_neg_2',
    aliases: ['exposure neg 2', 'minus'],
    category: 'image',
  },
  {
    icon: 'exposure_plus_1',
    aliases: ['exposure plus 1', 'plus'],
    category: 'image',
  },
  {
    icon: 'exposure_plus_2',
    aliases: ['exposure plus 2', 'plus'],
    category: 'image',
  },
  {
    icon: 'exposure_zero',
    aliases: ['exposure zero'],
    category: 'image',
  },
  {
    icon: 'filter',
    aliases: ['filter'],
    category: 'image',
  },
  {
    icon: 'filter_1',
    aliases: ['filter 1'],
    category: 'image',
  },
  {
    icon: 'filter_2',
    aliases: ['filter 2'],
    category: 'image',
  },
  {
    icon: 'filter_3',
    aliases: ['filter 3'],
    category: 'image',
  },
  {
    icon: 'filter_4',
    aliases: ['filter 4'],
    category: 'image',
  },
  {
    icon: 'filter_5',
    aliases: ['filter 5'],
    category: 'image',
  },
  {
    icon: 'filter_6',
    aliases: ['filter 6'],
    category: 'image',
  },
  {
    icon: 'filter_7',
    aliases: ['filter 7'],
    category: 'image',
  },
  {
    icon: 'filter_8',
    aliases: ['filter 8'],
    category: 'image',
  },
  {
    icon: 'filter_9',
    aliases: ['filter 9'],
    category: 'image',
  },
  {
    icon: 'filter_9_plus',
    aliases: ['filter 9 plus'],
    category: 'image',
  },
  {
    icon: 'filter_b_and_w',
    aliases: ['filter b and w'],
    category: 'image',
  },
  {
    icon: 'filter_center_focus',
    aliases: ['filter center focus'],
    category: 'image',
  },
  {
    icon: 'filter_drama',
    aliases: ['filter drama', 'cloud'],
    category: 'image',
  },
  {
    icon: 'filter_frames',
    aliases: ['filter frames'],
    category: 'image',
  },
  {
    icon: 'filter_hdr',
    aliases: ['filter hdr', 'mountains'],
    category: 'image',
  },
  {
    icon: 'filter_none',
    aliases: ['filter none'],
    category: 'image',
  },
  {
    icon: 'filter_tilt_shift',
    aliases: ['filter tilt shift'],
    category: 'image',
  },
  {
    icon: 'filter_vintage',
    aliases: ['filter vintage', 'flower'],
    category: 'image',
  },
  {
    icon: 'flare',
    aliases: ['flare', 'lensflare', 'sun', 'star', 'light', 'bright', 'shine', 'sparkle'],
    category: 'image',
  },
  {
    icon: 'flash_auto',
    aliases: ['flash auto', 'lightning'],
    category: 'image',
  },
  {
    icon: 'flash_off',
    aliases: ['flash off', 'lightning'],
    category: 'image',
  },
  {
    icon: 'flash_on',
    aliases: ['flash on', 'lightning'],
    category: 'image',
  },
  {
    icon: 'flip',
    aliases: ['flip'],
    category: 'image',
  },
  {
    icon: 'gradient',
    aliases: ['gradient'],
    category: 'image',
  },
  {
    icon: 'grain',
    aliases: ['grain'],
    category: 'image',
  },
  {
    icon: 'grid_off',
    aliases: ['grid off'],
    category: 'image',
  },
  {
    icon: 'grid_on',
    aliases: ['grid on'],
    category: 'image',
  },
  {
    icon: 'hdr_off',
    aliases: ['hdr off'],
    category: 'image',
  },
  {
    icon: 'hdr_on',
    aliases: ['hdr on'],
    category: 'image',
  },
  {
    icon: 'hdr_strong',
    aliases: ['hdr strong'],
    category: 'image',
  },
  {
    icon: 'hdr_weak',
    aliases: ['hdr weak'],
    category: 'image',
  },
  {
    icon: 'healing',
    aliases: ['healing', 'bandaid'],
    category: 'image',
  },
  {
    icon: 'image',
    aliases: ['image', 'picture'],
    category: 'image',
  },
  {
    icon: 'image_aspect_ratio',
    aliases: ['image aspect ratio'],
    category: 'image',
  },
  {
    icon: 'iso',
    aliases: ['iso'],
    category: 'image',
  },
  {
    icon: 'landscape',
    aliases: ['landscape'],
    category: 'image',
  },
  {
    icon: 'leak_add',
    aliases: ['leak add'],
    category: 'image',
  },
  {
    icon: 'leak_remove',
    aliases: ['leak remove'],
    category: 'image',
  },
  {
    icon: 'lens',
    aliases: ['lens'],
    category: 'image',
  },
  {
    icon: 'linked_camera',
    aliases: ['linked camera'],
    category: 'image',
  },
  {
    icon: 'looks',
    aliases: ['looks', 'rainbow'],
    category: 'image',
  },
  {
    icon: 'looks_3',
    aliases: ['looks 3'],
    category: 'image',
  },
  {
    icon: 'looks_4',
    aliases: ['looks 4'],
    category: 'image',
  },
  {
    icon: 'looks_5',
    aliases: ['looks 5'],
    category: 'image',
  },
  {
    icon: 'looks_6',
    aliases: ['looks 6'],
    category: 'image',
  },
  {
    icon: 'looks_one',
    aliases: ['looks one'],
    category: 'image',
  },
  {
    icon: 'looks_two',
    aliases: ['looks two'],
    category: 'image',
  },
  {
    icon: 'loupe',
    aliases: ['loupe'],
    category: 'image',
  },
  {
    icon: 'monochrome_photos',
    aliases: ['monochrome photos'],
    category: 'image',
  },
  {
    icon: 'movie_creation',
    aliases: ['movie creation', 'video', 'film'],
    category: 'image',
  },
  {
    icon: 'movie_filter',
    aliases: ['movie filter', 'video', 'film'],
    category: 'image',
  },
  {
    icon: 'music_note',
    aliases: ['music note'],
    category: 'image',
  },
  {
    icon: 'nature',
    aliases: ['nature', 'tree', 'forest'],
    category: 'image',
  },
  {
    icon: 'nature_people',
    aliases: ['nature people', 'tree', 'forest', 'person'],
    category: 'image',
  },
  {
    icon: 'navigate_before',
    aliases: ['navigate before', 'arrow'],
    category: 'image',
  },
  {
    icon: 'navigate_next',
    aliases: ['navigate next', 'arrow'],
    category: 'image',
  },
  {
    icon: 'palette',
    aliases: ['palette'],
    category: 'image',
  },
  {
    icon: 'panorama',
    aliases: ['panorama', 'picture', 'image'],
    category: 'image',
  },
  {
    icon: 'panorama_fish_eye',
    aliases: ['panorama fish eye'],
    category: 'image',
  },
  {
    icon: 'panorama_horizontal',
    aliases: ['panorama horizontal'],
    category: 'image',
  },
  {
    icon: 'panorama_vertical',
    aliases: ['panorama vertical'],
    category: 'image',
  },
  {
    icon: 'panorama_wide_angle',
    aliases: ['panorama wide angle'],
    category: 'image',
  },
  {
    icon: 'photo',
    aliases: ['photo'],
    category: 'image',
  },
  {
    icon: 'photo_album',
    aliases: ['photo album', 'picture', 'image'],
    category: 'image',
  },
  {
    icon: 'photo_camera',
    aliases: ['photo camera', 'picture', 'image'],
    category: 'image',
  },
  {
    icon: 'photo_filter',
    aliases: ['photo filter'],
    category: 'image',
  },
  {
    icon: 'photo_library',
    aliases: ['photo library', 'picture', 'image'],
    category: 'image',
  },
  {
    icon: 'photo_size_select_actual',
    aliases: ['photo size select actual'],
    category: 'image',
  },
  {
    icon: 'photo_size_select_large',
    aliases: ['photo size select large'],
    category: 'image',
  },
  {
    icon: 'photo_size_select_small',
    aliases: ['photo size select small'],
    category: 'image',
  },
  {
    icon: 'picture_as_pdf',
    aliases: ['picture as pdf'],
    category: 'image',
  },
  {
    icon: 'portrait',
    aliases: ['portrait'],
    category: 'image',
  },
  {
    icon: 'remove_red_eye',
    aliases: ['remove red eye'],
    category: 'image',
  },
  {
    icon: 'rotate_90_degrees_ccw',
    aliases: ['rotate 90 degrees ccw', 'arrow'],
    category: 'image',
  },
  {
    icon: 'rotate_left',
    aliases: ['rotate left', 'arrow', 'circle'],
    category: 'image',
  },
  {
    icon: 'rotate_right',
    aliases: ['rotate right', 'arrow', 'circle'],
    category: 'image',
  },
  {
    icon: 'slideshow',
    aliases: ['slideshow'],
    category: 'image',
  },
  {
    icon: 'straighten',
    aliases: ['straighten', 'piano'],
    category: 'image',
  },
  {
    icon: 'style',
    aliases: ['style'],
    category: 'image',
  },
  {
    icon: 'switch_camera',
    aliases: ['switch camera'],
    category: 'image',
  },
  {
    icon: 'switch_video',
    aliases: ['switch video'],
    category: 'image',
  },
  {
    icon: 'tag_faces',
    aliases: ['tag faces'],
    category: 'image',
  },
  {
    icon: 'texture',
    aliases: ['texture'],
    category: 'image',
  },
  {
    icon: 'timelapse',
    aliases: ['timelapse'],
    category: 'image',
  },
  {
    icon: 'timer',
    aliases: ['timer', 'stopwatch'],
    category: 'image',
  },
  {
    icon: 'timer_10',
    aliases: ['timer 10'],
    category: 'image',
  },
  {
    icon: 'timer_3',
    aliases: ['timer 3'],
    category: 'image',
  },
  {
    icon: 'timer_off',
    aliases: ['timer off', 'stopwatch'],
    category: 'image',
  },
  {
    icon: 'tonality',
    aliases: ['tonality'],
    category: 'image',
  },
  {
    icon: 'transform',
    aliases: ['transform'],
    category: 'image',
  },
  {
    icon: 'tune',
    aliases: ['tune', 'settings', 'sliders'],
    category: 'image',
  },
  {
    icon: 'view_comfy',
    aliases: ['view comfy', 'grid'],
    category: 'image',
  },
  {
    icon: 'view_compact',
    aliases: ['view compact', 'grid'],
    category: 'image',
  },
  {
    icon: 'vignette',
    aliases: ['vignette'],
    category: 'image',
  },
  {
    icon: 'wb_auto',
    aliases: ['wb auto'],
    category: 'image',
  },
  {
    icon: 'wb_cloudy',
    aliases: ['wb cloudy'],
    category: 'image',
  },
  {
    icon: 'wb_incandescent',
    aliases: ['wb incandescent', 'lightbulb'],
    category: 'image',
  },
  {
    icon: 'wb_iridescent',
    aliases: ['wb iridescent'],
    category: 'image',
  },
  {
    icon: 'wb_sunny',
    aliases: ['wb sunny'],
    category: 'image',
  },
  {
    icon: 'add_location',
    aliases: ['add location', 'gps'],
    category: 'maps',
  },
  {
    icon: 'beenhere',
    aliases: ['beenhere', 'checkmark'],
    category: 'maps',
  },
  {
    icon: 'directions',
    aliases: ['directions', 'naviate', 'arrow'],
    category: 'maps',
  },
  {
    icon: 'directions_bike',
    aliases: ['directions bike', 'bicycle'],
    category: 'maps',
  },
  {
    icon: 'directions_boat',
    aliases: ['directions boat'],
    category: 'maps',
  },
  {
    icon: 'directions_bus',
    aliases: ['directions bus'],
    category: 'maps',
  },
  {
    icon: 'directions_car',
    aliases: ['directions car'],
    category: 'maps',
  },
  {
    icon: 'directions_railway',
    aliases: ['directions railway'],
    category: 'maps',
  },
  {
    icon: 'directions_run',
    aliases: ['directions run', 'person'],
    category: 'maps',
  },
  {
    icon: 'directions_subway',
    aliases: ['directions subway'],
    category: 'maps',
  },
  {
    icon: 'directions_transit',
    aliases: ['directions transit', 'subway', 'metro'],
    category: 'maps',
  },
  {
    icon: 'directions_walk',
    aliases: ['directions walk', 'person'],
    category: 'maps',
  },
  {
    icon: 'edit_location',
    aliases: ['edit location', 'gps'],
    category: 'maps',
  },
  {
    icon: 'ev_station',
    aliases: ['ev station', 'gas station', 'gasoline', 'fuel', 'filling station'],
    category: 'maps',
  },
  {
    icon: 'flight',
    aliases: ['flight', 'flying', 'airplane'],
    category: 'maps',
  },
  {
    icon: 'hotel',
    aliases: ['hotel', 'bed', 'sleep'],
    category: 'maps',
  },
  {
    icon: 'layers',
    aliases: ['layers'],
    category: 'maps',
  },
  {
    icon: 'layers_clear',
    aliases: ['layers clear'],
    category: 'maps',
  },
  {
    icon: 'local_activity',
    aliases: ['local activity', 'star'],
    category: 'maps',
  },
  {
    icon: 'local_airport',
    aliases: ['local airport', 'flying', 'airplane'],
    category: 'maps',
  },
  {
    icon: 'local_atm',
    aliases: ['local atm', 'financial', 'dollar', 'money'],
    category: 'maps',
  },
  {
    icon: 'local_bar',
    aliases: ['local bar', 'alcohol', 'drink', 'martini'],
    category: 'maps',
  },
  {
    icon: 'local_cafe',
    aliases: ['local cafe', 'drink', 'coffee'],
    category: 'maps',
  },
  {
    icon: 'local_car_wash',
    aliases: ['local car wash'],
    category: 'maps',
  },
  {
    icon: 'local_convenience_store',
    aliases: ['local convenience store', 'building'],
    category: 'maps',
  },
  {
    icon: 'local_dining',
    aliases: ['local dining', 'cutlery', 'knife', 'spoon'],
    category: 'maps',
  },
  {
    icon: 'local_drink',
    aliases: ['local drink', 'glass', 'water'],
    category: 'maps',
  },
  {
    icon: 'local_florist',
    aliases: ['local florist', 'flower'],
    category: 'maps',
  },
  {
    icon: 'local_gas_station',
    aliases: ['local gas station', 'gasoline', 'fuel', 'filling station'],
    category: 'maps',
  },
  {
    icon: 'local_grocery_store',
    aliases: ['local grocery store'],
    category: 'maps',
  },
  {
    icon: 'local_hospital',
    aliases: ['local hospital', 'plus'],
    category: 'maps',
  },
  {
    icon: 'local_hotel',
    aliases: ['local hotel'],
    category: 'maps',
  },
  {
    icon: 'local_laundry_service',
    aliases: ['local laundry service'],
    category: 'maps',
  },
  {
    icon: 'local_library',
    aliases: ['local library', 'book', 'person'],
    category: 'maps',
  },
  {
    icon: 'local_mall',
    aliases: ['local mall', 'shopping bag'],
    category: 'maps',
  },
  {
    icon: 'local_movies',
    aliases: ['local movies'],
    category: 'maps',
  },
  {
    icon: 'local_offer',
    aliases: ['local offer'],
    category: 'maps',
  },
  {
    icon: 'local_parking',
    aliases: ['local parking'],
    category: 'maps',
  },
  {
    icon: 'local_pharmacy',
    aliases: ['local pharmacy', 'food'],
    category: 'maps',
  },
  {
    icon: 'local_phone',
    aliases: ['local phone'],
    category: 'maps',
  },
  {
    icon: 'local_pizza',
    aliases: ['local pizza'],
    category: 'maps',
  },
  {
    icon: 'local_play',
    aliases: ['local play'],
    category: 'maps',
  },
  {
    icon: 'local_post_office',
    aliases: ['local post office'],
    category: 'maps',
  },
  {
    icon: 'local_printshop',
    aliases: ['local printshop'],
    category: 'maps',
  },
  {
    icon: 'local_see',
    aliases: ['local see'],
    category: 'maps',
  },
  {
    icon: 'local_shipping',
    aliases: ['local shipping', 'car', 'truck', 'semi'],
    category: 'maps',
  },
  {
    icon: 'local_taxi',
    aliases: ['local taxi', 'car'],
    category: 'maps',
  },
  {
    icon: 'map',
    aliases: ['map'],
    category: 'maps',
  },
  {
    icon: 'my_location',
    aliases: ['my location'],
    category: 'maps',
  },
  {
    icon: 'navigation',
    aliases: ['navigation', 'arrow'],
    category: 'maps',
  },
  {
    icon: 'near_me',
    aliases: ['near me', 'arrow'],
    category: 'maps',
  },
  {
    icon: 'person_pin',
    aliases: ['person pin', 'location', 'gps'],
    category: 'maps',
  },
  {
    icon: 'person_pin_circle',
    aliases: ['person pin circle', 'location', 'gps'],
    category: 'maps',
  },
  {
    icon: 'pin_drop',
    aliases: ['pin drop', 'location', 'gps'],
    category: 'maps',
  },
  {
    icon: 'place',
    aliases: ['place'],
    category: 'maps',
  },
  {
    icon: 'rate_review',
    aliases: ['rate review', 'comment', 'message', 'chat'],
    category: 'maps',
  },
  {
    icon: 'restaurant',
    aliases: ['restaurant', 'cutlery', 'knife', 'fork'],
    category: 'maps',
  },
  {
    icon: 'restaurant_menu',
    aliases: ['restaurant menu'],
    category: 'maps',
  },
  {
    icon: 'satellite',
    aliases: ['satellite'],
    category: 'maps',
  },
  {
    icon: 'store_mall_directory',
    aliases: ['store mall directory', 'building'],
    category: 'maps',
  },
  {
    icon: 'streetview',
    aliases: ['streetview', 'map', 'gps', 'location'],
    category: 'maps',
  },
  {
    icon: 'subway',
    aliases: ['subway', 'metro'],
    category: 'maps',
  },
  {
    icon: 'terrain',
    aliases: ['terrain'],
    category: 'maps',
  },
  {
    icon: 'traffic',
    aliases: ['traffic', 'light'],
    category: 'maps',
  },
  {
    icon: 'train',
    aliases: ['train'],
    category: 'maps',
  },
  {
    icon: 'tram',
    aliases: ['tram'],
    category: 'maps',
  },
  {
    icon: 'transfer_within_a_station',
    aliases: ['transfer within a station', 'person'],
    category: 'maps',
  },
  {
    icon: 'zoom_out_map',
    aliases: ['zoom out map', 'arrows'],
    category: 'maps',
  },
  {
    icon: 'apps',
    aliases: ['apps', 'grid'],
    category: 'navigation',
  },
  {
    icon: 'arrow_back',
    aliases: ['arrow back'],
    category: 'navigation',
  },
  {
    icon: 'arrow_downward',
    aliases: ['arrow downward'],
    category: 'navigation',
  },
  {
    icon: 'arrow_drop_down',
    aliases: ['arrow drop down'],
    category: 'navigation',
  },
  {
    icon: 'arrow_drop_down_circle',
    aliases: ['arrow drop down circle'],
    category: 'navigation',
  },
  {
    icon: 'arrow_drop_up',
    aliases: ['arrow drop up'],
    category: 'navigation',
  },
  {
    icon: 'arrow_forward',
    aliases: ['arrow forward'],
    category: 'navigation',
  },
  {
    icon: 'arrow_upward',
    aliases: ['arrow upward'],
    category: 'navigation',
  },
  {
    icon: 'cancel',
    aliases: ['cancel', 'disable', 'cross'],
    category: 'navigation',
  },
  {
    icon: 'check',
    aliases: ['check', 'checkmark'],
    category: 'navigation',
  },
  {
    icon: 'chevron_left',
    aliases: ['chevron left', 'arrow'],
    category: 'navigation',
  },
  {
    icon: 'chevron_right',
    aliases: ['chevron right', 'arrow'],
    category: 'navigation',
  },
  {
    icon: 'close',
    aliases: ['close', 'cancel', 'disable', 'not allowed'],
    category: 'navigation',
  },
  {
    icon: 'expand_less',
    aliases: ['expand less', 'arrow'],
    category: 'navigation',
  },
  {
    icon: 'expand_more',
    aliases: ['expand more', 'arrow'],
    category: 'navigation',
  },
  {
    icon: 'first_page',
    aliases: ['first page', 'arrow'],
    category: 'navigation',
  },
  {
    icon: 'fullscreen',
    aliases: ['fullscreen'],
    category: 'navigation',
  },
  {
    icon: 'fullscreen_exit',
    aliases: ['fullscreen exit'],
    category: 'navigation',
  },
  {
    icon: 'last_page',
    aliases: ['last page', 'arrow'],
    category: 'navigation',
  },
  {
    icon: 'menu',
    aliases: ['menu'],
    category: 'navigation',
  },
  {
    icon: 'more_horiz',
    aliases: ['more horiz', 'dots'],
    category: 'navigation',
  },
  {
    icon: 'more_vert',
    aliases: ['more vert', 'dots'],
    category: 'navigation',
  },
  {
    icon: 'refresh',
    aliases: ['refresh'],
    category: 'navigation',
  },
  {
    icon: 'subdirectory_arrow_left',
    aliases: ['subdirectory arrow left', 'arrow'],
    category: 'navigation',
  },
  {
    icon: 'subdirectory_arrow_right',
    aliases: ['subdirectory arrow right', 'arrow'],
    category: 'navigation',
  },
  {
    icon: 'unfold_less',
    aliases: ['unfold less', 'arrow'],
    category: 'navigation',
  },
  {
    icon: 'unfold_more',
    aliases: ['unfold more', 'arrow'],
    category: 'navigation',
  },
  {
    icon: 'adb',
    aliases: ['adb', 'android'],
    category: 'notification',
  },
  {
    icon: 'airline_seat_flat',
    aliases: ['airline seat flat', 'bed', 'sleep'],
    category: 'notification',
  },
  {
    icon: 'airline_seat_flat_angled',
    aliases: ['airline seat flat angled', 'bed', 'sleep'],
    category: 'notification',
  },
  {
    icon: 'airline_seat_individual_suite',
    aliases: ['airline seat individual suite', 'bed', 'sleep'],
    category: 'notification',
  },
  {
    icon: 'airline_seat_legroom_extra',
    aliases: ['airline seat legroom extra'],
    category: 'notification',
  },
  {
    icon: 'airline_seat_legroom_normal',
    aliases: ['airline seat legroom normal'],
    category: 'notification',
  },
  {
    icon: 'airline_seat_legroom_reduced',
    aliases: ['airline seat legroom reduced'],
    category: 'notification',
  },
  {
    icon: 'airline_seat_recline_extra',
    aliases: ['airline seat recline extra'],
    category: 'notification',
  },
  {
    icon: 'airline_seat_recline_normal',
    aliases: ['airline seat recline normal'],
    category: 'notification',
  },
  {
    icon: 'bluetooth_audio',
    aliases: ['bluetooth audio'],
    category: 'notification',
  },
  {
    icon: 'confirmation_number',
    aliases: ['confirmation number', 'ticket'],
    category: 'notification',
  },
  {
    icon: 'disc_full',
    aliases: ['disc full', 'cd', 'vinyl', 'exclamation'],
    category: 'notification',
  },
  {
    icon: 'do_not_disturb',
    aliases: ['do not disturb', 'banned'],
    category: 'notification',
  },
  {
    icon: 'do_not_disturb_alt',
    aliases: ['do not disturb alt', 'banned'],
    category: 'notification',
  },
  {
    icon: 'do_not_disturb_off',
    aliases: ['do not disturb off', 'banned'],
    category: 'notification',
  },
  {
    icon: 'do_not_disturb_on',
    aliases: ['do not disturb on', 'banned'],
    category: 'notification',
  },
  {
    icon: 'drive_eta',
    aliases: ['drive eta'],
    category: 'notification',
  },
  {
    icon: 'enhanced_encryption',
    aliases: ['enhanced encryption', 'security', 'lock'],
    category: 'notification',
  },
  {
    icon: 'event_available',
    aliases: ['event available', 'agenda', 'calendar'],
    category: 'notification',
  },
  {
    icon: 'event_busy',
    aliases: ['event busy', 'agenda', 'calendar'],
    category: 'notification',
  },
  {
    icon: 'event_note',
    aliases: ['event note', 'agenda', 'calendar'],
    category: 'notification',
  },
  {
    icon: 'folder_special',
    aliases: ['folder special', 'directory'],
    category: 'notification',
  },
  {
    icon: 'live_tv',
    aliases: ['live tv', 'monitor', 'television'],
    category: 'notification',
  },
  {
    icon: 'mms',
    aliases: ['mms', 'chat', 'message', 'comment'],
    category: 'notification',
  },
  {
    icon: 'more',
    aliases: ['more', 'tag', 'badge'],
    category: 'notification',
  },
  {
    icon: 'network_check',
    aliases: ['network check', 'wifi'],
    category: 'notification',
  },
  {
    icon: 'network_locked',
    aliases: ['network locked', 'wifi'],
    category: 'notification',
  },
  {
    icon: 'no_encryption',
    aliases: ['no encryption', 'lock', 'security'],
    category: 'notification',
  },
  {
    icon: 'ondemand_video',
    aliases: ['ondemand video', 'monitor', 'television'],
    category: 'notification',
  },
  {
    icon: 'personal_video',
    aliases: ['personal video', 'monitor', 'television'],
    category: 'notification',
  },
  {
    icon: 'phone_bluetooth_speaker',
    aliases: ['phone bluetooth speaker'],
    category: 'notification',
  },
  {
    icon: 'phone_forwarded',
    aliases: ['phone forwarded'],
    category: 'notification',
  },
  {
    icon: 'phone_in_talk',
    aliases: ['phone in talk'],
    category: 'notification',
  },
  {
    icon: 'phone_locked',
    aliases: ['phone locked', 'security'],
    category: 'notification',
  },
  {
    icon: 'phone_missed',
    aliases: ['phone missed'],
    category: 'notification',
  },
  {
    icon: 'phone_paused',
    aliases: ['phone paused'],
    category: 'notification',
  },
  {
    icon: 'power',
    aliases: ['power', 'socket', 'plug'],
    category: 'notification',
  },
  {
    icon: 'priority_high',
    aliases: ['priority high', 'exclamation'],
    category: 'notification',
  },
  {
    icon: 'rv_hookup',
    aliases: ['rv hookup'],
    category: 'places',
  },
  {
    icon: 'sd_card',
    aliases: ['sd card'],
    category: 'notification',
  },
  {
    icon: 'sim_card_alert',
    aliases: ['sim card alert', 'exclamation'],
    category: 'notification',
  },
  {
    icon: 'sms',
    aliases: ['sms', 'chat', 'comment', 'message'],
    category: 'notification',
  },
  {
    icon: 'sms_failed',
    aliases: ['sms failed', 'chat', 'comment', 'message'],
    category: 'notification',
  },
  {
    icon: 'sync',
    aliases: ['sync'],
    category: 'notification',
  },
  {
    icon: 'sync_disabled',
    aliases: ['sync disabled', 'refresh', 'arrows'],
    category: 'notification',
  },
  {
    icon: 'sync_problem',
    aliases: ['sync problem', 'refresh', 'arrows'],
    category: 'notification',
  },
  {
    icon: 'system_update',
    aliases: ['system update', 'download'],
    category: 'notification',
  },
  {
    icon: 'tap_and_play',
    aliases: ['tap and play', 'nfc', 'wifi'],
    category: 'notification',
  },
  {
    icon: 'time_to_leave',
    aliases: ['time to leave'],
    category: 'notification',
  },
  {
    icon: 'vibration',
    aliases: ['vibration', 'mobile', 'phone'],
    category: 'notification',
  },
  {
    icon: 'voice_chat',
    aliases: ['voice chat', 'camera'],
    category: 'notification',
  },
  {
    icon: 'vpn_lock',
    aliases: ['vpn lock', 'world', 'security'],
    category: 'notification',
  },
  {
    icon: 'wc',
    aliases: ['wc', 'person', 'toilet', 'unisex'],
    category: 'notification',
  },
  {
    icon: 'wifi',
    aliases: ['wifi', 'network'],
    category: 'notification',
  },
  {
    icon: 'ac_unit',
    aliases: ['ac unit', 'snowflake', 'freeze'],
    category: 'places',
  },
  {
    icon: 'airport_shuttle',
    aliases: ['airport shuttle', 'bus', 'car'],
    category: 'places',
  },
  {
    icon: 'all_inclusive',
    aliases: ['all inclusive', 'infinite'],
    category: 'places',
  },
  {
    icon: 'beach_access',
    aliases: ['beach access', 'umbrella', 'parasol'],
    category: 'places',
  },
  {
    icon: 'business_center',
    aliases: ['business center', 'suitcase', 'briefcase'],
    category: 'places',
  },
  {
    icon: 'casino',
    aliases: ['casino', 'dice'],
    category: 'places',
  },
  {
    icon: 'child_care',
    aliases: ['child care', 'baby'],
    category: 'places',
  },
  {
    icon: 'child_friendly',
    aliases: ['child friendly', 'baby'],
    category: 'places',
  },
  {
    icon: 'fitness_center',
    aliases: ['fitness center', 'weights', 'workout'],
    category: 'places',
  },
  {
    icon: 'free_breakfast',
    aliases: ['free breakfast', 'tea', 'coffee', 'drink'],
    category: 'places',
  },
  {
    icon: 'golf_course',
    aliases: ['golf course', 'flag'],
    category: 'places',
  },
  {
    icon: 'hot_tub',
    aliases: ['hot tub', 'jacuzzi'],
    category: 'places',
  },
  {
    icon: 'kitchen',
    aliases: ['kitchen', 'refrigerator', 'cabinet'],
    category: 'places',
  },
  {
    icon: 'pool',
    aliases: ['pool', 'water', 'swimming'],
    category: 'places',
  },
  {
    icon: 'room_service',
    aliases: ['room service', 'bell', 'concierge'],
    category: 'places',
  },
  {
    icon: 'smoke_free',
    aliases: ['smoke free', 'cigarette'],
    category: 'places',
  },
  {
    icon: 'smoking_rooms',
    aliases: ['smoking rooms', 'cigarette'],
    category: 'places',
  },
  {
    icon: 'spa',
    aliases: ['spa', 'flower'],
    category: 'places',
  },
  {
    icon: 'cake',
    aliases: ['cake', 'pie'],
    category: 'social',
  },
  {
    icon: 'domain',
    aliases: ['domain'],
    category: 'social',
  },
  {
    icon: 'group',
    aliases: ['group', 'person', 'user'],
    category: 'social',
  },
  {
    icon: 'group_add',
    aliases: ['group add', 'person', 'user', 'people'],
    category: 'social',
  },
  {
    icon: 'location_city',
    aliases: ['location city', 'building'],
    category: 'social',
  },
  {
    icon: 'mood',
    aliases: ['mood', 'smiley', 'emoji', 'emoticon'],
    category: 'social',
  },
  {
    icon: 'mood_bad',
    aliases: ['mood bad', 'smiley', 'emoji', 'emoticon'],
    category: 'social',
  },
  {
    icon: 'notifications',
    aliases: ['notifications', 'bell', 'ring'],
    category: 'social',
  },
  {
    icon: 'notifications_active',
    aliases: ['notifications active', 'bell', 'ring'],
    category: 'social',
  },
  {
    icon: 'notifications_none',
    aliases: ['notifications none', 'bell', 'ring'],
    category: 'social',
  },
  {
    icon: 'notifications_off',
    aliases: ['notifications off', 'bell', 'ring'],
    category: 'social',
  },
  {
    icon: 'notifications_paused',
    aliases: ['notifications paused', 'bell', 'ring'],
    category: 'social',
  },
  {
    icon: 'pages',
    aliases: ['pages'],
    category: 'social',
  },
  {
    icon: 'party_mode',
    aliases: ['party mode'],
    category: 'social',
  },
  {
    icon: 'people',
    aliases: ['people', 'person', 'user'],
    category: 'social',
  },
  {
    icon: 'people_outline',
    aliases: ['people outline', 'person', 'user'],
    category: 'social',
  },
  {
    icon: 'person',
    aliases: ['person', 'user'],
    category: 'social',
  },
  {
    icon: 'person_add',
    aliases: ['person add', 'user'],
    category: 'social',
  },
  {
    icon: 'person_outline',
    aliases: ['person outline', 'user'],
    category: 'social',
  },
  {
    icon: 'plus_one',
    aliases: ['plus one', 'add'],
    category: 'social',
  },
  {
    icon: 'poll',
    aliases: ['poll', 'graph', 'chart'],
    category: 'social',
  },
  {
    icon: 'public',
    aliases: ['public', 'world', 'earth'],
    category: 'social',
  },
  {
    icon: 'school',
    aliases: ['school', 'university', 'college'],
    category: 'social',
  },
  {
    icon: 'sentiment_very_dissatisfied',
    aliases: ['sentiment very dissatisfied', 'smiley', 'emoji', 'emoticon', 'sad'],
    category: 'social',
  },
  {
    icon: 'sentiment_dissatisfied',
    aliases: ['sentiment dissatisfied', 'smiley', 'emoji', 'emoticon', 'sad'],
    category: 'social',
  },
  {
    icon: 'sentiment_neutral',
    aliases: ['sentiment neutral', 'smiley', 'emoji', 'emoticon'],
    category: 'social',
  },
  {
    icon: 'sentiment_satisfied',
    aliases: ['sentiment satisfied', 'smiley', 'emoji', 'emoticon', 'happy'],
    category: 'social',
  },
  {
    icon: 'sentiment_very_satisfied',
    aliases: ['sentiment very satisfied', 'smiley', 'emoji', 'emoticon', 'happy'],
    category: 'social',
  },
  {
    icon: 'share',
    aliases: ['share'],
    category: 'social',
  },
  {
    icon: 'whatshot',
    aliases: ['whatshot', 'fire'],
    category: 'social',
  },
  {
    icon: 'check_box',
    aliases: ['check box', 'checkmark', 'square'],
    category: 'toggle',
  },
  {
    icon: 'check_box_outline_blank',
    aliases: ['check box outline blank', 'square'],
    category: 'toggle',
  },
  {
    icon: 'indeterminate_check_box',
    aliases: ['indeterminate check box', 'square', 'minus'],
    category: 'toggle',
  },
  {
    icon: 'radio_button_checked',
    aliases: ['radio button checked', 'circle'],
    category: 'toggle',
  },
  {
    icon: 'radio_button_unchecked',
    aliases: ['radio button unchecked', 'circle'],
    category: 'toggle',
  },
  {
    icon: 'star',
    aliases: ['star', 'rating'],
    category: 'toggle',
  },
  {
    icon: 'star_border',
    aliases: ['star border', 'rating'],
    category: 'toggle',
  },
  {
    icon: 'star_half',
    aliases: ['star half', 'rating'],
    category: 'toggle',
  },
]
