import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {
  Icon,
  Chip,
  CardHeader,
  Link,
  List,
  ListItem,
  makeStyles,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@material-ui/core'
import ArchiveIcon from '@material-ui/icons/Archive'
import {useTheme} from '@material-ui/core/styles'

import TwoPanes from '../../components/layouts/two-panes'
import Cancel from '@material-ui/icons/Cancel'

import {timeFormat} from 'd3-time-format'
import {useGetWorkspaceSubscription} from '../../generated/types'
import {userIndex} from '../../api/algolia'
import seedrandom from 'seedrandom'
import {TeamNoteIcon} from '../../components/icons'
import {bingNewsSearch} from '../../api/news'

type Provider = {
  name: string
  image: {}
}

enum NewsType {
  Recent = 'Recent News',
  Shared = 'Shared News',
  Discarded = 'Discarded News',
}

type NewsArticle = {
  category: string
  datePublished: string
  description: string
  image: {
    thumbnail: {
      contentUrl: string
      width: number
      height: number
    }
  }
  name: string
  providers: Provider[]
  url: string
}

const WorkspaceNewsWrapper = () => {
  const classes = useStyles()
  const theme = useTheme()
  const {workspaceId: workspaceIdString} = useParams()
  const workspaceId = parseInt(workspaceIdString || '', 10)
  const {loading, error, data} = useGetWorkspaceSubscription({variables: {workspaceId}})
  const charts = data?.workspaces?.charts
  const formatChipTime = timeFormat('%A, %b %e at %I:%M%p')
  const [searchResults, setSearchResults] = useState<NewsArticle[]>([])
  const [newsArticles, setNewsArticles] = useState<NewsArticle[]>([])
  const [sharedArticles, setSharedArticles] = useState<NewsArticle[]>([])
  const [discardedArticles, setDiscardedArticles] = useState<NewsArticle[]>([])

  const newsTypeToTheme = new Map<NewsType, string>([
    [NewsType.Recent, theme.palette.text.primary],
    [NewsType.Shared, theme.brand.context.team],
    [NewsType.Discarded, theme.palette.text.disabled],
  ])

  const getChartNames = async () => {
    if (!charts) return
    var chartNames: Array<string> = []
    for (var cname of charts) {
      var res = (await userIndex.search(cname.symbol, {
        filters: 'type:chart',
      })) as any
      chartNames.push(res.hits[0].symbol + ' ' + res.hits[0].name)
    }
    return chartNames
  }

  useEffect(() => {
    if (!charts || loading || error) return
    var newsAllCharts: Array<NewsArticle> = []
    const fetchData = async () => {
      var chartNames = await getChartNames()
      if (!chartNames) return
      for (var chartName of chartNames) {
        var newsSingleChart = await bingNewsSearch(chartName)
        newsAllCharts.push(...newsSingleChart.value)
      }
      setSearchResults(newsAllCharts)
      // Arbitrarily assign news articles into buckets, since we don't have a
      // data model or backend API to work with yet. Help make the point that
      // outline is capable of ingesting external data without having to build
      // a complete news pipeline.
      var tempArticles = newsArticles.concat(newsAllCharts.slice(0, 4))
      setNewsArticles(tempArticles)
      tempArticles = sharedArticles.concat(newsAllCharts.slice(5, 7))
      setSharedArticles(tempArticles)
      tempArticles = discardedArticles.concat(newsAllCharts.slice(8, 9))
      setDiscardedArticles(tempArticles)
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charts])

  const renderIcon = (newsType: NewsType) => {
    if (newsType === NewsType.Shared) {
      return (
        <Icon
          style={{
            color: newsTypeToTheme.get(newsType),
          }}
        >
          <TeamNoteIcon />
        </Icon>
      )
    }
    if (newsType === NewsType.Discarded) {
      return <ArchiveIcon />
    }
    return <> </>
  }

  const renderNewsArticles = (newsType: NewsType, articles: NewsArticle[]) => {
    return (
      <div>
        <CardHeader
          disableTypography
          style={{
            textAlign: 'center',
            borderBottom: '2px solid',
            borderColor: newsTypeToTheme.get(newsType),
            backgroundColor: theme.palette.background.paper,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
          }}
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '20px',
                lineHeight: '24px',
                color: newsType === NewsType.Discarded ? newsTypeToTheme.get(newsType) : 'unset', // Only the discarded text is supposed to be of a different color
              }}
            >
              {renderIcon(newsType)}
              <Typography
                variant="h6"
                style={{
                  paddingLeft: newsType !== NewsType.Recent ? theme.spacing(1) : '0px', // Only the shared and discarded headers have icons
                }}
              >
                {newsType.charAt(0).toUpperCase() + newsType.slice(1)}
              </Typography>
            </div>
          }
        />

        <List className={classes.newsList}>
          {articles.map((article) => (
            <ListItem key={seedrandom(article.name).quick()}>
              <Card className={classes.newsCard}>
                <CardContent className={classes.newsCardContent}>
                  <Typography variant="h5" component="h3" style={{fontSize: '15px'}}>
                    {article.name}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom style={{fontSize: '15px'}}>
                    {article.description}
                  </Typography>
                  {article.providers &&
                    article.providers.map((provider: Provider) => (
                      <Typography color="textSecondary" gutterBottom style={{fontSize: '12px'}}>
                        {console.log('provider', provider)}
                        {provider.name}
                      </Typography>
                    ))}
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary">
                    Share
                  </Button>
                  <Link href={article.url} target="_blank" rel="noopener noreferrer">
                    <Button size="small" color="primary">
                      Source
                      <Icon style={{fontSize: '15px'}}>launch</Icon>
                    </Button>
                  </Link>
                  <Typography color="textSecondary" style={{float: 'right', fontSize: '12px'}}>
                    {formatChipTime(new Date(article.datePublished))}
                  </Typography>
                </CardActions>
              </Card>
            </ListItem>
          ))}
        </List>
      </div>
    )
  }

  return (
    <>
      <div style={{display: 'flex'}}>
        <Typography variant="h6">News Triage</Typography>
        <div style={{margin: theme.spacing(0.5), marginLeft: theme.spacing(1.5)}}>
          {charts?.map((chart) => (
            <Chip
              key={seedrandom(chart.symbol + 'ticker').quick()}
              icon={<Cancel />}
              label={chart.symbol}
              size="small"
              style={{marginRight: 5}}
            />
          ))}
        </div>
      </div>

      {searchResults && (
        <TwoPanes
          name="workspaceNewsPane"
          pane1={renderNewsArticles(NewsType.Recent, newsArticles)}
          pane2={
            <TwoPanes
              pane1={renderNewsArticles(NewsType.Shared, sharedArticles)}
              pane2={renderNewsArticles(NewsType.Discarded, discardedArticles)}
              name="newsSharedDiscarded"
              direction="horizontal"
            />
          }
        />
      )}
    </>
  )
}
const useStyles = makeStyles((theme) => ({
  newsList: {
    borderRadius: theme.shape.borderRadius,
  },
  newsCard: {
    padding: theme.spacing(1),
  },
  newsCardContent: {
    padding: theme.spacing(1),
  },
}))
export default WorkspaceNewsWrapper
