import React, {useEffect} from 'react'
import {
  Paper,
  ListSubheader,
  Grid,
  Divider,
  Button,
  ListItemSecondaryAction,
} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {useParams, useRouteMatch, Link} from 'react-router-dom'

import {
  UserClientNotePane,
  MessageList,
  UserClientChatPane,
  UserClientList,
  UserClientNoteChart,
} from '../../components'
import routes from '../../routes'
import {useNavDispatch} from '../../utils/nav-context'

import ThreePanes from '../../components/layouts/three-panes'

const UserClients = () => {
  const classes = useStyles()
  const navDispatch = useNavDispatch()

  const {publicNoteId: publicNoteIdString, clientId} = useParams<{
    publicNoteId: string
    clientId: string
  }>()
  const publicNoteId = parseInt(publicNoteIdString || '', 10)
  const showChart = !!useRouteMatch(routes.user.clientsClientNoteChart.path)?.isExact

  useEffect(() => {
    navDispatch({workspaceId: null})
  }, [navDispatch])

  const renderClientList = () => (
    <Paper style={{height: '100%'}} variant="outlined">
      <UserClientList selectedClientId={clientId} />
    </Paper>
  )

  const renderContent = () => {
    if (showChart)
      return (
        <Paper variant="outlined" className={classes.threadPane}>
          <Grid container direction="column" style={{height: '100%'}}>
            <ListSubheader component="div">
              Chart
              <ListItemSecondaryAction>
                <Button
                  variant="outlined"
                  color="secondary"
                  component={Link}
                  to={routes.user.clientsClientNote.createUrl(clientId, publicNoteId)}
                >
                  View Notes
                </Button>
              </ListItemSecondaryAction>
            </ListSubheader>
            <Divider />
            <div style={{flex: 1}}>
              <UserClientNoteChart publicNoteId={publicNoteId} clientId={clientId} />
            </div>
          </Grid>
        </Paper>
      )
    return (
      <Paper variant="outlined" className={classes.threadPane}>
        <MessageList clientId={clientId} selectedNoteId={publicNoteId} />
      </Paper>
    )
  }

  const renderSidePane = () => {
    if (publicNoteId) return <UserClientNotePane publicNoteId={publicNoteId} clientId={clientId} />
    return <UserClientChatPane clientId={clientId} />
  }

  if (!clientId)
    return (
      <Paper style={{height: '100%'}} variant="outlined">
        <UserClientList />
      </Paper>
    )

  return (
    <ThreePanes
      left={renderClientList()}
      middle={renderContent()}
      right={renderSidePane()}
      //   name="clientScreenVerticalPaneSplit"
      leftName="clientScreenVerticalPaneSplit"
      rightName="clientScreenNoteListPaneSplit"
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    maxWidth: 360,
  },
  threadPane: {
    width: '100%',
    height: '100%',
  },
  sidePane: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default UserClients
