import React from 'react'
import {Grid, Box, Typography} from '@material-ui/core'

import {ReactComponent as Logo} from '../assets/img/outline.svg'

const FourHundredAndFour = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{height: '100vh'}}
    >
      <Box m={5}>
        <Logo style={{height: 200}} />
      </Box>
      <Typography variant="h2">Nothing to see here</Typography>
    </Grid>
  )
}

export default FourHundredAndFour
