import React from 'react'
import {
  Button,
  TextField,
  FormControlLabel,
  Grid,
  Typography,
  Paper,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import {FrontendClientFragment} from '../../../generated/types'

type CSSFProps = {
  onWholeTeamClick: () => void
  allClients: FrontendClientFragment[]
  onSelectedClientsListChange: (_: object, value: any) => void
  clientsValue: FrontendClientFragment[]
}
export const ClientSelectionStepForm = ({
  onWholeTeamClick,
  allClients,
  onSelectedClientsListChange,
  clientsValue,
}: CSSFProps) => (
  <Grid container spacing={5}>
    <Grid item>
      <Paper variant="outlined" style={{padding: 20}}>
        <Typography variant="caption">
          Publishing this note means selected clients will be able to read the note as it is. If you
          make changes to the note later, you will have to publish those changes too.
        </Typography>
      </Paper>
    </Grid>
    <Grid item container direction="column" alignContent="stretch" spacing={2}>
      <Grid item>
        <Button
          onClick={onWholeTeamClick}
          color="primary"
          variant="contained"
          size="large"
          fullWidth
        >
          Publish to all clients of my team
        </Button>
      </Grid>
      <Grid container item justify="center">
        <Typography>- or -</Typography>
      </Grid>
      <Grid item>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={allClients}
          getOptionLabel={client => `${client.user.full_name} (${client.company.name})`}
          filterSelectedOptions
          onChange={onSelectedClientsListChange}
          value={clientsValue}
          renderInput={params => (
            <TextField {...params} variant="outlined" placeholder="Type to select client…" />
          )}
        />
      </Grid>
    </Grid>
  </Grid>
)

type ESSFProps = {
  value?: 'outline' | 'local' | 'none'
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
export const EmailSelectionStepForm = ({value, onChange}: ESSFProps) => (
  <RadioGroup value={value} onChange={onChange}>
    <Grid container spacing={3}>
      <Grid item>
        <FormControlLabel
          value="outline"
          control={<Radio />}
          label={
            <Grid container direction="column">
              <Typography variant="body1">Let Outline send them the note</Typography>
              <Typography variant="caption">
                Outline will send each client an email with the note and a link to see the note in
                Outline and talk to you directly. You can enter a personal message in the next step.
              </Typography>
            </Grid>
          }
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          value="local"
          control={<Radio />}
          label={
            <Grid container direction="column">
              <Typography variant="body1">I'll send the link to the note myself</Typography>
              <Typography variant="caption">
                Outline will open your email client and you can send a link to the note yourself.
                Only clients you selected in the previous step will have access to the note. You
                will not be able to see when your clients opened the email.
              </Typography>
            </Grid>
          }
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          value="none"
          control={<Radio />}
          label={
            <Grid container direction="column">
              <Typography variant="body1">Publish without sending a notification</Typography>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  </RadioGroup>
)

type MSFProps = {
  value?: string | null
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
export const MessageStepForm = ({value, onChange}: MSFProps) => (
  <Grid container direction="column" spacing={1}>
    <Grid item>
      <TextField
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        autoComplete="off"
        onChange={onChange}
        value={value}
        placeholder="Add a personal message (optional)…"
      />
    </Grid>
    <Grid item>
      <Typography variant="caption">
        The personal message will also be visible in the chat thread for the note.
      </Typography>
    </Grid>
  </Grid>
)
