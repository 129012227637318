import React, {useEffect, useState, useRef} from 'react'
import {Grid, InputBase, Typography, Box, Paper} from '@material-ui/core'
import {makeStyles, fade} from '@material-ui/core/styles'
import {timeFormat} from 'd3-time-format'

import {useChannel} from '../../lib/channel-context'
import {Message} from '../../lib/message-types'

import {useUserState} from '../../utils/user-context'

const formatMessageTime = timeFormat('%I:%M %p')

type GroupedMessage = Message & {isFirstMessage: boolean}
type ChatProps = {
  channelId?: string
}
const Chat = ({channelId}: ChatProps) => {
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  const {user} = useUserState()
  const [message, setMessage] = useState('')
  const {messages, sendMessage, startTyping, typingUsers} = useChannel(channelId, {
    loadMessages: true,
    markAsRead: true,
  })

  const groupedMessages = messages.map((msg, i) => {
    let result = msg as GroupedMessage
    result.isFirstMessage = i === 0 || messages[i - 1].sender.id !== msg.sender.id
    return result
  })

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({behavior: 'smooth'})
  }

  useEffect(scrollToBottom, [])
  useEffect(scrollToBottom, [messages])

  const handleMessageChange = (e: any) => {
    startTyping()
    setMessage(e.target.value)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    sendMessage(message)
    setMessage('')
  }

  const renderInput = () => (
    <form onSubmit={handleSubmit}>
      <Box p={1}>
        <InputBase
          autoComplete="off"
          placeholder="Message..."
          className={classes.input}
          fullWidth
          value={message}
          onChange={handleMessageChange}
        />
      </Box>
    </form>
  )

  const renderMessage = (message: GroupedMessage) => (
    <Box
      className={user && message.sender.id === user.id ? classes.messageSelf : classes.message}
      key={message.id}
    >
      {message.isFirstMessage && (
        <Typography className={classes.sender}>
          <strong>{message.sender.full_name}</strong> {formatMessageTime(message.createdAt)}
        </Typography>
      )}
      <Typography variant="body2">{message.text}</Typography>
    </Box>
  )

  const renderTypingUsers = () =>
    typingUsers.map(user => (
      <Typography className={classes.sender} key={user.id}>
        <strong>{user.full_name}</strong> is typing...
      </Typography>
    ))

  const renderPlaceholder = () => (
    <Typography component="div" className={classes.placeholder}>
      Send a message to start chatting...
    </Typography>
  )

  const renderMessages = () => (
    <div className={classes.chatGrid}>
      <div className={classes.chatInner}>
        {groupedMessages.length === 0 && renderPlaceholder()}
        {groupedMessages.map(renderMessage)}
        {renderTypingUsers()}
        <div ref={messagesEndRef} />
      </div>
    </div>
  )

  return (
    <Paper style={{height: '100%', width: '100%', overflow: 'auto'}}>
      <Grid container direction="column" justify="flex-end" style={{height: '100%'}}>
        {renderMessages()}
        <Grid item>{renderInput()}</Grid>
      </Grid>
    </Paper>
  )
}

const useStyles = makeStyles(theme => ({
  placeholder: {
    textAlign: 'center',
    width: '100%',
    display: 'block',
    opacity: 0.5,
    marginTop: theme.spacing(1),
  },
  input: {
    backgroundColor: fade(theme.palette.text.primary, 0.1),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1.5),
  },
  chatGrid: {
    flex: '1 1 auto',
    height: 0,
    position: 'relative',
    overflowY: 'auto',
  },
  chatInner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
    alignItems: 'start',
    padding: theme.spacing(0, 1),
  },
  message: {},
  messageSelf: {
    opacity: 0.7,
  },
  sender: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.fontSize * 0.9,
  },
  isSending: {
    backgroundColor: theme.palette.grey[500],
  },
}))

export default Chat
