import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'

import {UserSidebar, UserToolbar} from '../../components'
import {Workspace, WorkspaceFeed, WorkspaceModels, WorkspaceNews, Feed, Clients} from '.'
import {useMessageNotifications} from '../../utils/notifications'
import routes, {OutlineUserRoute} from '../../routes'
import clsx from 'clsx'

export default function UserHome() {
  useMessageNotifications()
  const classes = useStyles()
  return (
    <div style={{display: 'flex'}}>
      <UserSidebar />
      <UserToolbar />
      <div className={classes.content}>
        <div className={classes.toolbarSpacer} />
        <div className={clsx(classes.main)}>
          <Switch>
            <OutlineUserRoute path={routes.user.workspace.path} component={Workspace} exact />
            <OutlineUserRoute
              path={routes.user.workspaceFeed.path}
              component={WorkspaceFeed}
              exact
            />
            <OutlineUserRoute
              path={routes.user.workspaceModels.path}
              component={WorkspaceModels}
              exact
            />
            <OutlineUserRoute
              path={routes.user.workspaceNews.path}
              component={WorkspaceNews}
              exact
            />
            <OutlineUserRoute path={routes.user.clientsClientNoteChart.path} component={Clients} />
            <OutlineUserRoute path={routes.user.clientsClientNote.path} component={Clients} />
            <OutlineUserRoute path={routes.user.clientsClient.path} component={Clients} />
            <OutlineUserRoute path={routes.user.clients.path} component={Clients} exact />
            <OutlineUserRoute path={routes.user.root.path} component={Feed} exact />
            <Route>
              <Redirect to={routes.common.fourhundredandfour.createUrl()} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  content: {
    height: '100vh',
    maxWidth: '100%',
    flexGrow: 1,
  },
  toolbarSpacer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  main: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'hidden',
    overflowX: 'hidden',
    height: 'calc(100vh - 56px)',
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 'calc(100vh - 48px)',
    },
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 64px)',
    },
  },
}))
