import React from 'react'
import SplitPane from 'react-split-pane'
import createPersistedState from 'use-persisted-state'

type TPProps = {
  pane1: React.ReactNode
  pane2: React.ReactNode
  name: string
  direction?: 'vertical' | 'horizontal'
  pane1Style?: {}
  pane2Style?: {}
}

const verticalResizer = {
  zIndex: 4000,
  width: 11,
  margin: '0 -5px',
  cursor: 'col-resize',
}

const horizontalResizer = {
  zIndex: 4000,
  height: 11,
  margin: '-5px 0',
  cursor: 'row-resize',
}

const pane1Styling = {overflow: 'auto'}
const pane2Styling = {overflow: 'auto'}

const TwoPanes = ({
  pane1,
  pane2,
  name,
  direction = 'vertical',
  pane1Style = pane1Styling,
  pane2Style = pane2Styling,
}: TPProps) => {
  const useSplit = createPersistedState(name)
  const [split, setSplit] = useSplit(0)

  return (
    <SplitPane
      split={direction}
      minSize={200}
      defaultSize={split || '60%'}
      // overflow: hidden - the individual panes will scroll internally,
      // so there's no need for an overflow mechanism on the container with both panes
      // (it should just be sized according to it's container)
      // position: static/unset - SplitPane's default styling sets the position to 'absolute' meaning the component doesn't respect the other elements on the page and renders at 0,0
      style={{position: 'static', overflow: 'hidden'}}
      onChange={(size) => setSplit(size)}
      maxSize={-100}
      pane1Style={pane1Style}
      resizerStyle={direction === 'vertical' ? verticalResizer : horizontalResizer}
      pane2Style={pane2Style}
    >
      {pane1}
      {pane2}
    </SplitPane>
  )
}

export default TwoPanes
