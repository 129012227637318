import {useState, useEffect, useCallback} from 'react'
import {useTheme} from '@material-ui/core/styles'
import {useNavState} from './nav-context'
type Dimensions = {
  width: number
  height: number
}

type CSProps = {
  minWidth?: number
  minHeight?: number
  padding?: number
}

const useContentSize = ({minWidth = 100, minHeight = 100, padding = 0}: CSProps) => {
  const theme = useTheme()
  const {isSidebarOpen, isRightSidepanelOpen, isLandingPaneOpen} = useNavState()

  const drawerWidth = isSidebarOpen ? theme.shape.drawerWidth : theme.shape.drawerClosedWidth
  const landingPaneWidth = isLandingPaneOpen ? theme.shape.landingPaneWidth : theme.shape.landingPaneClosedWidth
  const paddingSize = theme.spacing(padding)

  const getSize = useCallback((): Dimensions => {
    const toolbarHeight = window.innerHeight >= 600 ? 64 : 56

    var chartWidth = window.innerWidth - drawerWidth - paddingSize - landingPaneWidth
    chartWidth = isRightSidepanelOpen ? chartWidth * 0.6 : chartWidth
    const customWidth = localStorage.getItem('userChartNotePaneSplit')
    if (
      isRightSidepanelOpen &&
      customWidth !== undefined &&
      customWidth !== null &&
      customWidth !== 'null' &&
      customWidth !== '0'
    ) {
      chartWidth = JSON.parse(customWidth || '300') as number
    }

    return {
      width: Math.max(minWidth, chartWidth),
      height: Math.max(minHeight, window.innerHeight - toolbarHeight - paddingSize),
    }
    // Adding add dependencies, especially, the right side pane makes
    // this glitchy because of the repeated re-renders of all underlying components
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerWidth, minHeight, minWidth, paddingSize, landingPaneWidth])

  let [contentSize, setContentSize] = useState<Dimensions>(getSize())

  useEffect(() => {
    setContentSize(getSize())
  }, [drawerWidth, setContentSize, getSize, isRightSidepanelOpen, landingPaneWidth])

  useEffect(() => {
    const handleResize = () => setContentSize(getSize())
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [getSize])

  return contentSize
}

export default useContentSize
