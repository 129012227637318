import React, {useState} from 'react'

import {Popover, Chip, TextField} from '@material-ui/core'
import ScheduleIcon from '@material-ui/icons/DateRange'
import ChartIcon from '@material-ui/icons/ShowChart'

import {timeFormat} from 'd3-time-format'
import {useNoteState} from './note-context'

const NoteChips = () => {
  const {canEdit, time, setTime, chartSymbol} = useNoteState()
  const [timeAnchor, setTimeAnchor] = useState<null | HTMLElement>(null)
  const [displayTime, setDisplayTime] = useState<Date>(time)
  const formatChipTime = timeFormat('%A, %b %e at %I:%M%p')
  const formatInputTime = timeFormat('%Y-%m-%dT%H:%M')

  const handleTimeOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setTimeAnchor(event.currentTarget)
  }

  const handleTimeClose = () => {
    setTimeAnchor(null)
  }

  return (
    <div style={{marginTop: 10}}>
      <Chip icon={<ChartIcon />} label={chartSymbol} size="small" style={{marginRight: 5}} />
      <Chip
        icon={<ScheduleIcon />}
        label={formatChipTime(displayTime)}
        size="small"
        onClick={canEdit ? handleTimeOpen : undefined}
      />
      {canEdit && (
        <Popover
          anchorEl={timeAnchor}
          open={!!timeAnchor}
          onClose={handleTimeClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <TextField
            id="datetime-local"
            type="datetime-local"
            value={formatInputTime(displayTime)}
            onChange={event => {
              const newTime = new Date(event.target.value)
              setDisplayTime(newTime)
              setTime(newTime)
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Popover>
      )}
    </div>
  )
}

export default NoteChips
