import {Auth0User} from './auth0'

export const intercomSettings = {
  app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  custom_launcher_selector: '#openSupportLink',
}

export const getIntercomConfigFromAuth0User = (auth0User: Auth0User) => ({
  user_id: auth0User.sid,
  name: auth0User.nickname,
  email: auth0User.email,
  created_at: auth0User.updated_at,
})
