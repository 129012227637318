import React, {createContext, useContext, useState} from 'react'

import {useGetUserQuery, FrontendUserFragment, Users} from '../generated/types'
import {useEffect} from 'react'
import {useCallback} from 'react'
import {useAuth0} from './auth0'

type UserState = {
  user: FrontendUserFragment | null
  team: Array<Pick<Users, 'id' | 'full_name'>> | null
  clients: Array<Pick<Users, 'id' | 'full_name'>> | null
  isFetchingUser: boolean
  fetchUser: (id: string) => void
}
const UserStateContext = createContext<UserState>({
  user: null,
  team: [],
  clients: [],
  isFetchingUser: false,
  fetchUser: id => {},
})

type UPProps = {
  children: React.ReactNode
}
const UserProvider = ({children}: UPProps) => {
  const {logout} = useAuth0()

  const [userId, setUserId] = useState<string | null>(null)
  const [isFetchingUser, setIsFetchingUser] = useState(false)
  // The `|| ''` looks dangerous, but I think it is ok because of the skipping when `!userId`.
  // So this is just to make Typescript happy.
  const {data, error} = useGetUserQuery({variables: {id: userId || ''}, skip: !userId})
  if (error) throw error

  const user = data?.user || null
  const team = user?.outline_user?.team.members.map(m => m.user) || null
  const clients = user?.outline_user?.team.contacts.map(m => m.client.user) || null
  if (data && !data.user) {
    console.log(`Didn't get a user back for id ${userId}`)
    logout()
  }

  const fetchUser = useCallback((userId: string) => {
    setIsFetchingUser(true)
    setUserId(userId)
  }, [])

  useEffect(() => {
    user && setIsFetchingUser(false)
  }, [user])

  return (
    <UserStateContext.Provider value={{user, team, clients, fetchUser, isFetchingUser}}>
      {children}
    </UserStateContext.Provider>
  )
}

const useUserState = () => {
  const context = useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }
  return context
}

export {UserProvider, useUserState}
