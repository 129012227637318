import algoliasearch from 'algoliasearch/lite'

import {Permissions_Enum} from '../generated/types'

const algoliaClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
)

// TODO: Implement secure API keys for the user index too.
export const userIndex = algoliaClient.initIndex(`${process.env.REACT_APP_STAGE}_user`)

export const getClientIndex = (secureApiKey: string) => {
  const secureAlgoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    secureApiKey,
  )
  return secureAlgoliaClient.initIndex(`${process.env.REACT_APP_STAGE}_client`)
}

// TODO: Move these types to a package that can be shared by frontend and backend.
export type AlgoliaChartType = {
  type: 'chart'
  name: string
  symbol: string
  exchange: string
}

export type AlogliaNoteType = {
  type: 'note'
  title: string
  createdAt: string
  ownerDisplayName: string
  noteId: number
  ownerId: string
  viewableByTeam: number | null
  organizationId: number
  permission: Permissions_Enum
}

export type AlogliaPublicNoteType = {
  type: 'public_note'
  title: string
  createdAt: string
  ownerDisplayName: string
  publicNoteId: number
}
