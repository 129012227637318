import React, {useState} from 'react'
import {Icon, Button, Box, Grid, IconButton} from '@material-ui/core'
import {useHistory} from 'react-router-dom'
import TickerSelect from './toolbar/ticker-select'
import {
  useUpdateClientChartMutation,
  Chart_Periods_Enum,
  Chart_Styles_Enum,
} from '../generated/types'
import routes from '../routes'
import {useNavState} from '../utils/nav-context'

const ClientChartPicker = () => {
  const {isSidebarOpen} = useNavState()
  const [showTicker, setShowTicker] = useState(false)
  const [updateChartSettings] = useUpdateClientChartMutation()
  const history = useHistory()

  const addChart = async (symbol: string) => {
    handleHideTicker()
    await updateChartSettings({
      variables: {
        symbol,
        period: Chart_Periods_Enum.Period_1mm,
        type: Chart_Styles_Enum.Candlestick,
      },
      optimisticResponse: {
        insert_client_charts_one: {
          __typename: 'client_charts',
          symbol,
          period: Chart_Periods_Enum.Period_1mm,
          type: Chart_Styles_Enum.Candlestick,
        },
      },
    })
    history.push(routes.client.chart.createUrl(symbol))
  }

  const handleHideTicker = () => setShowTicker(false)
  const handleShowTicker = () => setShowTicker(true)

  const renderButton = () => {
    if (isSidebarOpen)
      return (
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          startIcon={<Icon>add_circle</Icon>}
          onClick={handleShowTicker}
        >
          Add Chart...
        </Button>
      )

    return (
      <Button
        variant="outlined"
        color="primary"
        style={{padding: 5, minWidth: 40}}
        onClick={handleShowTicker}
      >
        <Icon style={{marginRight: 0}}>add_circle</Icon>
      </Button>
    )
  }

  return (
    <Box p={1}>
      {showTicker ? (
        <Grid container direction="row">
          <TickerSelect fullWidth focus onSelect={addChart} />
          <IconButton size="small" onClick={handleHideTicker} color="primary">
            <Icon>cancel</Icon>
          </IconButton>
        </Grid>
      ) : (
        renderButton()
      )}
    </Box>
  )
}

export default ClientChartPicker
