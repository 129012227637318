import React from 'react'
import {useTheme} from '@material-ui/core/styles'

type OLProps = {
  size: number
  style: React.CSSProperties
}

const OutlineLoading = ({size, style}: OLProps) => {
  const theme = useTheme()
  const color = theme.brand.primary.main

  return (
    <div style={{width: size, height: size, ...style}}>
      <svg
        id="eyujij6q6yh1"
        viewBox="0 0 400 400"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        width="100%"
        height="100%"
      >
        <style>
          {`
    #ring1 {animation: ring1_anim 3000ms linear infinite normal forwards}
    @keyframes ring1_anim {
        0% {opacity: 0; animation-timing-function: cubic-bezier(0.4,0,1,1)}
        25% {opacity: 1; animation-timing-function: cubic-bezier(0.4,0,1,1)}
        60% {opacity: 0}
        100% {opacity: 0}
    }

    #ring2 {animation: ring2_anim 3000ms linear infinite normal forwards}
    @keyframes ring2_anim {
        0% {opacity: 0}
        40% {opacity: 0; animation-timing-function: cubic-bezier(0.4,0,1,1)}
        65% {opacity: 1; animation-timing-function: cubic-bezier(0.4,0,1,1)}
        100% {opacity: 0}
    }

    #ring3 {animation: ring3_anim 3000ms linear infinite normal forwards}
    @keyframes ring3_anim {
        0% {opacity: 0}
        20% {opacity: 0;animation-timing-function: cubic-bezier(0.4,0,1,1)}
        45% {opacity: 1;animation-timing-function: cubic-bezier(0.4,0,1,1)}
        80% {opacity: 0}
        100% {opacity: 0}
    }

    #ring4 {animation: ring4_anim 3000ms linear infinite normal forwards}
    @keyframes ring4_anim {
        0% {opacity: 0}
        30% {opacity: 0; animation-timing-function: cubic-bezier(0.4,0,1,1)}
        55% {opacity: 1; animation-timing-function: cubic-bezier(0.4,0,1,1)}
        90% {opacity: 0}
        100% {opacity: 0}
    }

    #ring5 {animation: ring5_anim 3000ms linear infinite normal forwards}
    @keyframes ring5_anim {
        0% {opacity: 0}
        10% {opacity: 0; animation-timing-function: cubic-bezier(0.4,0,1,1)}
        35% {opacity: 1; animation-timing-function: cubic-bezier(0.4,0,1,1)}
        70% {opacity: 0}
        100% {opacity: 0}
    }

    `}
        </style>
        <path
          id="ring1"
          d="M199,110C200,110,201,110,202,110L211,126C207,125,203,125,199,125C157,125,124,158,124,200C124,241,157,275,199,275C240,275,274,241,274,200C274,196,273,193,273,189L289,198C289,198,289,199,289,200C289,249,248,290,199,290C149,290,109,249,109,200C109,150,149,110,199,110Z"
          transform="matrix(0 -1 1 0 -1 400)"
          opacity="0"
          fill={color}
          stroke="none"
        />
        <path
          id="ring2"
          d="M45,46C59,55,70,64,78,72C87,81,96,91,104,105C89,101,77,94,66,82C56,71,49,59,45,46Z"
          transform="matrix(0 -1 1 0 -1.2 150)"
          opacity="0"
          fill={color}
          stroke="none"
        />
        <path
          id="ring3"
          d="M174,19C235,11,199,69,263,136C327,203,391,159,379,227C364,314,291,382,200,382C99,382,18,300,18,200C18,108,85,32,174,19ZM195,36C192,34,187,33,176,34C94,46,33,116,33,200C33,292,107,367,200,367C282,367,350,306,365,225L365,224C367,212,366,206,364,203C362,201,357,199,346,196L336,193C333,192,330,192,328,191L327,191C319,188,311,186,304,183C285,175,268,163,252,146C236,130,225,112,217,94C214,87,211,80,209,72L208,71C207,67,203,54,203,53L202,52C199,42,197,38,195,36Z"
          transform="matrix(0 -1 1 0 -1.33 400)"
          opacity="0"
          fill={color}
          stroke="none"
        />
        <path
          id="ring4"
          d="M21,24C31,25,39,28,44,35C52,45,47,77,82,106C116,135,136,133,148,140C156,144,161,152,163,164C162,160,158,157,153,155C145,152,98,146,68,115C39,84,36,39,31,32C28,27,25,24,21,24Z"
          transform="matrix(0 -1 1 0 -2.24 187.321)"
          opacity="0"
          fill={color}
          stroke="none"
        />
        <path
          id="ring5"
          d="M186,64L191,79C128,83,78,136,78,200C78,267,133,321,199,321C263,321,315,272,320,210L335,213C328,282,270,336,199,336C124,336,63,275,63,200C63,129,117,71,186,64Z"
          transform="matrix(0 -1 1 0 -1 400)"
          opacity="0"
          fill={color}
          stroke="none"
        />
      </svg>
    </div>
  )
}

export default OutlineLoading
