/* withShortcuts

a plugin component for the Slate Editor 
and supporting functions that enable certain keypresses
to create new types of elements in the editor

*/

import {Editor, Transforms, Range, Point} from 'slate'
import {ReactEditor} from 'slate-react'

const SHORTCUTS: {[key: string]: string} = {
  '*': 'list-item',
  '-': 'list-item',
  '+': 'list-item',
  '%': 'callout',
  '>': 'blockquote',
  '#': 'heading-one',
  '##': 'heading-two',
  '###': 'heading-three',
}

// the plugin component to wrap around the editor
export const withShortcuts = (editor: ReactEditor) => {
  // bring these default commands into the closure to use as callbacks in the methods that overwrite them
  const {deleteBackward, insertText} = editor

  // overwrites the Editor's insertText method
  editor.insertText = (text: string) => {
    // gather the user's selection from the editor
    const {selection} = editor
    // if the cursor is at a space and no text is highlighted
    if (text === ' ' && selection && Range.isCollapsed(selection)) {
      // get the anchor (initial point of the user's selection)
      const {anchor} = selection
      // get the block above this point (the cursor's position) if there is one
      const block = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n),
      })
      // if there is a block above, save its second node as the `path`
      // essentially this skips the triggering shortcut symbol, e.g. ##
      const path = block ? block[1] : []
      // from this path on in the editor
      const start = Editor.start(editor, path)
      // draw a range from the last block to after the triggering symbol
      const range = {anchor, focus: start}
      // figure out what string lies in that range
      const beforeText: string = Editor.string(editor, range)
      // match the string to one of the keys in the SHORTCUTS dictionary above
      const type = SHORTCUTS[beforeText]
      // if there is a matching key, e.g. @ or %,
      if (SHORTCUTS.hasOwnProperty(beforeText)) {
        Transforms.select(editor, range)
        // clear those triggering symbols
        Transforms.delete(editor)
        // set this block type to the corresponding SHORTCUTS value
        Transforms.setNodes(editor, {type}, {match: n => Editor.isBlock(editor, n)})
        if (type === 'list-item') {
          // being sure to wrap an unordered list-item in a list element
          const list = {type: 'bulleted-list', children: []}
          Transforms.wrapNodes(editor, list, {
            match: n => n.type === 'list-item',
          })
        } // switch back to normal insert mode so the element can be filled in
        return
      }
    }
    // otherwise, allow for the default insertText behavior with this callback
    insertText(text)
  }

  // overwrites the Editor's deleteBackward method
  editor.deleteBackward = (unit: 'character' | 'word' | 'line' | 'block') => {
    // get the user's selection
    const {selection} = editor
    // if the cursor is blinking :)
    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        // look at whether a block precedes the cursor
        match: n => Editor.isBlock(editor, n),
        // as opposed to a leaf or inline element
      })
      // this might mean the cursor is at the beginning of a line
      if (match) {
        const [block, path] = match
        const start = Editor.start(editor, path)
        // the block isn't a paragraph and the cursor sits at the front of the block
        if (block.type !== 'paragraph' && Point.equals(selection.anchor, start)) {
          // set the node as a paragraph?
          Transforms.setNodes(editor, {type: 'paragraph'})
          if (block.type === 'list-item') {
            // if it's a list item, unwrap the bulleted list
            // so we can delete list items individually
            Transforms.unwrapNodes(editor, {
              match: n => n.type === 'bulleted-list',
              split: true,
            })
          }
          // otherwise leave it alone?
          return
        }
      }
      // and continue with the default deleteBackward behavior
      deleteBackward(unit)
    }
  }

  return editor
}
