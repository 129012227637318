const keymap = {
  FEED: 'Shift+0',
  WORKSPACE_1: 'Shift+1',
  WORKSPACE_2: 'Shift+2',
  WORKSPACE_3: 'Shift+3',
  WORKSPACE_4: 'Shift+4',
  WORKSPACE_5: 'Shift+5',
  WORKSPACE_6: 'Shift+6',
  WORKSPACE_7: 'Shift+7',
  WORKSPACE_8: 'Shift+8',
  WORKSPACE_9: 'Shift+9',
  NEW_WORKSPACE: `Shift+n`,
  HIGHLIGHT_SEARCH: '/',
  HELP: '?',
  CANCEL_SELECTION: 'esc',
}
export default keymap
