/* NoteEditor Component
 * 
 * an instance of the SlateJS editor
 * with a wealth of plugins to enable
 * image and file uploads, some markdown,
 * some rich text, fancy links, hotkeys,
 * and mentions
 * 
 * much of the logic concerning mentions that is here in the NoteEditor,
 * in the withMentions plugin, and in the MentionElement was adapted from 
 * the [SlateJS mentions example](https://github.com/ianstormtaylor/slate/blob/master/site/examples/mentions.tsx)
 */
import React, {useState, useCallback, useMemo, useRef, useEffect} from 'react'
import {createEditor, Node, Range} from 'slate'
import {Slate, Editable, withReact, ReactEditor} from 'slate-react'
import {withHistory} from 'slate-history'
import {
  HoveringToolbar,
  handleHotkeys,
  withShortcuts,
  withMentions, handleMentionKeys, toggleMentionsMenu, renderMentionsMenu, detectMentions,
  withUploads,
  withLayout,
  withBreaks,
  withLinks,
} from './plugins'
import {Element, Leaf} from './elements'
import {useApolloClient} from '@apollo/react-hooks'

export const BLANK: Node[] = [
  {
    type: 'heading-one',
    children: [{text: ''}],
  },
]

type Plugin = (editor: ReactEditor) => ReactEditor
export function withPlugins(plugins: Plugin[]) {
  return plugins.reduce((y: ReactEditor, fn) => fn(y), createEditor() as ReactEditor)
}

type NEProps = {
  onChange: (content: Node[]) => void
  value: Node[]
}
const NoteEditor = ({onChange, value}: NEProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [target, setTarget] = useState<Range>()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const [editorValue, setEditorValue] = useState<Node[]>(value || BLANK)
  const renderElement = useCallback((props) => <Element {...props} />, [])
  const apolloClient = useApolloClient()

  const editor = useMemo(() => {
    const plugins = [
      withHistory,
      withReact,
      withBreaks,
      withLinks,
      // Partial to include apollo client in the plugin
      (editor: ReactEditor) => withUploads(apolloClient, editor),
      withLayout,
      withShortcuts,
      withMentions,
    ]

    return withPlugins(plugins)
  }, [apolloClient])

  const handleChange = (value: Node[]) => {
    const {selection} = editor
    setEditorValue(value)
    // intercepts changes in the editor value
    detectMentions(selection, editor, setIndex, setSearch, setTarget)
    onChange(value)
  }

  // when there is a mention 'target', the mentionable menu is showing
  // and the arrow keys, tab, enter, and escape gain new abilities
  const handleKeys = useCallback(
    (event) => {
      if (target) handleMentionKeys(event, editor, index, setIndex, search, target, setTarget)
      else handleHotkeys(editor, event)
    }, [index, search, target, editor]
  )

  // waits for mentionables, then finds the target and places the menu under it
  useEffect(() => {
    toggleMentionsMenu(editor, ref, search, target)
  }, [editor, search, target])

  return (
    <Slate
      editor={editor}
      value={editorValue}
      onChange={handleChange}>
      <HoveringToolbar />
      <Editable
        renderElement={renderElement}
        renderLeaf={(props) => <Leaf {...props} />}
        autoFocus
        spellCheck
        placeholder="Enter some notes…"
        onKeyDown={handleKeys}
        // if we want to allow text selection
        // without showing the hovering format toolbar
        // we can start by detecting the mouse events here
        // onMouseDown={(event) => handleSelection(editor, event)}
      />
      {renderMentionsMenu(index, ref, search, target)}
    </Slate>
  )
}

export default NoteEditor
