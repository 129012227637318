import React from 'react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'
import {makeStyles, Theme} from '@material-ui/core'

// TODO: determine whether we can use a material-ui Popover instead
const Portal = ({ children }: any) => {
  return ReactDOM.createPortal(children, document.body)
}
const useStyles = makeStyles<Theme>(theme => ({
    // styles for the portal and its menu of mentionables
    portal: {
        top: '-9999px',
        left: '-9999px',
        position: 'absolute',
        zIndex: theme.zIndex.snackbar + 1,
        padding: '3px',
        background: theme.palette.background.paper,
        borderRadius: '4px',
        boxShadow: theme.shadows[2],
    },
    mentionable: {
        padding: '1px 3px',
        borderRadius: '3px',
        fontWeight: 500,
        backgroundColor: 'transparent',
        color: theme.brand.success.textcolor,
    },
    focused: {
        backgroundColor: theme.palette.action.selected,
    },
    person: {
        color: theme.brand.info.textcolor
    }
    }))

type MMProps = {
  mentionables: string[],
  index: number,
  targetRef: React.RefObject<HTMLDivElement>
}

const MentionsMenu = ({mentionables, index, targetRef}: MMProps) => {
    const classes = useStyles()

    return (
    // places the mentionables portal menu under the same conditions
    // as the useEffect loop above that positions it
    <Portal>
      <div
        ref={targetRef}
        className={classes.portal}
      >
        {mentionables.map((person: string, i: number) => (
          // draws in the matches to the mentions search
          <div
            key={person}
            className={clsx(
              classes.mentionable, {
              [classes.focused]: i === index,
              [classes.person]: /\s/.test(person),
            })}
          >
            {person}
          </div>
        ))}
      </div>
    </Portal>
    )   
}

export default MentionsMenu