import React from 'react'
import {Icon, ListItemIcon} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import clsx from 'clsx'

type OIProps = {
  online: boolean
}

const OnlineIcon = ({online}: OIProps) => {
  const classes = useStyles()
  return (
    <ListItemIcon className={clsx(classes.root, {[classes.online]: online})}>
      <Icon fontSize="small" className={classes.icon}>
        {online ? 'lens' : 'panorama_fish_eye'}
      </Icon>
    </ListItemIcon>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 18,
    fontSize: '10px',
    marginTop: -18,
  },
  online: {
    color: theme.palette.success.main,
  },
  icon: {
    fontSize: '10px',
  },
}))

export default OnlineIcon
