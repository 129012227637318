import React from 'react'
import {Avatar, makeStyles, Theme, Tooltip} from '@material-ui/core'

const AVATAR_COLORS = [
  '#1abc9c',
  '#16a085',
  '#2ecc71',
  '#27ae60',
  '#3498db',
  '#2980b9',
  '#9b59b6',
  '#8e44ad',
  '#34495e',
  '#2c3e50',
  '#f1c40f',
  '#f39c12',
  '#e67e22',
  '#d35400',
  '#e74c3c',
  '#c0392b',
]

const hash = (name: string, range: number) => {
  const hash = [...name].reduce((hash, chr) => ((hash << 5) - hash + chr.charCodeAt(0)) | 0, 0)
  return Math.abs(hash) % range
}

export const getColorHash = (name: string) => AVATAR_COLORS[hash(name, AVATAR_COLORS.length)]

export const getInitials = (name: string) => {
  if (name === '') return '?'
  const initials = name.match(/\b\w/g) || []
  // Shift gets the first element, pop the last in an array
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
}

type UAProps = {
  name: string
}

export const UsernameAvatar = ({name}: UAProps) => {
  const classes = useStyles({color: getColorHash(name)})
  return (
    <Tooltip title={name} arrow>
      <Avatar alt={name} className={classes.root}>
        {getInitials(name)}
      </Avatar>
    </Tooltip>
  )
}

const useStyles = makeStyles<Theme, {color: string}>(theme => ({
  root: {
    backgroundColor: props => props.color,
    fontSize: '80%',
    cursor: 'pointer',
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}))
