import React, {forwardRef} from 'react'
import {useSelected, useFocused, useEditor, ReactEditor} from 'slate-react'
import {Transforms} from 'slate'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import {CircularProgress} from '@material-ui/core'

export const BlockWrapper = forwardRef((props: any, ref) => {
  const {attributes, children, element, content, onClick} = props
  const editor = useEditor()
  const classes = useStyles()
  const selected = useSelected()
  const focused = useFocused()

  const onSelect = () => {
    try {
      const path = ReactEditor.findPath(editor, element)
      Transforms.select(editor, path)
      onClick && onClick()
    } catch {
      console.log("Couldn't select element")
    }
  }

  return (
    <div ref={ref} {...attributes}>
      <div
        contentEditable={false}
        className={clsx(classes.frame, {
          [classes.selected]: selected && focused,
        })}
        onClick={onSelect}
      >
        {content ? content : <CircularProgress className={classes.progress} />}
      </div>
      {children}
    </div>
  )
})

export default BlockWrapper

const useStyles = makeStyles(theme => ({
  frame: {
    userSelect: 'none',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    maxWidth: 540,
    width: 'auto',
    marginBottom: theme.spacing(1),
    textDecoration: 'none',
    cursor: 'pointer',
    boxShadow: '0 4px 6px -2px #0002',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  },
  progress: {
    margin: theme.spacing(2),
  },
  selected: {
    boxShadow: `0 0px 0px 4px ${theme.brand.primary.light}`,
  },
}))
