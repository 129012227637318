import React, {forwardRef} from 'react'
import {useSelected, useFocused, useEditor, ReactEditor} from 'slate-react'
import {Transforms} from 'slate'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import BlockWrapper from './block'

const ImageElement = forwardRef((props: any, ref) => {
  const {attributes, children, element} = props
  const editor = useEditor()
  const classes = useStyles()
  const selected = useSelected()
  const focused = useFocused()

  if (!element.url) {
    return <BlockWrapper {...props} ref={ref} />
  }

  const onSelect = () => {
    try {
      const path = ReactEditor.findPath(editor, element)
      Transforms.select(editor, path)
    } catch {
      console.log("Couldn'y select element")
    }
  }

  return (
    <div ref={ref} {...attributes}>
      <img
        contentEditable={false}
        onClick={onSelect}
        className={clsx(classes.frame, {
          [classes.selected]: selected && focused,
        })}
        alt={element.url}
        src={element.url}
      />
      {children}
    </div>
  )
})

const useStyles = makeStyles(theme => ({
  frame: {
    userSelect: 'none',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    maxWidth: '100%',
    maxHeight: '20em',
    marginBottom: theme.spacing(1),
    textDecoration: 'none',
    cursor: 'pointer',
    boxShadow: '0 4px 6px -2px #0002',
    display: 'block',
    backgroundColor: theme.palette.background.default,
  },
  selected: {
    boxShadow: `0 0px 0px 4px ${theme.brand.primary.light}`,
  },
}))

export default ImageElement
