// Inspired by https://github.com/reactivemarkets/react-financial-charts/blob/master/packages/charts/src/annotation/Annotate.tsx
import React from 'react'
import GenericChartComponent from 'react-financial-charts/lib/GenericChartComponent'

import {ChartData} from '..'

type CMAProps = {
  with: any
  when: Date
  usingProps?: object
}
export const ClosestMatchAnnotate = ({usingProps, with: Annotation, when}: CMAProps) => {
  const className =
    'react-financial-charts-enable-interaction react-financial-charts-annotate react-financial-charts-default-cursor'
  const renderSVG = (moreProps: any) => {
    const {
      xAccessor,
      xScale,
      chartConfig: {yScale},
      plotData,
    } = moreProps

    return (
      <g className={className}>
        <Annotation
          {...usingProps}
          xScale={xScale}
          yScale={yScale}
          xAccessor={xAccessor}
          plotData={plotData}
          datum={getClosestDate(when, plotData)}
        />
      </g>
    )
  }

  return <GenericChartComponent svgDraw={renderSVG} drawOn={['pan']} />
}

const getClosestDate = (when: Date, plotData: ChartData[]) => {
  let minDist = Number.MAX_VALUE
  let currentMatch = null

  const time = when.getTime()
  plotData.forEach(d => {
    const dist = Math.abs(d.date.getTime() - time)
    if (dist < minDist) {
      minDist = dist
      currentMatch = d
    }
  })
  return currentMatch
}
