import SvgIcon from '@material-ui/core/SvgIcon'
import React, {createContext, useContext, useState} from 'react'
import {Node, Text} from 'slate'
import {useDebouncedCallback} from 'use-debounce'
import {NOTE_AUTOSAVE_INTERVAL_MS, SHOW_NOTE_SAVED_INTERVAL} from '../../config'
import {
  FrontendClientFragment,
  GetNoteDocument,
  NotePublishingInfo,
  Permissions_Enum,
  PublishNoteMutation,
  useDeleteNoteMutation,
  useGetNotesUsersNotificationsByUserAndNoteQuery,
  useGetTeamClientsLiveSubscription,
  usePublishNoteMutation,
  useSoftDeleteNoteMutation,
  useSubscribeToNoteSubscription,
  useUnpublishNoteMutation,
  useUpdateNoteContentsMutation,
  useUpdateNotePermissionMutation,
  useUpdateNoteTimeMutation,
  useUpsertNotificationAsReadMutation,
} from '../../generated/types'
import {useSendbirdContext} from '../../lib/messages-context'
import getNoteProps from '../../utils/note-props'
import {useUserState} from '../../utils/user-context'

type NoteState = {
  noteId: number
  teamId?: number
  loading: boolean
  error?: any
  channelId: string | null
  time: Date
  title: string | null
  clients: FrontendClientFragment[]
  content: Node[]
  isPublished: boolean
  chartSymbol?: string
  userId?: string
  icon: string
  previewIcon: typeof SvgIcon
  header: string
  permission: Permissions_Enum
  isNoteUnread: boolean
  canEdit: boolean
  justSaved: boolean
  contentType: string
  setTime: (time: Date) => void
  setPermission: (permission: Permissions_Enum) => void
  deleteNote: () => void
  onClose: () => void
  onChange: (content: Node[]) => void
  unpublishNote: () => void
  publishNote: (publishData: NotePublishingInfo) => Promise<PublishNoteMutation | undefined>
  markNoteAsRead: () => void
}

// DefaultState is only used when a component does not have a matching
// Provider above it in the tree (for testing in isolation)
const DefaultState: NoteState = {
  noteId: 1,
  loading: true,
  canEdit: false,
  justSaved: false,
  isPublished: false,
  time: new Date(),
  channelId: null,
  title: 'A new note',
  content: [],
  clients: [],
  permission: Permissions_Enum.Private,
  isNoteUnread: false,
  setTime: (time: Date) => null,
  setPermission: (permission: Permissions_Enum) => null,
  deleteNote: () => null,
  onClose: () => null,
  onChange: (content: Node[]) => null,
  unpublishNote: () => null,
  publishNote: async (publishData: NotePublishingInfo) => undefined,
  markNoteAsRead: () => null,
  ...getNoteProps(null),
}

const NoteStateContext = createContext<NoteState>(DefaultState)

const getTitle = (content: Node[]) => {
  if (!content || content.length === 0) return null
  const firstBlock = content[0].children as Text[]
  if (firstBlock.length === 0) return null
  return firstBlock[0].text
}

type NPProps = {
  noteId: number
  onClose: () => void
  children: React.ReactNode
}

const NoteProvider = ({noteId, children, onClose}: NPProps) => {
  const {user} = useUserState()
  const {createTeamChannel} = useSendbirdContext()

  const {error, data, loading} = useSubscribeToNoteSubscription({variables: {id: noteId}})

  const {data: teamClientsData} = useGetTeamClientsLiveSubscription({
    variables: {teamId: user?.outline_user?.team_id || 1},
  })
  const {data: unreadNotificationData} = useGetNotesUsersNotificationsByUserAndNoteQuery({
    variables: {userId: user?.id || '', noteId: noteId},
  })
  const [justSaved, setJustSaved] = useState(false)
  const clients = teamClientsData?.team_contacts || []
  const contentFromDb = data?.note?.content

  const title = getTitle(contentFromDb)

  const refetchQueries = [{query: GetNoteDocument, variables: {id: noteId}}]
  const [publishNote] = usePublishNoteMutation({refetchQueries})
  const [unpublishNote] = useUnpublishNoteMutation({refetchQueries, variables: {noteId}})
  const [updateNotePermission] = useUpdateNotePermissionMutation({refetchQueries})
  const [updateNoteContents] = useUpdateNoteContentsMutation({refetchQueries})
  const [updateNoteTime] = useUpdateNoteTimeMutation({refetchQueries})
  const [deleteNote] = useDeleteNoteMutation({variables: {id: noteId}})
  const [softDeleteNote] = useSoftDeleteNoteMutation({
    variables: {id: noteId, currentTime: new Date().toISOString()},
  })
  const [upsertMarkAsRead] = useUpsertNotificationAsReadMutation({
    variables: {noteId: noteId, userId: user?.id || ''},
  })
  const markNoteAsRead = () => {
    upsertMarkAsRead()
  }

  // a debounced callback will only execute the callback after
  // it HASN'T been called for a given amount of time
  const [onChange] = useDebouncedCallback((content: Node[]) => {
    const title = getTitle(content) || ''
    if (JSON.stringify(content) === JSON.stringify(contentFromDb)) return
    setJustSaved(true)
    updateNoteContents({variables: {id: noteId, content, title}})
    setTimeout(() => setJustSaved(false), SHOW_NOTE_SAVED_INTERVAL)
  }, NOTE_AUTOSAVE_INTERVAL_MS)

  const [setTime] = useDebouncedCallback((time: Date) => {
    updateNoteTime({variables: {id: noteId, time: time.toISOString()}})
  }, NOTE_AUTOSAVE_INTERVAL_MS)

  const setPermission = async (permission: Permissions_Enum) => {
    if (permission !== Permissions_Enum.Private && !data?.note?.channel_id) {
      const channelId = await createTeamChannel(noteId)
      updateNotePermission({variables: {id: noteId, permission, channelId}})
    } else {
      // Not passing along the existing channel ID to the update call would set the channel to null.
      updateNotePermission({variables: {id: noteId, permission, channelId: data?.note?.channel_id}})
    }
    // On permissions update mark the note as read.
    // Otherwise the note shows up as unread to the user as it transitions states
    // (i.e. the last_modified timestamp is greater than when the user last "read" the note)
    markNoteAsRead()
  }

  const handleDeleteNote = () => {
    if (!title) {
      deleteNote()
      onClose()
      return
    }
    if (window.confirm('Are you sure you want to delete this note?')) {
      softDeleteNote()
      onClose()
    }
  }

  const handlePublish = async (
    notePublishingInfo: NotePublishingInfo,
  ): Promise<PublishNoteMutation | undefined> => {
    const {data, errors} = await publishNote({variables: {notePublishingInfo}})
    if (errors) {
      throw errors
    }
    return data
  }

  const handleClose = () => {
    if (!title) {
      deleteNote()
    }
    onClose()
  }

  const getUnreadNotification = () => {
    var noteUnread = false
    var unreadNotification = unreadNotificationData?.notes_users_notifications[0]

    if (data?.note?.permission !== Permissions_Enum.Private) {
      if (unreadNotification) {
        const user_read_time = new Date(unreadNotification.read_at)
        const note_updated_time = new Date(data?.note?.last_modified)
        noteUnread = note_updated_time > user_read_time ? true : false
      } else {
        // If the note exists but no new row has been created in the notes_users_notification table.
        noteUnread = true
      }
    }
    return noteUnread
  }

  const values = {
    noteId,
    title,
    justSaved,
    teamId: user?.outline_user?.team_id,
    clients: clients.map((c) => c.client),
    isEmpty: !title,
    channelId: data?.note?.channel_id || null,
    permission: data?.note?.permission || Permissions_Enum.Private,
    time: new Date(data?.note?.time),
    userId: data?.note?.owner,
    isPublished: !!data?.note?.published_note,
    canEdit: (user && user.id === data?.note?.owner) || false,
    chartSymbol: data?.note?.chart_symbol,
    isNoteUnread: getUnreadNotification(),
  }

  // Color, icon and other stuff based on the note's permission
  const noteProps = getNoteProps(data?.note)

  const handlers = {
    onClose: handleClose,
    onChange,
    setTime,
    setPermission,
    unpublishNote,
    publishNote: handlePublish,
    deleteNote: handleDeleteNote,
    markNoteAsRead,
  }

  return (
    <NoteStateContext.Provider
      value={{content: contentFromDb, error, loading, ...values, ...noteProps, ...handlers}}
    >
      {children}
    </NoteStateContext.Provider>
  )
}

const useNoteState = () => {
  const context = useContext(NoteStateContext)
  if (context === undefined) {
    throw new Error('useNoteState must be used within a NoteProvider')
  }
  return context
}

export {NoteProvider, useNoteState}
