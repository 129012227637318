import {ReactEditor} from 'slate-react'
import isUrl from '../../../utils/isUrl'
import {Transforms, Editor, Range} from 'slate'

export const withLinks = (editor: ReactEditor) => {
  const {insertData, insertText, isInline} = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const isLinkActive = (editor: ReactEditor) => {
  const [link] = Editor.nodes(editor, {match: n => n.type === 'link'})
  return !!link
}

const unwrapLink = (editor: ReactEditor) => {
  Transforms.unwrapNodes(editor, {match: n => n.type === 'link'})
}

const wrapLink = async (editor: ReactEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const {selection} = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  if (selection && isCollapsed && selection.anchor.offset === 0) {
    Transforms.setNodes(editor, {
      type: 'link-preview',
      url,
      children: [{text: ''}],
    })
    return
  }

  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{text: url}] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, {split: true})
    Transforms.collapse(editor, {edge: 'end'})
  }
}
