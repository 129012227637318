import React, {useEffect} from 'react'
import {Router, Route, Redirect, Switch} from 'react-router-dom'

import {useAuth0} from './utils/auth0'
import {Home, Login, FourHundredAndFour} from './screens'
import {Home as ClientHome} from './screens/client'
import {Home as UserHome, NoteWindow} from './screens/user'
import {intercomSettings, getIntercomConfigFromAuth0User} from './utils/intercom'
import history from './utils/history'
import {useUserState} from './utils/user-context'
import routes, {ClientRoute, OutlineUserRoute} from './routes'
import {ThemeProvider} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import createPersistedState from 'use-persisted-state'
import {outlineDarkTheme, outlineLightTheme} from './ui/theme'

function App() {
  const {user: auth0User} = useAuth0()
  const {user, fetchUser} = useUserState()
  const useDarkMode = createPersistedState('darkMode')
  const [darkMode] = useDarkMode(false)

  useEffect(() => {
    if (!auth0User) return
    window.intercomSettings = intercomSettings
    window.Intercom('boot', getIntercomConfigFromAuth0User(auth0User))
    fetchUser(auth0User.sub)
  }, [auth0User, fetchUser])

  useEffect(() => {
    const unlisten = history.listen(location => {
      if (!user) return
      localStorage.setItem('lastPath', location.pathname)
    })
    return () => unlisten()
  }, [user])

  return (
    <ThemeProvider theme={darkMode ? outlineDarkTheme : outlineLightTheme}>
      <CssBaseline />
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path={routes.common.login.path} component={Login} />
          <Route path={routes.user.noteWindow.path} component={NoteWindow} exact />
          <ClientRoute path={routes.client.root.path} component={ClientHome} />
          <OutlineUserRoute path={routes.user.root.path} component={UserHome} />
          <Route
            path={routes.common.fourhundredandfour.path}
            exact
            component={FourHundredAndFour}
          />
          <Route>
            <Redirect to={routes.common.fourhundredandfour.path} />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  )
}

export default App
