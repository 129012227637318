import React from 'react'
import {useHistory} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import {Avatar, Tooltip, Badge} from '@material-ui/core'
import {getColorHash, getInitials} from '../../utils/initials'
import Search from './search'

import {useGetChartSubscription, useGetTeamClientsLiveSubscription} from '../../generated/types'

import WorkspaceContextMenu from './workspace-context-menu'
import {useNavState} from '../../utils/nav-context'
import {useUserState} from '../../utils/user-context'
import routes from '../../routes'

import AddNote from '../toolbar/add-note'
import Toolbar from './toolbar'
import UserChartToolbar from '../chart/user-chart-toolbar'
import EmptyChartToolbar from '../chart/empty-chart-toolbar'
import ContentFilterToolbar from '../toolbar/content-filter'
import {useChannel} from '../../lib/channel-context'

const UserToolbar = () => {
  const classes = useStyles()

  const {workspaceId, activeChart, chartId} = useNavState()
  const {data: chartData} = useGetChartSubscription({variables: {chartId: chartId || 1}})

  const {user} = useUserState()

  const {error, data: clientsData} = useGetTeamClientsLiveSubscription({
    variables: {teamId: user?.outline_user?.team_id || 1},
  })

  if (error) throw error
  const clients = clientsData?.team_contacts
    .filter((c) => !!c.client.user.is_online)
    .map((c) => ({
      channelId: c.channel_id,
      name: c.client.user.full_name || '',
      userId: c.client.user_id,
    }))

  return (
    <Toolbar>
      {workspaceId && <WorkspaceContextMenu workspaceId={workspaceId} />}
      {chartId && <UserChartToolbar chartId={chartId} data={chartData} />}
      {!chartId && workspaceId && activeChart !== undefined && activeChart !== null && (
        <EmptyChartToolbar workspaceId={workspaceId} position={activeChart} />
      )}
      {workspaceId && <ContentFilterToolbar />}
      <div className={classes.grow} />
      <Search />
      <AvatarGroup max={5} classes={{root: classes.avatargroup, avatar: classes.avatar}}>
        {clients?.map((c) => (
          <ClientAvatar key={c.userId} name={c.name} userId={c.userId} channelId={c.channelId} />
        ))}
      </AvatarGroup>
      {chartId && <AddNote symbol={chartData?.charts_by_pk?.symbol} />}
    </Toolbar>
  )
}

type CAProps = {
  name: string
  userId: string
  channelId?: string | null
}
const ClientAvatar = ({name, userId, channelId, ...props}: CAProps) => {
  const history = useHistory()
  const {unread} = useChannel(channelId)

  const gotoClient = (clientId: string) =>
    history.push(routes.user.clientsClient.createUrl(clientId))

  return (
    <Tooltip title={`${name} is online`} arrow key={userId} {...props}>
      <Badge variant="dot" badgeContent={unread} overlap="circle" color="error" max={9} {...props}>
        <Avatar
          {...props}
          alt={name}
          style={{backgroundColor: getColorHash(name)}}
          onClick={() => gotoClient(userId)}
        >
          {getInitials(name)}
        </Avatar>
      </Badge>
    </Tooltip>
  )
}

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  avatargroup: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    fontSize: '80%',
    cursor: 'pointer',
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}))

export default UserToolbar
