import React from 'react'
import {List, makeStyles, Divider, ListSubheader} from '@material-ui/core'

import {PublicNotePreviewWithThreadsFragment} from '../../generated/types'
import routes from '../../routes'
import NoteListItem from './note-list-item'

type NLProps = {
  notes: PublicNotePreviewWithThreadsFragment[]
  selected?: number | null
}
const NotesList = ({notes, selected}: NLProps) => {
  const classes = useStyles()
  return (
    <List className={classes.root} subheader={<ListSubheader>Notes</ListSubheader>}>
      <Divider />
      {notes.map(note => (
        <NoteListItem
          note={note}
          read={!!note.threads[0]?.read_at}
          selected={selected === note.id}
          channelId={note.threads[0]?.channel_id}
          key={note.id}
          to={routes.client.note.createUrl(note.id)}
        />
      ))}
    </List>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
}))

export default NotesList
