import React from 'react'
import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import {
  useAddNoteMutation,
  Permissions_Enum,
  Note_Types_Enum,
  useUpdateNotePermissionMutation,
} from '../../generated/types'
import {OpenNote, useNavDispatch} from '../../utils/nav-context'
import {useSendbirdContext} from '../../lib/messages-context'
import {makeStyles, Theme} from '@material-ui/core/styles'

type AddNoteProps = {
  symbol: string | undefined
}

const AddNote = ({symbol}: AddNoteProps) => {
  const navDispatch = useNavDispatch()
  const classes = useStyles()

  const onNewNote = (openNote: OpenNote) => {
    navDispatch({openNote})
  }
  const {createTeamChannel} = useSendbirdContext()
  const [updatePermissions] = useUpdateNotePermissionMutation()

  // TODO: dr - DRY this up
  const setNotePermissions = async (noteId: number, permission: Permissions_Enum) => {
    const channelId = await createTeamChannel(noteId)
    updatePermissions({
      variables: {
        id: noteId,
        permission: permission,
        channelId: channelId,
      },
    })
  }

  const [addNote] = useAddNoteMutation({
    onCompleted: (data) => {
      const newNote = data?.insert_notes_one
      newNote && onNewNote({noteId: newNote.id})
      newNote && setNotePermissions(newNote.id, newNote.permission || Permissions_Enum.Private)
    },
  })

  const handleCreateNote = () => {
    if (symbol) {
      addNote({
        variables: {
          symbol: symbol,
          type: Note_Types_Enum.Note,
          time: new Date().toISOString(),
          permission: Permissions_Enum.Private,
        },
      })
    } else {
      console.log('Could not add note - chart symbol was undefined')
    }
  }
  return (
    <div className={classes.buttonContainer}>
      <Button
        variant="contained"
        className={classes.addNote}
        startIcon={<AddIcon />}
        onClick={handleCreateNote}
      >
        Note
      </Button>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  addNote: {
    backgroundColor: theme.brand.button.background,
    color: theme.brand.button.text,
    '&:hover': {
      backgroundColor: theme.brand.button.hoverBackground,
    },
  },
  buttonContainer: {
    paddingRight: theme.shape.landingPaneClosedWidth,
  },
}))

export default AddNote
