import React from 'react'
import {outlineDarkTheme, outlineLightTheme} from '../../ui/theme'
import createPersistedState from 'use-persisted-state'

import {ThemeProvider} from '@material-ui/core/styles'
import Note from './note'
import {NoteProvider} from './note-context'

type NDProps = {
  onClose: () => void
  onFocus: (noteId: number) => void
  noteId: number
  active?: boolean
  fullscreen?: boolean
}
const NoteContainer = ({noteId, onClose, onFocus, active = false, fullscreen = false}: NDProps) => {
  const useDarkMode = createPersistedState('darkModeForNotes')
  const [darkMode] = useDarkMode(true)

  return (
    <ThemeProvider theme={darkMode ? outlineDarkTheme : outlineLightTheme}>
      <NoteProvider noteId={noteId} onClose={onClose}>
        <Note fullscreen={fullscreen} onFocus={() => onFocus(noteId)} active={active} />
      </NoteProvider>
    </ThemeProvider>
  )
}

export default NoteContainer
