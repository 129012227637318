import React, {useEffect, useState} from 'react'

import {Paper, Snackbar, SnackbarContent, Typography} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import NewWindow from 'react-new-window'
import {Chat, Editor} from '..'
import {Permissions_Enum} from '../../generated/types'
import routes from '../../routes'
import {useNavDispatch, useNavState} from '../../utils/nav-context'
import TwoPanes from '../layouts/two-panes'
import EditorViewer from './editor-viewer'
import NoteChips from './note-chips'
import {useNoteState} from './note-context'
import NoteHeader from './note-header'

const DEFAULT_NOTE_WIDTH = 350
const DEFAULT_NOTE_HEIGHT = 600

type NWProps = {
  onFocus: () => void
  fullscreen?: boolean
  active?: boolean
}
const Note = ({fullscreen = false, onFocus, active}: NWProps) => {
  const [isUndocked, setIsUndocked] = useState(false)
  const navDispatch = useNavDispatch()
  const {openNotes, isLandingPaneOpen} = useNavState()
  const classes = useStyles({isLandingPaneOpen})
  const {
    permission,
    loading,
    noteId,
    error,
    content,
    onChange,
    justSaved,
    canEdit,
    channelId,
    markNoteAsRead,
  } = useNoteState()

  // When you toggle a note from a shared state to a private state,
  // the channelId is preserved on the note object. So, we want to check
  // both the permission of the note and the existence of the channelID
  // when deciding whether to show the chat pane or not
  const hasChat = channelId && permission !== Permissions_Enum.Private

  useEffect(markNoteAsRead, [loading])

  if (loading || !openNotes) return null

  const renderEditor = () => {
    if (error)
      return (
        <Typography variant="subtitle1" color="error">
          {error.message}
        </Typography>
      )
    if (!canEdit) return <EditorViewer value={content} />
    return <Editor value={content} onChange={onChange} />
  }

  const renderNotePane = () => {
    return (
      <>
        <NoteHeader fullscreen={fullscreen} onUndock={() => setIsUndocked(true)} />
        <div className={classes.noteContentPane}>
          <NoteChips />
          {renderEditor()}
          <Snackbar
            className={classes.savedSnackNotification}
            open={!!justSaved}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          >
            <SnackbarContent message="Saved" className={classes.justSaved} variant="outlined" />
          </Snackbar>
        </div>
      </>
    )
  }

  const renderPanes = () => {
    if (!hasChat) {
      return <div className={classes.singlePane}>{renderNotePane()}</div>
    }
    return (
      <TwoPanes
        pane1={<div className={classes.notePane}>{renderNotePane()}</div>}
        pane2={channelId && <Chat channelId={channelId} />}
        name="noteChatSplit"
        direction="horizontal"
        // We don't want the note header to scroll,
        // so we fix it by hiding the overflow for this container
        // and exposing the scrollbar in the note pane.
        pane1Style={{overflow: 'hidden'}}
      />
    )
  }

  if (isUndocked) {
    return (
      <NewWindow
        features={{
          width: DEFAULT_NOTE_WIDTH,
          height: DEFAULT_NOTE_HEIGHT,
          titlebar: 'no',
          toolbar: 'no',
          location: 'no',
          status: 'no',
          menubar: 'no',
        }}
        url={routes.user.noteWindow.createUrl(noteId)}
        onUnload={() => navDispatch({closeNote: noteId})}
        title="Outline Note"
      />
    )
  }

  return (
    <Paper className={classes.modal} elevation={4} onClick={onFocus}>
      {renderPanes()}
    </Paper>
  )
}

const useStyles = makeStyles<Theme, {isLandingPaneOpen: boolean}>((theme) => ({
  singlePane: {
    // The height of the other pane (the chart grid) is determined in useContext,
    // allowing for that chart and layout to be resized accordingly. We need to
    // effectively use the same version for the note pane since the page height (height: 100%)
    // is larger than what's displayed (meaning you end up with a note pane that can't scroll).
    // This mechanism isn't required when you use the SplitPane for notes that have chats since
    // that component is restricted to the size of the page (by magic I don't understand)
    // and scrolling is enabled for it's internal panes individually.
    // Of course, this is CSS and my reasoning could be completely off given that I've been
    // starting at this for more than a reasonable amount of time.
    height: window.innerHeight - theme.shape.toolbarHeight - theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    overflowY: 'hidden',
  },
  notePane: {
    flex: '1',
    overflow: 'hidden',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  noteContentPane: {
    padding: theme.spacing(1),
    overflow: 'auto',
    height: '100%',
    width: '100%',
  },
  modal: {
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.appBar + 2,
    borderRadius: theme.shape.borderRadius,
  },
  justSaved: {
    minWidth: 0,
    color: theme.brand.primary.main,
    background: theme.palette.background.default,
    padding: '0px 8px',
  },
  savedSnackNotification: {
    left: 'auto',
    right: (props) =>
      props.isLandingPaneOpen
        ? theme.shape.landingPaneWidth + theme.spacing(3.5)
        : theme.shape.landingPaneClosedWidth + theme.spacing(3.5),
    bottom: theme.spacing(3),
  },
}))

export default Note
