import React from 'react'
import {ImageElement, FileElement, LinkElement, MentionElement} from '.'
import {Link} from '@material-ui/core'
import {Theme, makeStyles} from '@material-ui/core/styles'
// TODO: serialize the contents of blockquotes and callouts
// https://docs.slatejs.org/concepts/09-serializing
const Element = (props: any) => {
  const {attributes, children, element} = props
  const classes = useStyles()
  switch (element.type) {
    case 'mention':
      return <MentionElement {...props} />
    case 'callout':
      return <aside className={classes.callout} {...attributes}>{children}</aside>
    case 'blockquote':
        return <blockquote className={classes.blockquote} {...attributes}><p>{children}</p></blockquote>
    case 'code-block':
      return <pre {...attributes}><code>{children}</code></pre>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'list-item':
      return <li className={classes.listItem} {...attributes}>{children}</li>
    case 'image':
      return <ImageElement {...attributes} children={children} element={element} />
    case 'file':
      return <FileElement {...attributes} children={children} element={element} />
    case 'link':
      return <Link className={classes.link} {...attributes} href={element.href} target="_blank">{children}</Link>
    case 'link-preview':
      return <LinkElement {...attributes} children={children} element={element} />
    default:
      return <p {...attributes}>{children}</p>
  }
}

const useStyles = makeStyles<Theme>(theme => ({
  link: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textDecoration: 'underline',
  },
  callout: {
    padding: '0.75rem',
    fontSize: '125%',
    fontWeight: 500,
    backgroundColor: theme.brand.info.highlight,
    color: theme.brand.info.textcolor,
    '&::before': {
      content: '"👉"',
      float: 'left',
      marginRight: '0.5rem'
    }
  },
  listItem: {
    '&:not(:last-child)': {
      marginBottom: '-0.75rem',
    }
  },
  blockquote: {
    padding: '0.25em 1em',
    marginLeft: 0,
    borderLeft: '2px solid',
    borderColor: theme.palette.text.primary,
  },
}))

export default Element
